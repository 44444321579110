<template>
	<v-dialog v-model="params.isVisible"
			  content-class="page-params-dialog"
			  width="500">
		<v-card>
			<v-card-title>
				Установите параметры
			</v-card-title>

			<v-card-text>
				<v-row v-if="dialogParams" v-for="(item, index) of visibleParams" :key="'params'+index">
					<v-col>
						<v-checkbox v-if="isTypeCheckbox(item)" v-model="item.VALUE"
									dense hide-details
									:label="item.PARAMCAPTION"/>
						<!--						<v-select v-else-if="isTypeSelect(item)"
														  v-model="item.VALUE"
														  outlined dense hide-details
														  :items="itemDict(item)"
														  :label="item.PARAMCAPTION"/>-->
						<v-text-field v-else-if="isTypeSelect(item)" :value="selectorText(item)"
									  dense hide-details
									  :label="item.PARAMCAPTION"
									  placeholder="Значение"
									  append-icon="mdi-dots-horizontal"
									  class="pointer"
									  outlined readonly
									  @click="onClickSelector(item)"
									  @click:append="onClickSelector(item)"
						/>
						<v-text-field v-else-if="isTypeDate(item)"
									  v-model="item.VALUE"
									  :label="item.PARAMCAPTION"
									  placeholder="ДД.ММ.ГГГГ"
									  append-icon="mdi-calendar"
									  outlined dense hide-details
									  maxlength="10" @click:append="onClickCalendar(item)"/>
						<v-text-field v-else-if="isTypeNumber(item)"
									  v-model="item.VALUE"
									  type="number"
									  dense hide-details
									  :label="item.PARAMCAPTION"
									  placeholder="Значение"
									  outlined
						/>
						<v-text-field v-else
									  v-model="item.VALUE"
									  dense hide-details
									  :label="item.PARAMCAPTION"
									  placeholder="Значение"
									  outlined
						/>

					</v-col>
				</v-row>
			</v-card-text>

			<v-divider/>

			<v-card-actions>
				<v-spacer></v-spacer>
				<v-btn
					color="primary"
					text
					@click="params.isVisible=false"
				>
					Отмена
				</v-btn>
				<v-btn
					color="primary"
					text
					@click="onSetDefaults"
				>
					По умолчанию
				</v-btn>
				<v-btn
					color="primary"
					:loading="params.isLoading||this.isSavingUserParams"
					@click="onApply">
					Применить
				</v-btn>
			</v-card-actions>
		</v-card>

		<proc-calendar-dialog :params="calendarDialog" @set="onCalendarSetDate"/>

		<proc-dialog :params="childProcDialog"
					 @click="onChildClick"
					 @open="onChildOpen"
					 @close="onChildProcClose"/>
	</v-dialog>
</template>

<script>
import Vue from "vue";
import ProcCalendarDialog from "@/components/elements/proc-calendar-dialog";
import ProcDialog from "@/components/elements/proc-dialog";
import Config from "@/config";
import {dmy2ymd, ymd2dmy} from "@/utils/date";

export default {
	name: "page-params-dialog",
	components: {ProcCalendarDialog, ProcDialog},
	props: ["params"],
	data: () => ({
		isDateModal: false,
		calendarDialog: {
			isVisible: false,
			date: null,
		},
		childProcDialog: {
			isVisible: false,
			id: null,
			btn: null,
			params: null,
			hideButtons: true,
			readonly: true,
			withOpen: false,
			item: null,
			col: null,
		},
		dialogParams: null,
		isSavingUserParams: false,
	}),
	computed: {
		visibleParams() {
			return this.dialogParams?.filter(el => !!el.PARAMCAPTION);
		}
	},
	watch: {
		"params.params": {
			immediate: true,
			handler() {
				this.initDialogParamValues();
			}
		},
		"params.paramSet": {
			immediate: true,
			handler() {
				this.initDialogParamValues();
				//console.log("PARAMSET", this.params.paramSet);
			}
		}
	},
	methods: {
		/*
			ТИПЫ ПАРАМЕТРОВ
			Тип параметра смотрим в поле PARAMTYPE
			510, 570 - дата
			448 - строка
			496, 580, 480, 452, 32766  - число
			Если в поле PRMFORMAT стоит значение CHECKBOX, то значение параметра может быть 0 или 1 и это будет чекбокс.
		 */
		isTypeCheckbox(item) {
			return item.PRMFORMAT === Config.TYPE_FORMAT_CHECKBOX;
		},
		isTypeSelect(item) {
			return item.ID_PROCNAMESOURCE && this.params.dicts;
		},
		isTypeString(item) {
			//return item.PARAMTYPE === Config.TYPE_ID_STRING;
			return !!Config.TYPE_IDS_STRING.find(el => el === item.PARAMTYPE);
		},
		isTypeNumber(item) {
			return !!Config.TYPE_IDS_NUMBER.find(el => el === item.PARAMTYPE);
		},
		isTypeDate(item) {
			return !!Config.TYPE_IDS_DATE.find(el => el === item.PARAMTYPE);
		},
		itemDict(param) {
			//console.log("PARAM", param);
			const dict = this.params.dicts[param.ID_PROCNAMESOURCE];
			if (!dict) return [];
			return dict.map((el, index) => {
				return {
					value: el[param.PROCNAMESOURCEKEYFLD?.trim()],
					text: el[param.PROCNAMESOURCEFLD]
				};
			});
		},
		selectorText(item) {
			const child = this.itemDict(item).find(el => el.value === item.VALUE);
			return child?.text;
		},
		initDialogParamValues(withDefaults = false) {
			//console.log("initDialogParamValues", this.params.params);
			this.dialogParams = this.params?.paramSet?.map(el=>{
				return this.params?.params?.find(p=>p.INPARAMNAME===el.INPARAMNAME);
			});

			// открепить dialogParams от входящих объектов параметров, чтобы менять свою копию
			this.dialogParams = this.dialogParams?.map(el=>({...el}));

			// привести параметры к нужному нам виду
			this.dialogParams?.forEach(el => {
				//console.log("EL: "+el.PARAMCAPTION+": "+ el.VALUE);

				if (!withDefaults && typeof el.VALUE !== "undefined") return;

				//el.VALUE = el.PARAMVLS;
				if (this.isTypeCheckbox(el)) {
					Vue.set(el, "VALUE", el.PARAMVLS === "1");
				} else {
					let v = el.PARAMVLS;
					//if ( typeof v === "string" && v.match(/^\d+$/) ) v = Number(v);
					if (this.isTypeNumber(el) && v !== null) {
						v = Number(v);
						//console.log("IS NUMBER", el);
					}
					//console.log("SET VALUE", v);
					Vue.set(el, "VALUE", v);
				}

				// применить параметры из item для скрытых полей
				if (!el.PARAMCAPTION && this.params.item) {
					Vue.set(el, "VALUE", this.params.item[el.INPARAMNAME]);
				}
			});
		},
		saveUserParams() {
			this.isSavingUserParams = true;
			return this.$store.dispatch("post", {
				action: "UserParamsController",
				params: {
					params: this.visibleParams
				},
			})
				.then(res => {
					//console.log("RES", res);
					//this.$store.state.page = res.page;
				})
				.catch(err => {
					console.error("ERROR", err);
					this.$store.state.snackbar.error = err.error;
				})
				.finally(() => {
					this.isSavingUserParams = false;
				});
		},
		async onApply() {
			// prepare param for posting
			this.dialogParams.forEach(el => {

				//console.log("APPLY " + el.PARAMCAPTION+": "+el.VALUE);

				if (this.isTypeCheckbox(el)) {
					//el.DISPLAY_VALUE = el.VALUE ? "1" : "0";
					Vue.set(el, "DISPLAY_VALUE", el.VALUE ? "1" : "0");
					el.VALUE = el.VALUE ? 1 : 0;
				} else if (this.isTypeSelect(el)) {
					const dict = this.params.dicts[el.ID_PROCNAMESOURCE];
					const item = dict.find(d => d[el.PROCNAMESOURCEKEYFLD?.trim()] === el.VALUE);
					if (item) Vue.set(el, "DISPLAY_VALUE", item[el.PROCNAMESOURCEFLD]);
					//el.VALUE = el.VALUE;
				} else {
					//el.DISPLAY_VALUE = el.VALUE;
					Vue.set(el, "DISPLAY_VALUE", el.VALUE);
				}
				//console.log(el.PARAMCAPTION+": "+el.VALUE);

				// apply values back to original param
				const p = this.params?.params?.find(p=>p.INPARAMNAME===el.INPARAMNAME);
				Vue.set(p, "VALUE", el.VALUE);
				Vue.set(p, "DISPLAY_VALUE", el.DISPLAY_VALUE);
			});

			await this.saveUserParams();

			this.$emit("update");

			if (this.params.callback) {
				//this.isLoading = true;
				this.params.callback(this.params.params).then(() => {
					//this.isLoading = false;
					this.params.callback = null;
				});
			}
			this.params.isVisible = false;

		},
		onSetDefaults() {
			this.initDialogParamValues(true);
		},
		onClickSelector(item) {
			//console.log("CHILD PROC", item, col);
			this.childProcDialog.item = item;
			this.childProcDialog.id = item.ID_PROCNAMESOURCE;
			this.childProcDialog.hideButtons = true;
			this.childProcDialog.btn = null;
			this.childProcDialog.readonly = true;
			this.childProcDialog.params = [];
			this.childProcDialog.withOpen = true;
			this.childProcDialog.isVisible = true;
		},
		onChildClick(child) {
			// todo перенесли логику в даблклик
		},
		/**
		 * Вызывается на даблклик по элементу в дочерней выборке.
		 */
		onChildOpen(child) {
			// child dialog clicked an item
			this.childProcDialog.isVisible = false;
			const item = this.childProcDialog.item;
			//console.log("CHILD", child, item);
			item.VALUE = child[item.PROCNAMESOURCEKEYFLD?.trim()];
			/*item[col.KEYFIELD] = child[col.LOOKUPKEY];
			item[col.PROCPARAM] = child[col.LOOKUPRESULT];*/
			//console.log("ITEM AFTER", item);
			//this.update(item);
		},
		/**
		 * Вызывается при закрытии окна дочерней выборки.
		 */
		onChildProcClose() {
			//console.log("CLOSED", this.childProcDialog);

			const action = () => {
				// если открывали диалог кнопкой - refresh item or the whole proc
				if (this.childProcDialog.btn?.REFRESHTYPE === Config.BTN_REFRESH_TYPE_ITEM) {
					// refresh item
					this.refetchCurrentItem();
				} else if (this.childProcDialog.btn?.REFRESHTYPE === Config.BTN_REFRESH_TYPE_PROC) {
					// refresh whole page
					this.fetchProc();
				}
			};

			// если открывали диалог кнопкой - возможно требуется сообщение
			if (Number(this.childProcDialog.btn?.IS_NOTIFY_AFTER_RUN)) {
				this.msgDialog.callback = action;
				this.msgDialog.isVisible = true;
			} else action();

		},
		onClickCalendar(item) {
			this.calendarDialog.date = dmy2ymd(item.VALUE);
			this.calendarDialog.item = item;
			this.calendarDialog.isVisible = true;
		},
		onCalendarSetDate(value) {
			const item = this.calendarDialog.item;
			Vue.set(item, "VALUE", ymd2dmy(value));
		}
	}
}
</script>

<style lang="scss">

.page-params-dialog {
	.pointer.v-text-field--outlined.v-input--dense.v-text-field--outlined > .v-input__control > .v-input__slot {
		//min-height: 24px;

		input {
			//font-size: 12px;
			cursor: pointer;
		}
	}

	.v-card {
		&__title {
			padding: 2px 12px !important;
		}
		&__text {
			padding: 12px !important;
		}
		&__actions {
			padding: 12px !important;
		}
	}
}
</style>