import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';

Vue.use(Vuetify);

// Translation provided by Vuetify (javascript)
import ru from 'vuetify/lib/locale/ru';
import en from 'vuetify/lib/locale/en';

/*const breakpoint = {
    thresholds: {
        xs: 317,
        sm: 318,
        md: 319,
        lg: 320,
    },
};*/


export default new Vuetify({
    //breakpoint,
    lang: {
        locales: {ru, en},
        current: 'ru',
    },
});
