import md5Func from "js-md5";
import {iso2DDMMYYYY} from "./date";

/**
 * Same as PHP number_format()
 * @param {string | number} number
 * @param {number} decimals
 * @param {string} dec_point
 * @param {string} thousands_sep
 * @returns {string}
 */
export function nf(number, decimals = 0, dec_point = ',', thousands_sep = ' ') {
	number = ((number + '')).replace(/[^0-9+\-Ee.]/g, '');
	const n = !isFinite(+number) ? 0 : +number,
		prec = !isFinite(+decimals) ? 0 : Math.abs(decimals),
		sep = (typeof thousands_sep === 'undefined') ? ',' : thousands_sep,
		dec = (typeof dec_point === 'undefined') ? '.' : dec_point,
		toFixedFix = function (n, prec) {
			const k = Math.pow(10, prec);
			return '' + Math.round(n * k) / k;
		};
	// Fix for IE parseFloat(0.55).toFixed(0) = 0;
	let s = (prec ? toFixedFix(n, prec) : '' + Math.round(n)).split('.');
	if (s[0].length > 3) {
		s[0] = s[0].replace(/\B(?=(?:\d{3})+(?!\d))/g, sep);
	}
	if ((s[1] || '').length < prec) {
		s[1] = s[1] || '';
		s[1] += new Array(prec - s[1].length + 1).join('0');
	}
	return s.join(dec);
};

/**
 * Same as nf for currency formatting.
 * Appends ₽ (rouble sign) to output.
 * @param number
 * @param decimals
 * @param dec_point
 * @param thousands_sep
 * @returns {string}
 */
export function cf(number, decimals = 0, dec_point = ',', thousands_sep = ' ') {
	return nf(number, decimals, dec_point, thousands_sep) + " ₽";
}

/**
 * Same as cf, but does not appends ₽ (rouble sign) to ouput.
 * @param str
 * @param decimals
 * @returns {string}
 */
export function price(number, decimals = 0, dec_point = ',', thousands_sep = ' ') {
	return nf(number, decimals, dec_point, thousands_sep);
}

/**
 * The famous PlayNext string-end routine.
 * @param {number} count
 * @param {string} one
 * @param {string} two
 * @param {string} many
 * @returns {string}
 */
export function end(count, one, two, many) {
	let n = (count + '');
	let e = parseInt(n.substring(n.length, n.length - 1));
	let e2 = parseInt(n.substring(n.length, n.length - 2));
	//console.log(n+":"+n.substring(n.length,n.length-1)+":"+n.substring(n.length,n.length-2));
	if (e >= 2 && e <= 4 && !(e2 >= 11 && e2 <= 19)) return two;
	else if (e === 1 && !(e2 >= 11 && e2 <= 19)) return one;
	return many;
};

/**
 * Returns md5 hash of a payload (can be any type).
 * @param {*} payload
 * @returns {string}
 */
export function md5(payload) {
	if (payload === null || payload === undefined) return '';
	if (typeof payload === "object") {
		let values = [];
		for (let key in payload) {
			if (payload.hasOwnProperty(key)) {
				values.push(key + ":" + md5(payload[key]));
				//console.log(key+": "+ md5(payload[key]))
			}
		}
		return md5Func(values.join(":"));
	} else if (Array.isArray(payload)) {
		let values = [];
		for (let item of payload) {
			values.push(md5(item));
		}
		return md5Func(values.join(":"));
	} else return md5Func(payload.toString());
	//else return md5Func(payload);
};

/**
 * Форматирует строку в вид номера телефона: +7 (999) 999-99-99.
 * @param str
 * @returns {*}
 */
export function phone(str) {
	if (!str) return '';
	return str.replace(/\+7(\d{3})(\d{3})(\d{2})(\d{2})/, "+7 ($1) $2-$3-$4");
}

/**
 * Returns "H часов n минут" from H,m.
 * m is treated as tenth and converted to minutes.
 * So:
 * 1,5 = 1 час 30 минут
 * 1,3 = 1 час 18 минут
 * @param time
 * @returns {string}
 */
export function hm(time) {
	if (!time) return "";
	let hm = time.toString().split(/[.,]/);
	let hours = Math.floor(parseInt(hm[0]));
	let minutes = parseInt(hm[1]);
	let str = hours + " " + end(hours, "час", "часа", "часов");
	if (minutes) {
		minutes *= 60 / 10;
		str += " " + minutes + " " + end(minutes, "минута", "минуты", "минут");
	}
	return str;
}

/**
 * Возвращает строку даты в формате 31.12.2022 из любого из следующих форматов:
 * 2022-12-31
 * 2022-12-31 00:00:00
 * 31.12.2022
 * 31.12.2022 00:00:00
 * @param str
 * @returns {string}
 */
export function date(str) {
	if (!str) return "";
	str = str.toString().trim();
	if (str.match(/^\d{2}.\d{2}.\d{4}$/)) return str;
	if (str.match(/^\d{2}.\d{2}.\d{4} \d{2}\:\d{2}\:\d{2}$/)) return str.substr(0, 10);
	if (str.match(/^\d{4}-\d{2}-\d{2}/)) return iso2DDMMYYYY(str);
	return str;
};

/**
 * Возвращает строку даты в формате 31.12.2022 23:59:59 из любого из следующих форматов:
 * 2022-12-31
 * 2022-12-31 23:59:59
 * 31.12.2022
 * 31.12.2022 23:59:59
 * @param str
 * @returns {string}
 */
export function datetime(str) {
	if (!str) return "";
	str = str.toString().trim();
	if (str.match(/^\d{2}.\d{2}.\d{4}$/)) return str;
	if (str.match(/^\d{2}.\d{2}.\d{4} \d{2}\:\d{2}\:\d{2}$/)) return str;
	if (str.match(/^\d{4}-\d{2}-\d{2}$/)) return iso2DDMMYYYY(str)
	if (str.match(/^\d{4}-\d{2}-\d{2} \d{2}\:\d{2}$/)) return iso2DDMMYYYY(str)+" "+str.substring(11, 16);
	if (str.match(/^\d{4}-\d{2}-\d{2} \d{2}\:\d{2}\:\d{2}$/)) return iso2DDMMYYYY(str)+" "+str.substring(11, 16);
	return str;
};

export function base64ToBytes(base64) {
	const binString = atob(base64);
	return Uint8Array.from(binString, (m) => m.codePointAt(0));
}

export function bytesToBase64(arrayBuffer) {
	var base64 = '';
	var encodings = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/';

	var bytes = new Uint8Array(arrayBuffer);
	var byteLength = bytes.byteLength;
	var byteRemainder = byteLength % 3;
	var mainLength = byteLength - byteRemainder;

	var a, b, c, d;
	var chunk;

	// Main loop deals with bytes in chunks of 3
	for (var i = 0; i < mainLength; i = i + 3) {
		// Combine the three bytes into a single integer
		chunk = (bytes[i] << 16) | (bytes[i + 1] << 8) | bytes[i + 2];

		// Use bitmasks to extract 6-bit segments from the triplet
		a = (chunk & 16515072) >> 18; // 16515072 = (2^6 - 1) << 18
		b = (chunk & 258048) >> 12; // 258048   = (2^6 - 1) << 12
		c = (chunk & 4032) >> 6; // 4032     = (2^6 - 1) << 6
		d = chunk & 63;               // 63       = 2^6 - 1

		// Convert the raw binary segments to the appropriate ASCII encoding
		base64 += encodings[a] + encodings[b] + encodings[c] + encodings[d]
	}

	// Deal with the remaining bytes and padding
	if (byteRemainder === 1) {
		chunk = bytes[mainLength];

		a = (chunk & 252) >> 2; // 252 = (2^6 - 1) << 2

		// Set the 4 least significant bits to zero
		b = (chunk & 3) << 4; // 3   = 2^2 - 1

		base64 += encodings[a] + encodings[b] + '=='
	} else if (byteRemainder === 2) {
		chunk = (bytes[mainLength] << 8) | bytes[mainLength + 1];

		a = (chunk & 64512) >> 10; // 64512 = (2^6 - 1) << 10
		b = (chunk & 1008) >> 4; // 1008  = (2^6 - 1) << 4

		// Set the 2 least significant bits to zero
		c = (chunk & 15) << 2; // 15    = 2^4 - 1

		base64 += encodings[a] + encodings[b] + encodings[c] + '=';
	}

	return base64;
}

export function preserveSpaces(str) {
	return str?.toString().replace(/ /g, "&nbsp;");
}

export function nl2br(str) {
	return str?.toString()
		.replace(/\r/g, "")
		.replace(/\n/g, "<br />");
}