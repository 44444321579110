<template>
	<div class="panels" :class="componentClass">
		<panel v-if="isLG" :ui="root" :formId="data.formId" :params="panelParams"
			   class="panels__root"/>
		<panel-xs v-else :ui="root" :formId="data.formId" :params="panelParams"
				  class="panels__root"/>
		<ds v-for="(item,index) of ds" :ui="item" :key="'ds'+index"
			:ds-params="dsParams"/>
	</div>
</template>

<script>
import Vue from "vue";
import DS from "@/components/elements/ds";
import Config from "@/config";
import vp from "@/mixins/vp";

export default {
	name: "panels",
	props: ["data", "dialogParams", "mainProcId"],
	components: {ds: DS},
	mixins: [vp],
	data: () => ({
		ui: null,
		ds: null,
	}),
	computed: {
		root() {
			if (this.isLG) {
				// desktop root panel = a form, a root panel
				return this.ui.find(el => !el.PARENT && !!el.data);
			} else {
				// mobile root panel = root proc panel
				let rootUI = null;
				if (this.isCard) {
					// [form with all children controls]
					//console.group("This is a card Controls:");
					const controls = this.ui.filter(el => el.COMPNAME.match(Config.RE_KNOWN_CONTROLS));
					//console.log("\t" + controls?.map(el => el.COMPNAME));
					//console.groupEnd();

					//parentProc = this.ui.filter(el => el.children?.filter(el => el.COMPNAME.match(Config.RE_KNOWN_CONTROLS))?.length);
					rootUI = this.ui.find(el => el.COMPNAME.match(/fmForm/));
					rootUI.children = controls;
					//if (!parentProc) return null;
				} else {
					// [parent proc]
					// find root from ui:
					// it is either isParent
					// or its parentKeys are "from the lower layer" - a proc of another form
					//const allProcs = this.ui.filter(el => !!el.ID_PROCNAME);
					//console.log("allProcs", allProcs);
					rootUI = this.ui.find(el => el.isParent);
					if (!rootUI) {
						// last chance - take ID_PROCNAME_MAIN_VIS from current menu
						//const mainProcId = this.dialogParams?.mainProcId;
						//const mainProcId = this.$store.state.currentMenu?.ID_PROCNAME_MAIN_VIS;
						if (this.mainProcId) rootUI = this.ui.find(el => el.ID_PROCNAME === this.mainProcId);
					}
				}

				/*if (!rootUI) {
					this.$store.state.snackbar.error = "Не получилось найти главную выборку для данной формы. Пожалуйста, обратитесь в поддержку.";
					return null;
				}*/

				console.log("XS: PARENT UI: ", rootUI);
				return rootUI;
			}
		},
		isCard() {
			return this.dialogParams?.isCard;
		},
		panelParams() {
			// начальные параметры панелей могут быть только при открытии формы в диалоге
			return this.dialogParams?.params;
		},
		dsParams() {
			// параметры для ds - из диалога или только ID формы
			return this.dialogParams || {
				formId: this.data.formId
			}
		},
		componentClass() {
			const c = [];
			//if ( this.withFormTitle ) c.push("--with-form-title");
			return c.length ? c : null;
		},
	},
	watch: {
		// вариант входа 2 - по смене навигации
		data: {
			immediate: true,
			handler(to, old) {
				this.initUIAndDS();
			}
		},
	},
	methods: {
		initUIAndDS() {
			this.ui = null;
			this.ds = null;
			if (!this.data.ui) return;

			//console.log("ALL UIs", this.data.ui);

			// берем все DS (data sources)
			this.ds = this.data.ui.filter(el => el.COMPNAME.match(/^(DS_)/i));
			//console.log("KNOWS DSs", this.ds);

			// we take only known panel types / controls
			const knownUIs = Config.RE_KNOWN_UIS;
			this.ui = this.data.ui.filter(el => el.COMPNAME.match(knownUIs));

			//console.log("KNOWS UIs", this.ui);

			// set panel props
			const props = ["left", "top", "width", "height", "clientWidth", "clientHeight", "align",
				"caption", "color", "font.color", "font.height", "font.size", "font.style",
				"dataBinding.dataSource", "dataBinding.dataField",
				"dataSource", "dataField",
				"anchors", "ImeName", "tag",
				"WindowState"
			];
			const procs = [];
			this.ui.forEach(el => {
				if (!el.data) return;

				const lines = el.data.split(/\n/);
				props.forEach(prop => {
					if (prop === "caption") {
						// can be multiline
						const lineIndex = lines.findIndex(line => line.trim().match(new RegExp("^" + prop + " *=", "i")));
						if (lineIndex >= 0) {
							const value = [];
							for (let i = lineIndex; i < lines.length; i++) {
								let line;
								//if (el.COMPNAME === "Label855") console.log("Line " + i + ": " + lines[i]);
								if (i === lineIndex) line = lines[i].replace(/^.+?=(.+?)$/is, "$1").trim();
								else if (lines[i].match(/^ {4}.+/i)) line = lines[i].trim();
								if (line?.match(/ \+$/)) line = line.substring(0, line.length - 1).trim();
								if (line) value.push(line);
							}
							if (value.length) el[prop] = value.join("");
						}
					} else {
						const line = lines.filter(line => line.match(/.+?=.+?/)).find(line => line.trim().match(new RegExp("^" + prop + " *=", "i")));
						if (line) el[prop] = line.replace(/^.+?=(.+?)$/is, "$1").trim();
					}
				});

				// convert encoded caption
				if (el.caption) {
					if (el.COMPNAME === "Label855") console.log("CAPTION" + el.caption);

					const letters = [];
					let caption = el.caption.replace(/['"]/g, "");
					//if ( el.COMPNAME==="TStaticText866") console.log("caption", caption);
					for (let i = 0; i < caption.length; i += 1) {
						let c = caption[i];
						if (caption.substring(i, i + 3) === "#39") {
							c = "'";
							i += 2;
						} else if (c === "#") {
							//if ( el.COMPNAME==="TStaticText866") console.log(caption.substring(i, i + 5));
							const cc = Number(caption.substring(i + 1, i + 5));
							if (!isNaN(cc)) c = String.fromCodePoint(cc);
							i += 4;
							//if ( el.COMPNAME==="TStaticText866") console.log("\t", c);
						}
						letters.push(c);
					}
					el.caption = letters.join("");
					//console.log("output", el.caption);
				}

				// init parent data source
				if (el["dataBinding.dataSource"]) el["dataSource"] = el["dataBinding.dataSource"];
				if (el["dataBinding.dataField"]) el["dataField"] = el["dataBinding.dataField"];
				if (el["dataSource"]) {
					//console.log("UI element "+el.COMPNAME+" has data source: "+el["dataSource"]);
					const parentName = el["dataSource"].replace(/.+\.DS(.+)/, "$1");

					// родитель может быть чистым DS или Panel с выборкой
					const parent = this.ds.find(el => el.COMPNAME === 'DS' + parentName)
						|| this.ui.find(el => el.COMPNAME === parentName);
					if (parent) {
						Vue.set(el, "parentProcId", parent.ID_PROCNAME);
						Vue.set(el, "parentProcField", el["dataField"]?.replace(/'/g, ""));

						//console.log("\tparent DS/Proc", parent);
					}
				} else if (el.ID_PROCNAME_OPER) {
					// родительская выборка задается явно (для кнопок)
					Vue.set(el, "parentProcId", el.ID_PROCNAME_OPER);
				}

				if (el.WindowState === Config.FORM_DATA_WS_MAXIMIZED) el.isMaximized = true;
			});

			// for all child procs - set parent procs, so they load only after parents loaded
			let parentProcIds = this.ui.filter(el => el.isParent).map(el => el.ID_PROCNAME);
			parentProcIds = [...parentProcIds, ...this.ds.filter(el => el.isParent).map(el => el.ID_PROCNAME)];
			//console.log("PARENT PROC IDS", parentProcIds);
			this.ui.filter(el => el.isChild).forEach(el => Vue.set(el, "parentProcIds", parentProcIds));

			//console.groupEnd();

			// create horizontal or vertical & pseudo panels
			let pseudoPanelId = 1;
			// todo - make recursive - now works only for 3 children
			this.ui.filter(el => !!el.data).forEach(el => {
				let children = this.ui.filter(child => child.PARENT === el.COMPNAME);
				if (!children?.length) return;

				// check if this a horizontal or vertical panel
				let isHorizontal = !!children.every(el => el.COMPNAME.match(/(Panel|Page)/i)) && !!children.find(el => el.align?.match(/(left|right)/i));
				Vue.set(el, "isHorizontal", isHorizontal);
				let isVertical = !!children?.every(el => el.COMPNAME.match(/(Panel|Page)/i)) && !!children.find(el => el.align?.match(/(top|bottom)/i));
				Vue.set(el, "isVertical", isVertical);

				// if there are more than 2 children - create pseudo parent panel
				// todo remove false
				if ((isHorizontal || isVertical) && children.length > 2) {
					const name = "PseudoPanel" + (pseudoPanelId)++;
					const rest = children.slice(1);

					const width = rest.reduce((total, child) => total + +child.width, 0);
					const height = rest.reduce((total, child) => total + +child.width, 0);
					const maxWidth = rest.reduce((max, child) => {
						const v = +child.width;
						if (v > max) return v;
						return max;
					}, 0);
					const maxHeight = rest.reduce((max, child) => {
						const v = +child.height;
						if (v > max) return v;
						return max;
					}, 0);
					const parent = {
						COMPNAME: name,
						PARENT: el.COMPNAME,
						align: "alRight",	// todo
						caption: name,
						children: rest,
						width: el.isHorizontal ? width : maxWidth,
						height: el.isHorizontal ? maxHeight : height,
						isHorizontal: !!rest.find(el => el.align?.match(/(left|right)/i)),
						isVertical: !!rest.find(el => el.align?.match(/(top|bottom)/i)),
					};
					children = children.slice(0, 1);
					children.push(parent);

					this.ui.push(parent);
				}

				Vue.set(el, "children", children);
			});
			//console.log("Els with children: ", this.ui.filter(el => el.children));

			// sort & resize children
			const totalWidth = +this.data.ui.find(el => el.COMPNAME.match(/fmForm/))?.width || 1920;
			const totalHeight = +this.data.ui.find(el => el.COMPNAME.match(/fmForm/))?.height || 1000;
			//console.log("Frame size: " + totalWidth + " x " + totalHeight);
			this.ui.filter(el => !!el.children).forEach(el => {
				// sort
				el.children = el.children.sort((a, b) => {
					if (a.align?.match(/top/i) && !b.align?.match(/top/i)) return -1;
					if (!a.align?.match(/top/i) && b.align?.match(/top/i)) return 1;
					if (a.align?.match(/bottom/i) && !b.align?.match(/bottom/i)) return 1;
					if (!a.align?.match(/bottom/i) && b.align?.match(/bottom/i)) return -1;

					if (a.align?.match(/left/i) && !b.align?.match(/left/i)) return -1;
					if (!a.align?.match(/left/i) && b.align?.match(/left/i)) return 1;
					if (a.align?.match(/right/i) && !b.align?.match(/right/i)) return 1;
					if (!a.align?.match(/right/i) && b.align?.match(/right/i)) return -1;

					return 0;
				});

				el.children.forEach(child => {
					const size = (el.isHorizontal ? child.width / el.width : (el.isVertical ? child.height / el.height : 1)) * 100;

					/*console.group(child.COMPNAME);
					console.log("parent " + (el.isHorizontal ? "isHorizontal" : (el.isVertical ? "isVertical" : "")));
					console.log("width " + child.width + " / " + el.width);
					console.log("height " + child.height + " / " + el.height);
					console.log("size " + size);
					console.groupEnd();*/

					Vue.set(child, "size", size);
				});
			});

			//this.$store.state.currentPanelXS = this.root;

			/*this.ui.forEach(el => {
				console.group(el.COMPNAME);
				console.log("width " + el.width);
				console.log("height " + el.height);
				console.log("size " + el.size);
				console.groupEnd();
			});*/
		},
	},
}
</script>

<style lang="scss">
.panels {
	flex-grow: 1;
	width: 100%;
	height: 100%;
	display: flex;
	flex-direction: column;

	&__root {
		width: 100%;
		flex: 1;
	}
}
</style>