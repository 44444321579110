<template>
	<div v-if="countChildControls" class="panel-xs panel-xs--controls" :id="id+'-controls'">
		<panel-control v-for="(item, index) of controls" :ui="item" :formId="formId"
					   :key="'control_'+index+'_'+item.COMPNAME"
					   @error="onControlError"/>

		<!-- child panels -->
<!--		<panel-control v-for="(item, index) of panels" :ui="item" :params="params" :formId="formId"
					   :key="'panel_'+item.COMPNAME"
					   @error="onControlError"/>-->
	</div>
<!--	<div v-else-if="countChildPanels" class="panel-xs panel-xs&#45;&#45;single" :id="id">
		<panel-xs :ui="parentPanel" :params="params" :formId="formId"/>
	</div>-->
	<div v-else class="panel-xs panel-xs--proc" :id="id" :class="procPanelComponentClass">
		<div v-if="isDebug" class="panel__ui-name">
			<div>{{ ui.COMPNAME }}</div>
			<div class="caption">{{ ui.width }} x {{ ui.height }} px | {{ ui.size | nf(1) }} %</div>
		</div>

		<proc v-if="ui.ID_PROCNAME"
			  :ui="ui"
			  :id="ui.ID_PROCNAME"
			  :formId="formId"
			  :params="params"
			  @loaded="onProcLoad"
			  @click="onProcClick"
			  @open="onProcOpen"/>
	</div>
</template>

<script>
import PanelTabs from "@/components/elements/panel-tabs";
import PanelControl from "@/components/elements/panel-control";
import Config from "@/config";
import vp from "@/mixins/vp";

export default {
	name: "panel-xs",
	props: {
		ui: {},
		// здесь структура параметров НЕ совпадает с тем, что непосредстенно у proc
		// смотри panels.vue
		params: {
			type: Array,
			//default: () => []
		},
		formId: {},
		currentUI: null,
	},
	mixins: [vp],
	components: {
		PanelTabs,
		PanelControl
	},
	data: () => ({}),
	computed: {
		isDebug() {
			return !!this.$route.query?.debugPanels;
		},
		id() {
			return this.ui?.COMPNAME;
		},
		children() {
			return this.ui?.children;
		},
		countChildPanels() {
			return this.panels?.length || 0;
		},
		hasSinglePanelChild() {
			return this.countChildPanels === 1;
		},
		countChildControls() {
			return this.controls?.length || 0;
		},
		hasTabs() {
			return !!this.ui.children?.find(el => el.COMPNAME.match(/TabSheet/i));
		},
		panels() {
			return this.children?.filter(el => el.COMPNAME.match(/(Panel|Page)/i));
		},
		controls() {
			const controls = this.children?.filter(el => el.COMPNAME.match(Config.RE_KNOWN_CONTROLS));
			if (controls) controls.sort((a, b) => {
				if (a.WEBORDER < b.WEBORDER) return -1;
				if (a.WEBORDER > b.WEBORDER) return 1;
				return 0;
			});
			//console.log("Controls", controls?.map(el=>el.COMPNAME));
			return controls;
		},
		procPanelComponentClass() {
			const c = [];
			//if (this.isParentProc) c.push("--parent");
			if (this.$store.state.currentPanelXS === this.ui) c.push("--active");
			if (false && this.isChildProc) {
				c.push("--child");
				if (this.isVisibleChildProc) c.push("--child-visible");
				else c.push("--child-hidden");
			}
			return c.length ? c : null;
		},
		parentKeys() {
			return this.ui?.parentKeys;
		},
		proc() {
			return this.$store.state.formProcs?.find(el => el.procNameId === this.ui.ID_PROCNAME);
		},
		pfbParams() {
			return this.proc?.pfbParams;
		},
		areAllPFBParamsSet() {
			return !this.parentKeys?.length || this.parentKeys?.every(key => this.pfbParams?.find(el => el.INPARAMNAME === key && typeof el.VALUE !== "undefined"));
		},
		isParentProc() {
			return !this.ui?.isChild;
		},
		isChildProc() {
			return this.ui?.isChild;
		},
		/**
		 * Means its proc has all PFB params set and this is NOT a root proc
		 * @returns {*|boolean}
		 */
		isVisibleChildProc() {
			return this.areAllPFBParamsSet && this.isChildProc;
		},
	},
	watch: {},
	methods: {
		onProcLoad(data) {
			//this.page = data;
			this.$emit("load", data);
		},
		onProcClick(item) {
			this.$emit("click", item);
		},
		onProcOpen(item) {
			this.$emit("open", item);
		},
		onControlError(error) {
			this.$emit("error", this.ui, error);
		}
	}
}
</script>

<style lang="scss">

.panel-xs {
	position: relative;
	//background: $thead-bg-color;
	width: 100%;
	height: 100%;

	&--controls {
		padding: 20px 24px;
	}

	&.--child {
		position: absolute;
		z-index: 2;
		left: 0;
		width: 100%;

		&-hidden {
			left: 0;
			top: 100vh;
			height: 100vh;
		}

		&-visible {
			display: none; // todo temp
			position: absolute;
			//top: 90vh;
			top: 100vh;
			height: 5vh;
		}
	}

	/*&:not(.--active) {
		position: absolute;
		top: 100vh;
		z-index: 1;
	}*/
}
</style>
