<template>
	<div class="ds" :id="ui.COMPNAME" :class="{loading:isLoading}">{{ ui.COMPNAME }} : {{ ui.ID_PROCNAME }} :
		<!--{{ ui.parentKeys }}-->

		<proc-dialog :params="childProcDialog"
					 @click="onChildClick"
					 @open="onChildOpen"
					 @close="onChildProcClose"/>
		<confirm-dialog :params="confirmDialog" @yes="onConfirm"/>
		<msg-dialog :params="msgDialog" @yes="onAfterMsg"/>
	</div>
</template>

<script>
import Vue from "vue";
import {nf, md5} from "@/utils/string";
import Config from "@/config";
import procBase from "@/components/elements/proc-base";
import ProcDialog from "@/components/elements/proc-dialog";
import ConfirmDialog from "@/components/elements/confirm-dialog";
import MsgDialog from "@/components/elements/msg-dialog";

export default {
	name: "ds",
	mixins: [procBase],
	components: {
		ProcDialog,
		ConfirmDialog,
		MsgDialog,
	},
	props: {
		dsParams: {
			type: Object,
		},
	},
	data: () => ({
	}),
	computed: {
		id() {
			return this.ui.ID_PROCNAME;
		},
		formId() {
			return this.dsParams?.formId;
		},
		isNew() {
			return !this.dsParams?.item;
		},
		isCard() {
			return this.dsParams?.isCard;
		},
		hasError: {
			get() {
				return !!this.error;
			},
			set(v) {
				if (!v) this.error = null;
			}
		},
		/**
		 * Возвращает массив из элементов
		 * {
		 * 		procNameId,	// ID родительской выборки
		 * 		INPARAMNAME,	// название колонки в родительской выборке = параметра для текущей выборки
		 * 		VALUE	// значение колонки элемента в родительской выборке = значение параметра для текущей выборки
		 * 	}
		 * @returns {unknown[] | undefined}
		 */
		pfbParams() {
			const availablePFBCols = this.formProcs    // берем все выборки формы
				.filter(el => el.formId === this.formId)    // все выборки в нашей форме
				.filter(el => el.procNameId !== this.procNameId)    // все выборки, кроме текущей
				?.reduce((all, el) => {	// собираем воедино все колонки из всех выборок
					//console.log(el.procNameId + " PFB COLS", el.cols);
					return all.concat(el.cols || []);
				}, [])
				.filter(el => el.match(/^PFB_.+/));	// отбираем только PFB_ колонки
			//console.log("DS " + this.procNameId+" AVAILABLE PFB COLS", availablePFBCols, this.parentKeys);

			// теперь для всех параметров, на которые мы подписаны - собираем массив параметров из элементов родительских выборок
			return this.parentKeys?.filter(key => !!availablePFBCols?.includes(key)).map(key => {
				//console.log("parent key "+key);
				//console.log("global proc items", this.formProcs);
				const el = this.formProcs
					.filter(el => el.formId === this.formId)    // все выборки в нашей форме
					.find(el => el.item && typeof el.item[key] !== "undefined");
				return {
					procNameId: el?.procNameId,
					formId: el?.formId,
					INPARAMNAME: key,
					VALUE: el?.item[key]
				}
			});
		},
	},
	watch: {
		// вариант открытия 1 - выборка открывается по ui.ID_PROCNAME - нужно зафетчить данные
		ui: {
			immediate: true,
			handler(val, old) {
				if (val !== old && this.id) {

					//console.group("Proc ["+ this.id+"] initing...");
					if ( this.ui?.isChild ) {
						//console.log("This is a child proc, waiting for parent PFBs...");
					}
					else {
						//console.log("This is a parent proc, start loading!");
						//console.log(old+" REINITED BY UI: ", val);
						this.proc = null;	// важно сбросить поступившие ранее данные из бэка
						this.applyProcParams(!!old);	// важно применить родительские параметры до фетча, !!old - при чтении другой выборки - предварительно очищаем параметры
						this.fetchProc();
					}
					//console.groupEnd();
				}
			}
		},
		// вариант открытия 2 - выборка уже была открыта для создания нового элемента и теперь перечитывается после его создания
		isNew(val, old) {
			// was new, but now not
			if (old && !val) {
				this.proc = null;	// важно сбросить поступившие ранее данные из бэка

				//console.group("CARD HAS BEEN SAVED", this.formId+"."+ this.procNameId, this.localParams);
				/*console.log("Params before primary key:");
				this.localParams.forEach(el=>{
					console.log("\t"+el.INPARAMNAME+": "+el.VALUE);
				});*/

				// добавить в параметры DS primary key с значением нового элемента
				this.formProcs?.filter(el => el.meta?.ID_FORMNAME_AS_CARD === this.formId && el.meta?.ID_PROCNAME_CARD === this.procNameId)?.forEach(el => {
					if (!!el.meta?.KEYFIELD && !!el.meta?.QUERYPARAM_IN_CARD) {
						//console.log("Primary key: ", el.meta?.KEYFIELD);
						if (!this.localParams?.find(p => p.INPARAMNAME === el.meta.QUERYPARAM_IN_CARD)) {
							this.localParams?.push({
								INPARAMNAME: el.meta.QUERYPARAM_IN_CARD,
								VALUE: this.dsParams.item[el.meta.KEYFIELD]
							});
							//console.log("Primary key value set: " + this.dsParams.item[el.meta.KEYFIELD]);
						}
					}
				});

				this.applyProcParams();
				/*console.log("Params after applyProcParams():");
				this.localParams.forEach(el=>{
					console.log("\t"+el.INPARAMNAME+": "+el.VALUE);
				});*/

				// перечитать выборку
				this.fetchProc();

				//console.groupEnd();
			}
		},
		/*"dsParams": {
			deep: true,
			handler(val) {
				console.log("DS " + this.procNameId + " sees dsParams", val);
			}
		},*/
		/*"dsParams.item": {
			deep: true,
			handler(val) {
				console.log("DS " + this.procNameId + " sees dsParams.item", val);
			}
		},*/
	},
	methods: {
		newItem(initialValues = null) {
			const item = {
				isNew: true,
				//...initialValues	todo doesn't work...
			};
			this.headers?.forEach(col => {
				if (initialValues && typeof initialValues[col.PROCPARAM] !== "undefined") Vue.set(item, col.PROCPARAM, initialValues[col.PROCPARAM]);
				else if (!!col.CHECKBOX) Vue.set(item, col.PROCPARAM, 0);
				else Vue.set(item, col.PROCPARAM, undefined);	// undefined is important!
			});

			return item;
		},
		fetchProcReal() {
			//console.log("fetchProc() DS ["+this.procNameId + "]");
			this.unregisterProc();

			//console.log(new Date().valueOf() + " Proc ["+this.procNameId + "] loading...");
			this.isLoading = true;
			this.$store.dispatch("get", {
				action: "ProcController",
				params: {
					id: this.procNameId,
					formId: this.formId,
					params: this.localParams,
					isCard: true,
				},
			})
				.then(res => {
					this.applyProc(res.page);
					this.$emit("loaded", this.proc);
					//console.log(new Date().valueOf() + " Proc ["+this.procNameId + "] loaded!", this.proc);
				})
				.catch(err => {
					console.error("ERROR", err);
					this.error = err.error;
				})
				.finally(() => {
					this.isLoading = false;
				});
		},
		fetchOnNewRecord() {
			return this.$store.dispatch("post", {
				action: "ProcOnNewItemController",
				params: {
					id: this.procNameId,
					params: this.localParams,
				},
			})
				.then(res => {
					return res?.page.values;
				})
				.catch(err => {
					console.error("ERROR", err);
					this.error = err.error;
				})
				.finally(() => {
				});
		},
		/*notifyProcIsLoading() {
			const existingIndex = this.formProcs.findIndex(el => el.procNameId === this.procNameId);
			if (existingIndex >= 0) this.formProcs[existingIndex].isLoading = true;
		},
		notifyProcLoaded() {
			const existingIndex = this.formProcs.findIndex(el => el.procNameId === this.procNameId);
			if (existingIndex >= 0) this.formProcs[existingIndex].isLoading = false;
		},*/

		applyProc(data, withResetParams = false) {
			//console.log("applyProc() DS ["+this.procNameId + "]");
			this.items = [];
			this.proc = null;

			if (!data) return;
			this.proc = data;
			//this.setGlobalAvailableCols();
			this.applyHeaders();
			this.applyProcParams(withResetParams);	// применить параметры после фетча

			if (this.isCard && this.isNew) {
				//console.log("before fetchOnNewRecord");
				this.fetchOnNewRecord().then(initialValues => {
					this.items = [this.newItem(initialValues)];
					this.currentItem = this.items[0];
					this.currentItemIndex = 0;
					//console.log("registerProc after fetchOnNewRecord");
					this.registerProc();
				});
			} else this.applyProcItems();
			this.applyButtons();

			//console.log("DS ["+this.procNameId+"] cols with self validates: "+this.headers.filter(el=>el.VISIBLE).filter(el=>el.ONVALIDATE).map(el=>el.PROCPARAM));
			//console.log("DS ["+this.procNameId+"] cols with ext. validates: "+this.headers.filter(el=>el.VISIBLE).filter(el=>el.IS_USESVALIDATES).map(el=>el.PROCPARAM));
		},
		applyProcParams(withResetParams = false) {

			//console.log("applyProcParams", withResetParams);

			if (withResetParams) {
				// очистить параметры
				this.localParams?.splice(0, this.localParams.length);
			}

			// first, apply paramSet todo check if this is neded for DS
			/*this.proc?.paramSet?.forEach(param => {
				const p = this.localParams?.find(el => el.INPARAMNAME === param.INPARAMNAME);
				if (p) {
					if (typeof param.VALUE !== "undefined") Vue.set(p, "VALUE", param.VALUE);
				} else this.localParams?.push(param);
			});

			// now, apply params todo check if this is neded for DS
			if (!withResetParams) {
				this.proc?.params?.forEach(param => {
					const p = this.localParams?.find(el => el.INPARAMNAME === param.INPARAMNAME);
					if (p) {
						if (typeof param.VALUE !== "undefined") Vue.set(p, "VALUE", param.VALUE);
					} else this.localParams?.push(param);
				});
			}*/

			// apply dialog params
			this.dsParams?.params?.forEach(param => {
				const p = this.localParams?.find(el => el.INPARAMNAME === param.INPARAMNAME);
				if (p) {
					if (typeof param.VALUE !== "undefined") Vue.set(p, "VALUE", param.VALUE);
				} else this.localParams?.push(param);
			});

			// apply PFB params
			this.pfbParams?.forEach(param => {
				const p = this.localParams?.find(el => el.INPARAMNAME === param.INPARAMNAME);
				if (p) {
					if (typeof param.VALUE !== "undefined") Vue.set(p, "VALUE", param.VALUE);
				} else this.localParams?.push(param);
			});

			// собрать параметры из внешниз выборок (в т.ч. с других слоев) по parentProc.meta.KEYFIELD + parentProc.meta.ID_PROCNAME_CARD
			// todo перенести во внешние параметры
			this.formProcs?.filter(el => el.meta?.ID_FORMNAME_AS_CARD === this.formId && el.meta?.ID_PROCNAME_CARD === this.procNameId)?.forEach(el => {
				if (!!el.item && !!el.meta?.KEYFIELD && !!el.meta?.QUERYPARAM_IN_CARD) {
					//console.log("FORM PROC PARAM", el.meta?.KEYFIELD);
					if (!this.localParams?.find(p => p.INPARAMNAME === el.meta.QUERYPARAM_IN_CARD)) {
						this.localParams?.push({
							INPARAMNAME: el.meta.QUERYPARAM_IN_CARD,
							VALUE: el.item[el.meta.KEYFIELD]
						});
					}
				}
			});

			//console.log(this.procNameId + ". PARAMS inited", this.parentProcParams);
		},
		applyProcItems() {
			this.items = this.proc?.items?.map((el, index) => ({...el, index})) || [];

			// специфика DS - автовыбор первого элемента
			if (this.items?.length) {
				this.currentItem = this.items[0];
				this.currentItemIndex = 0;
				//console.log("registerProc in applyProcItems");
				this.registerProc();
			}
		}
	},
}
</script>

<style lang="scss">

.ds {
	height: 0; //14px;
	overflow: hidden;
	/*position: relative;
	background: gray;
	color: white;
	font-size: 9px;
	text-align: center;
	@include transition();

	&.loading {
		background-color: #ffcc00;
	}*/
}
</style>