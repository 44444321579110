import { render, staticRenderFns } from "./proc-btns.vue?vue&type=template&id=13224dde"
import script from "./proc-btns.vue?vue&type=script&lang=js"
export * from "./proc-btns.vue?vue&type=script&lang=js"
import style0 from "./proc-btns.vue?vue&type=style&index=0&id=13224dde&prod&lang=scss"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VImg } from 'vuetify/lib/components/VImg';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VMenu } from 'vuetify/lib/components/VMenu';
installComponents(component, {VBtn,VIcon,VImg,VList,VListItem,VListItemTitle,VMenu})
