export default {
    IS_LIVE: process.env.IS_LIVE,
    API_SERVER_URI: process.env.API_SERVER_URI ? process.env.API_SERVER_URI : '',
    //API_SERVER_URI: 'http://totem.db.spider.playnext.net:40088',
    defaultTitle: 'Добро пожаловать',
    STORAGE_AUTH_TOKEN: 'auth',
    STORAGE_AUTH_DEMO_TOKEN: 'auth-demo',
    STORAGE_AUTH_ENTRY: 'entry',

    STORAGE_DESKTOP_VIEW: 'desktopView',

    // типы логирования сессии
    ID_WEBLOGTYPE_MENU: 1,  // логируем выбор пункта меню
    ID_WEBLOGTYPE_BTN: 2,   // логируем нажатие операции
    ID_WEBLOGTYPE_INSERT: 3,    // логируем INSERT выборки
    ID_WEBLOGTYPE_UPDATE: 4,    // логируем UPDATE выборки
    ID_WEBLOGTYPE_DELETE: 5,    // логируем DELETE выборки

    SESSION_LIFE_ALERT_IN_SECONDS: 30,

    // верх дерева, запроса по приборам начинается с этого узла
    DEFAULT_NODE: {
        id: 1,
        type: 0,
    },
    INPUT_DEBOUNCE_TIMEOUT: 600,
    INPUT_DEBOUNCE_TIMEOUT_SHORT: 300,
    INPUT_DEBOUNCE_TIMEOUT_LONG: 1500,

    ITEMS_PER_PAGE: 100,
    BIG_TABLE_PER_PAGE: 200,

    BTN_REFRESH_TYPE_ITEM: "Refresh",
    BTN_REFRESH_TYPE_PROC: "Reopen",

    WM_VALUE_GALLERY: -1111,
    ID_OPERATION_FILE_DOWNLOAD: -806412,
    ID_OPERATION_FILE_UPLOAD: -405263,
    ID_OPERATION_FILE_OPEN: -1066655,
    ID_OPERATION_FILE_DELETE: -405301,

    ERROR_CODE_NOT_READY: 2000,
    ERROR_CODE_FORBIDDEN: 403,
    ERROR_CODE_NOT_FOUND: 404,
    ERROR_CODE_EXISTS: "EXISTS",
    ERROR_SERVER: 500,
    ERROR_FATAL: 500,

    //in _variables.scss, config.js and plugins/vuetify.js
    WIDTH_SM: 600,
    WIDTH_MD: 720,
    WIDTH_LG: 720, //960,//1264,
    WIDTH_XL: 1904,

    TYPE_IDS_STRING: [448],
    TYPE_IDS_DATE: [510, 570],
    TYPE_IDS_NUMBER: [496, 580, 480, 452, 32766],
    TYPE_FORMAT_CHECKBOX: 'CHECKBOX',

    FORM_DATA_WS_MAXIMIZED: "wsMaximized",

    RE_KNOWN_UIS: /^(fmForm|Panel|Page|TabSheet|DBEdit|TStaticText|Label|TDBDateTimeEdit|TButton|TdxDBCheckEdit|DBImage)/i,
    RE_KNOWN_CONTROLS: /^(TabSheet|DBEdit|TStaticText|Label|TDBDateTimeEdit|TButton|TdxDBCheckEdit|DBImage)/i,
};
