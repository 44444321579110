import Vue from 'vue';
import VueRouter from 'vue-router';

Vue.use(VueRouter);

import store from '../store/index';
import {mergeObjects} from "@/utils/utils";
import metrika from "@/utils/metrika";
import Config from "@/config";
import routes from './routes';

const router = new VueRouter({
    mode: 'history',
    //base: process.env.BASE_URL,
    routes
});

router.beforeEach(async (to, from, next) => {
    store.state.isLoading = true;

    // close all menus:
    setTimeout(()=>{
        store.state.isMenuShown = false;
        /*if ( store.state.menuItems?.length ) store.state.menuItems.forEach(el=>{
            console.log("Close: "+el.title);
            Vue.set(el, "openOnHover", true);
        });*/
        setTimeout(()=>{
            store.state.isMenuShown = true;
            /*if ( store.state.menuItems?.length )store.state.menuItems.forEach(el=>{
                Vue.set(el, "openOnHover", false);
            });*/
        },10);
    },10);

    store.commit("setRouteName", to.name);

    // if viewType is not yet registered - register it
    //if ( !store.state.view ) await store.dispatch('registerView');

    // загружаем данные текущего роута (кэшируемые), в т.ч. common данные:
    if (to.meta?.api && (!to.meta?.withoutAPI && !to.params?.withoutAPI)) {

        let params = mergeObjects({}, {action: to.meta.api, method: to.meta.method, params: to.params}, to.meta);
        if (to.query) {
            //console.log("QUERY KEYS", Object.keys(to.query));
            // forward GET arguments to API request
            params.query = {};
            Object.keys(to.query).map(key => {
                //console.log("KEY " + key + " maps from " + to.meta.query[key]);
                params.query[key] = to.query[key];
            });
            /*Object.keys(to.meta.query).map(key => {
                //console.log("KEY " + key + " maps from " + to.meta.query[key]);
                params.query[key] = to.query[to.meta.query[key]];
            });*/
            //console.log("PARAMS QUERY", params.query);
        }
        store.dispatch('fetchPage', params)
            .then(() => {
                next();
            })
            .catch((err) => {
                if (err?.code === Config.ERROR_CODE_FORBIDDEN) {
                    router.replace({name: 'auth'});
                }
                else if (err?.code) {
                    router.replace({name: 'error', params: {code: err.code}});
                }
                else console.error(err);
                //next();
            });
    } else next();
});

router.afterEach((to, from) => {
    // очищаем выбранные элементы выборок
    store.state.formProcs = [];

    setTimeout(() => {
        store.state.isLoading = false;
    },150);

    // небольшая задержка, чтобы в браузере сменился URL
    setTimeout(() => {
        // сообщаем Метрике явно, на какой URL пришли:
        //metrika.hit();

        // scroll to top
        window.scrollTo({
            'behavior': 'auto', //'smooth',
            'left': 0,
            'top': 0
        });

        // сообщаем в ГТМ, что страница доступна
        // if(typeof dataLayer) dataLayer.push({'event': 'render-event'});
    }, 500);

    // сообщаем пререндереру, что страница загрузилась:
    setTimeout(() => {
        document.dispatchEvent(new Event('render-event'));
    }, 1000);
});

export default router;
