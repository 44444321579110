<template>
	<div class="panel-control" :id="id" :style="controlStyle" :class="controlClass"
		 :data-debug-color="ui['color']"
		 :data-debug-font-style="ui['font.style']"
		 :data-debug-font-size="ui.WEB_FONT_SIZE"
		 :data-debug-font-height="ui['font.height']"
		 :data-debug-font-color="ui['font.color']"
		 :data-debug-rights="JSON.stringify(parentProcRights)">

		<div v-if="isDebug" class="panel-control__ui-name">{{ ui.COMPNAME }}</div>

		<!-- todo вынести в отдельные компоненты -->

		<div v-if="isTextarea" class="panel-control__textarea">
			<textarea v-if="isBlob" v-model="textBlobValue"
					  :readonly="isReadonly"
					  :disabled="isDisabled"
					  @keyup="onChange"
					  @change="onChange"
					  @click="onClickEditable"/>
			<textarea v-else v-model="value"
					  :disabled="isDisabled"
					  :readonly="isReadonly"
					  @keyup="onChange"
					  @change="onChange"
					  @click="onClickEditable"/>
		</div>
		<v-text-field v-else-if="isInput" v-model="value"
					  :outlined="isLG" dense hide-details
					  :disabled="isDisabled"
					  :readonly="isReadonly"
					  :loading="isValidating"
					  :rules="[validationRule]"
					  :type="isInputInteger?'number':'text'"
					  class="panel-control__input"
					  :append-icon="appendIcon"
					  :clearable="!isLG"
					  @keyup="onChange"
					  @change="onChange"
					  @click="onClickEditable">
<!-- :placeholder="col ? col.PROCPARAM : null" -->

			<template v-slot:append>
				<a v-if="isURL" :href="value" target="_blank">
					<v-icon small>mdi-open-in-new</v-icon>
				</a>
			</template>
		</v-text-field>
		<v-text-field v-else-if="isCalendar" class="panel-control__calendar"
					  v-model="value"
					  append-icon="mdi-calendar"
					  outlined dense hide-details
					  :disabled="isDisabled"
					  readonly
					  :loading="isValidating"
					  maxlength="10"
					  @click:append="onClickCalendar"
					  @click="onClickCalendar"
					  @keyup="onChange"
					  @change="onChange" :title="col ? col.PROCPARAM : null"/>
		<!--<v-checkbox v-else-if="isCheckbox" v-model="value" class="panel-control__checkbox"
					dense hide-details :label="ui.caption"/>-->
		<span v-else-if="isCheckbox && isLG" @click="onClickCheckbox">
			<v-icon class="checkbox" :class="{active:value}">
				{{ value ? 'mdi-checkbox-intermediate' : 'mdi-checkbox-blank-outline' }}
			</v-icon> {{ ui.caption }}
		</span>
		<v-checkbox v-else-if="isCheckbox"
					v-model="value"
					dense hide-details
					:label="col ? col.CAPTION : null" @change="onChange"/>
		<v-text-field v-else-if="isSelector" class="panel-control__selector"
					  :value="value"
					  dense hide-details
					  :disabled="isDisabled"
					  :label="ui.caption"
					  :loading="isSelectorLoading || isValidating"
					  append-icon="mdi-dots-horizontal"
					  outlined readonly
					  @click="onSelectorClick"
					  @click:append="onSelectorClick">
			<template v-slot:append>
				<v-icon v-if="typeof(value) !=='undefined' && value!==null"
						class="dots"
						@click.stop="onSelectorClear">mdi-close
				</v-icon>
				<v-icon class="dots" @click="onSelectorClick">mdi-dots-horizontal</v-icon>
			</template>
		</v-text-field>
		<!--<span v-else-if="isSelector" class="panel-control__selector"
			  @click="onSelectorClick">
			<v-text-field v-else-if="isInput" v-model="value"
						  outlined dense hide-details
						  :readonly="isReadonly"
						  class="panel-control__selector-body"/>
			<span class="panel-control__selector-icons">
				<v-icon v-if="value!==null" class="dots" @click.stop="onSelectorClear">mdi-close</v-icon>
				<v-icon class="dots">mdi-dots-horizontal</v-icon>
			</span>
		</span>-->
		<v-btn v-else-if="isButton" :disabled="isDisabled" :loading="isButtonLoading"
			   class="panel-control__btn" @click="onBtnClick">{{
				ui.caption
			}}
		</v-btn>
		<div v-else-if="isImage" class="panel-control__image"
			 :class="{'--with-content':!!imageBlobValue, '--uploadable':parentProcRights.IS_INSERT}">
			<v-progress-circular v-if="isImageLoading" indeterminate/>
			<template v-else>
				<img v-if="!!imageBlobValue" :src="imageBlobValue" @click="onImageClick"/>
				<template v-if="!isDisabled">
					<input type="file"
						   class="panel-control__image-input"
						   ref="upload-input" title=""
						   @change="onImageUploadSelected"/>
					<template v-if="!!imageBlobValue">
						<v-btn v-if="!isNewItem && isImageUploadSelected && !isCard" :loading="isImageSaving"
							   class="panel-control__image-btn-save" @click="onImageSave">
							Сохранить
							<v-icon x-small class="ml-1">mdi-content-save</v-icon>
						</v-btn>
						<div class="panel-control__image-btns">
							<v-btn v-if="parentProcRights.IS_INSERT && !isImageSaving" fab
								   :loading="isImageSaving"
								   class="panel-control__image-btn-upload" @click="onImageUpload" title="Загрузить">
								<v-icon x-small>mdi-upload</v-icon>
							</v-btn>
							<v-btn v-if="parentProcRights.IS_DELETE && !isImageSaving" fab
								   class="panel-control__image-btn-del" @click="onImageDelete" title="Удалить">
								<v-icon x-small>mdi-close</v-icon>
							</v-btn>
						</div>
					</template>
					<div v-else-if="parentProcRights.IS_INSERT" class="panel-control__image-placeholder">Загрузить<br/>изображение
					</div>
				</template>
			</template>
		</div>
		<proc v-else-if="isProcPanel"
			  :ui="ui"
			  :id="ui.ID_PROCNAME"
			  :formId="formId"
			  :params="params"
		/>
		<panel v-else-if="isPanel" class="panel-control__panel" :ui="ui" :params="params" :formId="formId"/>
		<span v-else class="panel-control__static">{{ ui.caption }}</span>

		<proc-calendar-dialog v-if="isCalendar" :params="calendarDialog" @set="onCalendarSetDate"/>
		<proc-dialog v-else-if="isSelector"
					 :params="childProcDialog"
					 @click="onChildClick"
					 @open="onChildOpen"
					 @close="onChildProcClose"/>
		<confirm-dialog v-if="isImage" :params="confirmDialog"/>
		<v-snackbar v-model="hasError" color="red" top>
			<template v-slot:action="{ attrs }">
				<v-btn
					color="white"
					icon
					v-bind="attrs"
					@click="error = null"
				>
					<v-icon>
						mdi-close
					</v-icon>
				</v-btn>
			</template>
			{{ error }}
		</v-snackbar>
	</div>
</template>

<script>
import ProcCalendarDialog from "@/components/elements/proc-calendar-dialog";
import ProcDialog from "@/components/elements/proc-dialog";
import ConfirmDialog from "@/components/elements/confirm-dialog";
import {dmy2ymd, iso2dmyhm, iso2dmy, dmyhms2Iso, dmyhm2Iso} from "@/utils/date";
import {base64ToBytes, bytesToBase64, md5} from "@/utils/string";
import {colorFromDelphi} from "@/utils/utils";
import Vue from "vue";
import vp from "@/mixins/vp";
import Config from "@/config";
import {debounce} from "lodash";

const INPUT_HEIGHT = 21;
const DEFAULT_FONT_SIZE_TEXTAREA = "11px";

export default {
	name: "panel-control",
	components: {ProcCalendarDialog, ProcDialog, ConfirmDialog},
	props: {
		ui: {},
		formId: {},
		params: {},
	},
	mixins: [vp],
	data: () => ({
		calendarDialog: {
			isVisible: false,
			date: null,
			withTime: null,
		},
		childProcDialog: {
			isVisible: false,
			id: null,
			parentId: null,
			formId: null,
			btn: null,
			params: null,
			hideButtons: true,
			withHeader: true,
			isCard: false,
			readonly: true,
			withOpen: false,
			item: null,
			col: null,
		},
		confirmDialog: {
			isVisible: false,
			isLoading: false,
			title: "Удалить изображение?",
			callback: null
		},
		isSelectorLoading: false,
		isImageSaving: false,
		isImageUploadSelected: false,
		isValidating: false,
		canValidate: false,
		canValidateTimeout: null,
		error: null,
		//imageSrc: null,
		//isLoading: false,
		//text: null,
		//isEmpty: false,

		validateDebounced: null,
		onValueChangeDebounced: null,
	}),
	computed: {
		value: {
			get() {
				if (this.parentProc?.item && this.ui.parentProcField) {
					let val = this.parentProc.item[this.ui.parentProcField];
					//console.log("VALUE BEFORE: "+this.col.CAPTION+": "+val + ": isDate"+this.isDate);
					if (this.isDate) val = iso2dmy(val);
					else if (this.isDateTime) val = iso2dmyhm(val);
					//console.log("VALUE AFTER: "+this.col.CAPTION+": "+val);
					return val;
				}
				return null;
			},
			set(val) {
				if (this.parentProc?.item && this.ui.parentProcField) {
					if (this.isDate && val?.match(/^\d{2}\.\d{2}\.\d{4}$/)) val = dmy2ymd(val);
					//else if (this.isDateTime && val?.match(/^\d{2}\.\d{2}\.\d{4} \d{2}:\d{2}$/)) val = dmyhm2Iso(val);
					else if (this.isDateTime && val?.match(/^\d{2}\.\d{2}\.\d{4} \d{2}:\d{2}(:\d{2})?$/)) val = dmyhm2Iso(val);
					//console.log("VALUE: "+val);
					this.parentProc.item[this.ui.parentProcField] = val;

					//console.log("CONTROL "+this.id+" changed: "+val);
				}
			}
		},
		textBlobValue: {
			get() {
				if (this.parentProc?.blobs) {
					const content = this.parentProc.blobs[this.ui.parentProcField];
					return new TextDecoder().decode(base64ToBytes(content));
				}
				return null;
			},
			set(val) {
				//this.blob = val;
				const bytes = new TextEncoder().encode(val);
				this.parentProc.blobs[this.ui.parentProcField] = bytesToBase64(bytes);
			}
		},
		imageBlobValue: {
			get() {
				// has anything from parent proc (item)?
				//console.log("imageBlobValue.get", this.parentProc?.procNameId);
				if (this.parentProc?.blobs) {
					let content = this.parentProc.blobs[this.ui.parentProcField];
					if (!content) return null;
					// todo jpeg only?
					return "data:image/jpeg;base64," + content;
				}

				return null;
			},
			set(val) {
				this.parentProc.blobs = this.parentProc.blobs || {};
				val = val?.replace(/^data:.+?;base64,(.*)$/, "$1");
				//this.blob = val;
				//console.log("RECEIVED: ", bytesToBase64(val));
				//const bytes = new TextEncoder().encode(val);
				Vue.set(this.parentProc.blobs, this.ui.parentProcField, val);
			}
		},
		id() {
			return this.ui.COMPNAME;
		},
		item() {
			return this.parentProc?.item;
		},
		hasError: {
			get() {
				return !!this.error;
			},
			set(v) {
				if (!v) this.error = null;
			}
		},
		isNewItem() {
			const key = this.parentProc?.meta?.KEYFIELD;
			if ( key ) return !this.item || !this.item[key];
			return true;
		},
		isDebug() {
			return !!this.$route.query?.debugControls;
		},
		isDataLoading() {
			if (this.isStatic || this.isLabel) return false;
			return !this.parentProc || this.parentProc.isLoading;
		},
		appendIcon() {
			if (this.isInput && this.isURL) return "mdi-open-in-new";
			return null;
		},
		controlClass() {
			return {
				// control types
				"--input": this.isInput,
				"--textarea": this.isTextarea,
				"--calendar": this.isCalendar,
				"--checkbox": this.isCheckbox,
				"--selector": this.isSelector,
				"--btn": this.isButton,
				"--static": this.isStatic,
				"--label": this.isLabel,
				"--image": this.isImage,
				"--proc": this.isProcPanel,
				"--panel": this.isPanel,

				// states
				"--debug": this.isDebug,
				"--error": this.hasError,
				"--readonly": this.isReadonly,
				"--disabled": this.isDisabled,
			};
		},
		controlStyle() {
			let s = null;

			if (this.isLG) {
				let width = this.ui.width + "px";
				let height = this.ui.height + "px";
				if (this.ui.align?.match(/alClient/)) {
					width = "100%";
					height = "100%";
				} else if (this.isTextarea) {
					if (this.ui.anchors?.match(/akLeft/) && this.ui.anchors?.match(/akRight/)) width = "100%";
					if (this.ui.anchors?.match(/akTop/) && this.ui.anchors?.match(/akBottom/)) height = "100%";
				}

				s = {
					//width: this.ui.children.length > 1 ? (this.ui.width * 0.9) + "px" : "100%",
					//width: "100%",
					//height: this.ui.children.length > 1 ? (this.ui.height * 0.9) + "px" : "100%",
					//height: "100%",
					//width: this.ui.width + "px",
					//height: this.ui.height + "px",
					left: this.ui.left + "px",
					top: this.ui.top + "px",
					width,
					height,
				}

				if (this.ui.WEB_FONT_SIZE) {
					s.fontSize = this.ui.WEB_FONT_SIZE + "px";
				} else s.fontSize = DEFAULT_FONT_SIZE_TEXTAREA;
				if (this.ui["font.color"]) {
					s.color = colorFromDelphi(this.ui["font.color"]);
				}
				/*if (this.ui["font.size"]) {
					s.fontSize = Math.abs(parseInt(this.ui["font.size"])) + "px";
					//console.log("Font-size",s.fontSize);
				}*/
				if (this.ui["font.style"]) {
					//console.log("FONT STYLE: ", this.ui["font.style"]);
					let styles = this.ui["font.style"].substring(1, this.ui["font.style"].length - 1);
					if (styles) {
						styles = styles.split(",");
						styles.forEach(el => {
							el = el.trim();
							if (el === "fsUnderline") s.textDecoration = "underline";
							else if (el === "fsBold") s.fontWeight = "bold";
							else if (el === "fsItalic") s.fontStyle = "italic";
						});
					}
				}
				if (this.ui["color"]) {
					//console.log("COLOR: ", this.ui["color"], colorFromDelphi(this.ui["color"]));
					s.backgroundColor = colorFromDelphi(this.ui["color"]);
				} else if (this.isTextarea || this.isInput) s.backgroundColor = "white";
			}

			//if (this.isDisabled && !this.isPanel && !this.isProcPanel && !this.isStatic) s.opacity = .4;
			//if ( this.isDisabled )  s.opacity = .25;

			//if ( this.canValidate ) s.backgroundColor = "red";

			return s;
		},
		isLabel() {
			return this.ui.COMPNAME.match(/Label/i);
		},
		isTextarea() {
			return this.ui.COMPNAME.match(/DBEdit/i) && this.ui.height > INPUT_HEIGHT && !this.ui.ImeName;
		},
		isInput() {
			return this.ui.COMPNAME.match(/DBEdit/i) && this.ui.height <= INPUT_HEIGHT && !this.ui.ImeName;
		},
		isInputInteger() {
			return this.isInput
				&& (this.col?.FIELD_TYPE_EX?.match(/^integer/)
					|| this.col?.FIELD_TYPE_EX?.match(/^decimal/));
		},
		isStatic() {
			return this.ui.COMPNAME.match(/TStaticText/i);
		},
		/**
		 * TODO какая разница с isCalendar? оставить isDate
		 * @returns {RegExpMatchArray}
		 */
		isDate() {
			//console.log("COL", this.col);
			return this.col?.FIELD_TYPE_EX?.match(/^date( .+)?$/);
		},
		isDateTime() {
			return this.col?.FIELD_TYPE_EX?.match(/^datetime/)
				|| this.col?.FIELD_TYPE_EX?.match(/^timestamp/);
		},
		/**
		 * TODO какая разница с isDate?
		 * @returns {RegExpMatchArray}
		 */
		isCalendar() {
			return this.ui.COMPNAME.match(/TDBDateTimeEdit/i);
		},
		isCheckbox() {
			return this.ui.COMPNAME.match(/TdxDBCheckEdit/i);
		},
		isSelector() {
			return !!this.ui.ImeName;
		},
		isButton() {
			return this.ui.COMPNAME.match(/TButton/i);
		},
		isImage() {
			return this.ui.COMPNAME.match(/DBImage/i);
		},
		isPanel() {
			return this.ui.COMPNAME.match(/Panel/i) && !this.ui.ID_PROCNAME;
		},
		isProcPanel() {
			return this.ui.COMPNAME.match(/Panel/i) && this.ui.ID_PROCNAME;
		},
		isDisabled() {
			if (this.isPanel || this.isProcPanel || this.isStatic) return false;
			return !this.parentProc?.item || this.isDataLoading;
		},
		isReadonly() {
			if (this.isPanel || this.isProcPanel || this.isStatic) return false;
			return this.parentProc?.isReadonly || (this.col?.IS_READONLY ? true : false);
		},
		isImageLoading() {
			return this.isImage && this.parentProc?.isLoading;
		},
		isURL() {
			return !!Number(this.col?.IS_URL?.trim());
		},
		/**
		 * todo what is it for?
		 * @returns {any}
		 */
		isCard() {
			// todo this prop is never set
			return this.parentProc?.isCard;
		},
		isParentProcLoading() {
			return this.parentProc?.isLoading;
		},
		formProcs() {
			return this.$store.state.formProcs;
		},
		parentProc() {
			// находим родительскую выборку (может еще быть недоступна!)
			return this.formProcs.find(el => el.formId === this.formId && el.procNameId === this.ui.parentProcId);
		},
		parentProcButtons() {
			return this.parentProc?.buttons;
		},
		isButtonLoading() {
			const button = this.parentProcButtons?.find(el => el.ID_OPERATION === this.ui.ID_OPERATION);
			return button?.isLoading;
		},
		col() {
			return this.parentProc?.headers?.find(el => el.PROCPARAM === this.ui.parentProcField);
		},
		headers() {
			return this.parentProc?.headers;
		},
		isBlob() {
			// блоб это колонка с FIELD_TYPE_EX ^= blob
			// или value похоже на 0x0000004500000000
			return !!this.col?.FIELD_TYPE_EX?.match(/^blob/i) || ("" + this.value)?.match(/^\dx\d{16}$/);
		},
		parentProcRights() {
			if (!this.parentProc?.meta) return {};
			return {
				IS_INSERT: this.parentProc.meta.IS_INSERT,
				IS_DELETE: this.parentProc.meta.IS_DELETE,
				IS_UPDATE: this.parentProc.meta.IS_UPDATE,
			};
		},
	},
	watch: {
		value(val, old) {
			//if ( this.col?.PROCPARAM === "QTTY") console.log(this.col?.PROCPARAM+" changed, can validate? "+this.canValidate);
			this.onValueChangeDebounced(val, old);
		},
		item(val, old) {
			if (val !== old) {
				this.isImageUploadSelected = false;
				//if ( this.col?.PROCPARAM === "ID_ADDDATE") console.log(this.col?.PROCPARAM+" item set: ", val, md5(val)===md5(old), val===old);
				this.canValidate = false;
				if (val) {
					clearTimeout(this.canValidateTimeout);
					//if ( this.ui.parentProcField==='ID_ADDDATE' ) console.log("SETTING VALIDATE TIMEOUT");
					this.canValidateTimeout = setTimeout(() => this.canValidate = true, 300);
				}
			}
		},
		isParentProcLoading() {
			//console.log(this.col?.PROCPARAM+": parent proc loading "+ this.isParentProcLoading);
			/*if (this.isParentProcLoading === false) {	// важна проверка именно на false
				clearTimeout(this.canValidateTimeout);
				this.canValidateTimeout = setTimeout(() => this.canValidate = true, 150);
			} else this.canValidate = false;*/
		},
		/*parentValue(val) {
			if (this.isDateTime && typeof val === "string" && val.length > 10) val = val.substring(0, 10);
			this.value = val;

			this.isImageUploadSelected = false;
		},*/
	},
	methods: {
		onClickEditable() {
		},
		onClickCheckbox() {
			this.onClickEditable();
			setTimeout(() => {
				this.value = !this.value;
				this.onChange();
			}, 150);
		},
		onClickCalendar() {
			this.onClickEditable();
			if (this.isReadonly) return;
			this.calendarDialog.date = dmyhm2Iso(this.value);
			//console.log("CALENDAR GETS VALUE: "+this.value);
			//console.log("CALENDAR GETS DATE: "+this.calendarDialog.date);
			this.calendarDialog.withTime = this.isDateTime;
			this.calendarDialog.isVisible = true;
			this.calendarDialog.callback = this.onChange;
		},
		onCalendarSetDate(value) {
			console.log("CALENDAR SETS DATE: " + value);
			this.value = value;//iso2dmyhms(value);
		},
		onSelectorClick() {
			if (this.isDisabled || this.isReadonly) return;
			this.onClickEditable();

			//console.log("click selector", this.col);

			const col = this.col;
			this.isSelectorLoading = true;

			// selector child dialog
			// fetch selector mapping
			this.$store.dispatch("get", {
				action: "SelectorController",
				params: {
					id: col.ID_PROCPARAM,
				},
			})
				.then(res => {
					const params = [];

					// map parent item
					if (res?.page?.mapping) {

						res.page.mapping?.forEach(p => {
							const type = p.PARAM_TYPE.trim().toLowerCase();

							if (type === "поле") {
								// data of the edited card item (from parent DS)
								params.push({
									INPARAMNAME: p.PARAMNAME,
									VALUE: this.item[p.SOURCE_NAME]
								});
							} else if (type === "параметр") {
								params.push({
									INPARAMNAME: p.PARAMNAME,
									VALUE: this.params.find(el => el.INPARAMNAME === p.SOURCE_NAME)?.VALUE
								});
							} else if (type === "значение") {
								params.push({
									INPARAMNAME: p.PARAMNAME,
									VALUE: p.DEFAULT_VALUE
								});
							}
						});
					}

					//console.log("PARAMS TO CHILD proc", params);

					this.childProcDialog.params = params;
					this.childProcDialog.item = this.item;
					this.childProcDialog.col = col;
					this.childProcDialog.id = col.ID_SELECTION_LKP;
					this.childProcDialog.formId = null;
					this.childProcDialog.parentId = null;
					this.childProcDialog.mainProcId = null;
					this.childProcDialog.hideButtons = true;
					this.childProcDialog.withHeader = true;
					this.childProcDialog.isCard = false;
					this.childProcDialog.btn = null;
					this.childProcDialog.readonly = true;
					this.childProcDialog.withOpen = true;
					this.childProcDialog.isVisible = true;
					this.childProcDialog.callback = this.onChange;
				})
				.catch(err => {
					console.error("ERROR", err);
					if (err?.error) this.error = err?.error;
					this.$emit("error", err?.error);
				})
				.finally(() => {
					this.isSelectorLoading = false;
				});
		},
		onSelectorClear() {
			this.value = null;
			this.onChange();
		},
		/**
		 * Вызывается на клик по элементу в дочерней выборке.
		 */
		onChildClick(child) {
			// todo перенесли логику в даблклик
		},
		/**
		 * Вызывается на даблклик по элементу в дочерней выборке (для установки в селекторе в текущей выборке).
		 */
		onChildOpen(child) {
			// child dialog clicked an item
			this.childProcDialog.isVisible = false;
			const col = this.childProcDialog.col;
			const item = this.childProcDialog.item;
			//console.log("CHILD", child);
			//console.log("COL", col);
			//console.log("ITEM BEFORE", oldItem);
			item[col.KEYFIELD] = child[col.LOOKUPKEY];
			item[col.PROCPARAM] = child[col.LOOKUPRESULT];
			//console.log("ITEM AFTER", item);
			//this.update(item);
		},
		/**
		 * Вызывается при закрытии окна дочерней выборки.
		 */
		onChildProcClose() {
			//console.log("CLOSED", this.childProcDialog);
		},
		onImageSave() {
			this.saveParentItem();
		},
		onImageDelete() {
			this.confirmDialog.isVisible = true;
			this.confirmDialog.callback = () => {
				this.imageBlobValue = null;

				if (!this.isCard && !this.isNewItem) this.saveParentItem();
			};
		},
		onImageUpload() {
			const input = this.$refs['upload-input'];
			input.click();
		},
		async onImageUploadSelected() {
			const input = this.$refs['upload-input'];
			//const file = input.files[0];	// todo пока всегда один файл?
			//if (!file) return;
			//console.log("onUploadSelected", file);

			// read uploaded files
			const uploads = await new Promise(async (resolve, reject) => {
				if (input.files) {
					const ps = [];
					[...input.files].forEach(file => {
						ps.push(new Promise((resolve, reject) => {
							const reader = new FileReader();
							reader.loadend = () => {
								//console.log("Upload loaded?");
							}
							reader.onload = (e) => {
								resolve({
									name: file.name,
									size: file.size,
									content: e.target.result
								});
							}
							reader.readAsDataURL(file);
							//reader.readAsArrayBuffer(file);
							//reader.readAsBinaryString(file);
							//console.log("FILE", this.uploadForm);
						}));
					});
					await Promise.all(ps).then(values => {
						resolve(values);
					});

				} /*else if (this.uploadForm.draggedFile) {
					resolve([{
						name: this.uploadForm.draggedFile.name,
						size: this.uploadForm.draggedFile.size,
						content: this.uploadForm.draggedFile
					}]);
				} */ else resolve(null);
			});
			if (!uploads) return;
			//this.value = uploads;
			//console.log("uploads", uploads);

			// todo one file only for now?
			this.imageBlobValue = uploads[0].content;
			this.isImageUploadSelected = true;
		},
			onImageClick() {
				if (!this.imageBlobValue) return;
				const byteCount = (s) => {
					return encodeURI(s).split(/%..|./).length - 1;
				};
				const mime = this.imageBlobValue.split(";")[0];
				const file = {
					readonly: true,
					mime,
					//src: this.imageBlobValue,
					content: this.imageBlobValue,
					size: byteCount(this.imageBlobValue),
					name: "Изображение",
				};
				this.$store.state.filesDialog.item = file;
				this.$store.state.filesDialog.files = [file];
				this.$store.state.filesDialog.currentIndex = 0;
				this.$store.state.filesDialog.isVisible = true;
			},
		onBtnClick() {
			console.log("panel-conrol: btn click", this.parentProc?.procNameId + "." + this.ui.ID_OPERATION);
			this.$store.state.procsShouldRunOperation.push({
				formId: this.formId,
				procId: this.parentProc?.procNameId,
				operationId: this.ui.ID_OPERATION,
			});
		},
		/**
		 * Вызывается на стандартные change события - изменения значений пользователем.
		 */
		onChange() {
			if (this.parentProc?.item && !this.isReadonly && !this.isDisabled) {
				// enter parent proc+item into edit mode
				//console.log("Control " + this.ui.COMPNAME + " report edit mode for element", this.parentProc?.item);
				this.$set(this.parentProc, "shouldEnterEditMode", true);
			}
		},
		/**
		 * В отличие от onChange - вызывается на любые изменения значения, в т.ч. не пользователем.
		 */
		onValueChange() {
			//if ( this.col?.PROCPARAM === "ID_ADDDATE") console.log("Column changed: ", this.col);
			if (!this.canValidate) {
				if (this.col?.PROCPARAM === "ID_ADDDATE") console.log("Can not validate");
				return;
			}
			let col = this.col;
			if (!col) return;
			console.group("Column changed: " + col.PROCPARAM, col);
			// for selectors - check item[col.KEYFIELD]
			if (this.isSelector) {
				col = this.headers?.find(el => el.PROCPARAM === col.KEYFIELD);
				console.log("Real selector col: ", col);
			}
			/*else if ( this.isCheckbox ) {
				col = this.headers?.find(el=>el.PROCPARAM === col.KEYFIELD);
				console.log("Real checkbox col: ", col);
			}*/
			if (col?.IS_USESVALIDATES || col?.ONVALIDATE) {
				console.log("Will validate value: " + this.item[col.PROCPARAM]);
				if (this.validateDebounced) this.validateDebounced(col);
			}
			console.groupEnd();
		},
		validationRule() {
			// todo
			return true;//return !!this.error ? this.error : true;
		},
		async saveParentItem() {
			if (!this.parentProc) return;

			this.isImageSaving = true;
			return this.$store.dispatch("put", {
				action: "ItemController",
				params: {
					procNameId: this.parentProc.procNameId,
					item: this.parentProc.item,
					blobs: this.parentProc.blobs,
					params: this.parentProc.params
				},
			})
				.then(res => {
					//console.log("RES", res);
					this.isImageUploadSelected = false;
				})
				.catch(err => {
					console.error("ERROR", err);
					if (err?.error) this.error = err?.error;
					this.$emit("error", err?.error);
				})
				.finally(() => {
					this.isImageSaving = false;
				});
		}
	},
	mounted() {
		// important to create a new function for each control!
		this.validateDebounced = debounce(function (col) {
			if (!this.item || !col) return;
			//this.$emit("validate", item, col);
			//console.log("Validating...");
			//console.log("Control validating " + col.PROCPARAM, col);
			this.isValidating = true;
			return this.$store.dispatch("post", {
				action: "ProcOnValidateController",
				params: {
					id: this.parentProc?.procNameId,
					item: this.item,
					col,
					params: this.params
				},
			})
				.then(res => {
					const values = res?.page?.values;
					//console.log("\tControl validated " + col.PROCPARAM, values);
					if (values) {
						Object.keys(values).forEach(key => this.item[key] = values[key]);
					}
				})
				.catch(err => {
					console.error("ERROR", err);
					if (err?.error) this.error = err?.error;
					this.$emit("error", err?.error);
				})
				.finally(() => {
					//console.groupEnd();
					this.isValidating = false;
				});
		}, Config.INPUT_DEBOUNCE_TIMEOUT)
		this.onValueChangeDebounced = debounce(function (val, old) {
			this.onValueChange(val, old);
		}, Config.INPUT_DEBOUNCE_TIMEOUT_SHORT)
	}
}
</script>

<style lang="scss">

.panel-control {
	position: relative;
	margin: 0 0 1.5rem 0;
	width: 100%;

	@include up($lg) {
		position: absolute;
		left: 0;
		top: 0;
		font-size: $font-size-control;
		line-height: 100%; //$font-size-control;
		width: unset;
		margin: unset;
	}
	@include transition();

	&__ui-name {
		position: absolute;
		z-index: 2;
		left: 0;
		top: 0;
		width: 100%;
		height: 100%;
		background: rgba(0, 0, 255, .15);
		padding: 4px;
		color: black;
		font-size: 12px;
		display: none;
		justify-content: center;
		align-items: center;
		pointer-events: none
	}

	&:hover {
		& > .panel-control__ui-name {
			display: flex;
		}
	}

	&.--static {
		//display: inline-block;
		font-size: $font-size-smaller;
		margin: 0 0 .5rem 0;
		@include up($lg) {
			font-size: $font-size-static; // размер по-умолчанию
			margin: unset;
		}
	}

	&.--label {
		margin: 0 0 .5rem 0;
		font-size: $font-size-smaller;

		@include up($lg) {
			margin: unset;
			font-size: $font-size-static; // размер по-умолчанию
			z-index: 1;
		}
	}

	&.--selector {
		display: flex;
		justify-content: space-between;

		input {
			cursor: pointer;
		}

		&-icons {
			display: inline;
			flex: 0;

			.v-icon {
				/*position: absolute;
				right: 4px;
				top: 8px;*/
				font-size: 12px;
				margin-left: 4px;
				opacity: .7;
				transition: opacity 0.2s;

				&:hover {
					opacity: 1;
				}
			}
		}
	}

	&.--image {
		border-top: 1px solid $border-color;
		border-left: 1px solid $border-color;
		background-color: $table-bg-color;
		border-right: 1px solid $white;
		border-bottom: 1px solid $white;
	}

	&.--proc {
	}

	&.--panel {
		/*margin-top: -2px;
		border-top: 1px solid $border-color;
		border-left: 1px solid $border-color;
		background-color: $table-bg-color;
		border-right: 1px solid $white;
		border-bottom: 1px solid $white;*/
	}

	&.--debug {
		background: $border-color;
	}

	&.--error {
		.v-input {
			background: map-get($red, "lighten-3");

			input {
				color: white;
			}
		}
	}

	&.--readonly {
		.v-input {
			//background: map-get($blue, "lighten-5");
			opacity: 0.6;
		}
	}

	&.--disabled {
		opacity: 0.4;
	}

	&.--input, &.--textarea, &.--selector, &.--calendar {

		@include up($lg) {
			// default control background
			background: $white;
		}
	}

	&__input {
		input {
			&[type=number] {
				text-align: right;
			}

			@include hideInputIntegerScroller;
		}
	}

	&__btn {
		font-size: $font-size-control !important;
		line-height: $font-size-control;
		padding: 0 4px !important;
		letter-spacing: 0 !important;
		text-transform: none;
		width: 100%;
		height: 19px !important;
	}

	@include up($lg) {
		&.--checkbox {
			margin-top: 2px;
			cursor: pointer;
			display: inline-flex;
			align-items: center;
			font-size: $font-size-static;

			.v-icon {
				font-size: 14px;
			}
		}

		&__calendar, &__input, &__selector {
			border-radius: 0 !important;

			&.v-input {
				@include transition();

				.v-input__slot {
					min-height: 19px !important;
					padding: 0 4px 0 6px !important;
					background-color: inherit; //$white;

					input {
						font-size: $font-size-control;
						line-height: $font-size-control;
						padding: 0;
						@include transition();
					}

					.v-input__append-inner {
						margin-top: 1px !important;
						padding-left: 0 !important;

						button {
							font-size: 16px;
						}

						.v-input__icon {
							height: 100%;
							width: 16px;
							min-width: 16px;
						}
					}
				}
			}
		}
	}

	&__image {
		position: relative;
		display: flex;
		align-items: center;
		justify-content: center;
		height: 30vh;

		@include up($lg) {
			height: 100%;
		}

		&.--with-content {
			.panel-control__image-placeholder {
				opacity: 0;
			}
		}

		&.--uploadable:not(.--with-content) {
			.panel-control__image-input {
				width: 100%;
				height: 100%;
			}
		}

		img {
			/*position: absolute;
			left: 50%;
			top: 50%;
			transform: translateX(-50%) translateY(-50%);*/
			max-width: 100%;
			max-height: 100%;
			//aspect-ratio: 1;
			object-fit: contain;
				cursor: pointer;

				&:hover {
					opacity: 0.8;
				}
		}

		/*.v-image {
			//height: 100%;
			flex: unset !important;
			//max-width: unset !important;

			img {
				position: absolute;
				left: 0;
				top: 0;
				width: 100%;
				height: 100%;
				opacity: 0;
			}
		}*/

		.v-btn {
			@include up($lg) {
				@include transition();
				&:not(.panel-control__image-btn-save) {
					opacity: 0;
				}
			}
		}

		&-btns {
			position: absolute;
			z-index: 3;
			right: 16px;
			top: 16px;
			width: 100px;
			height: 32px;
			display: flex;
			align-items: center;
			justify-content: flex-end;
			gap: 16px;

			@include up($lg) {
				right: 8px;
				top: 8px;
				width: 100px;
				height: 20px;
				gap: 6px;
			}
		}

		&-btn {
			&-del {
				width: 32px !important;
				min-width: 32px !important;
				height: 32px !important;

				@include up($lg) {
					width: 20px !important;
					min-width: 20px !important;
					height: 20px !important;
				}
			}

			&-upload {
				width: 32px !important;
				min-width: 32px !important;
				height: 32px !important;

				@include up($lg) {
					width: 20px !important;
					min-width: 20px !important;
					height: 20px !important;
				}
			}

			&-save {
				position: absolute;
				z-index: 3;
				left: auto;
				right: auto;
				bottom: 16px;
				margin: 0 auto;

				@include up($lg) {
					bottom: 8px;
				}
			}
		}

		&-input {
			position: absolute;
			z-index: 2;
			left: 0;
			top: 0;
			width: 0;
			height: 0;
			opacity: 0;
			cursor: pointer;
		}

		&-placeholder {
			position: absolute;
			z-index: 1;
			left: 8px;
			top: 8px;
			width: calc(100% - 16px);
			height: calc(100% - 16px);
			display: flex;
			align-items: center;
			justify-content: center;
			letter-spacing: 2px;
			line-height: 150%;
			text-transform: lowercase;
			border: 1px dashed $border-color;
			text-align: center;
			color: map-get($grey, "lighten-1");
			transition: opacity .2s, color .2s, border-color .2s;
		}

		&:hover {
			/*.v-image {
				opacity: 0.3;
			}*/

			.panel-control__image-placeholder {
				opacity: 1;
				border: 1px dashed $black;
				background: rgba(255, 255, 255, 0.25);
				color: inherit;
			}

			.v-btn {
				opacity: 1;
			}
		}
	}

	&__textarea {
		display: flex;
		align-items: center;
		justify-content: center;
		height: 100%;
		border-top: 1px solid $border-color;
		border-left: 1px solid $border-color;
		border-right: 1px solid $white;
		border-bottom: 1px solid $white;
		//background-color: $table-bg-color;
		background-color: inherit; //$white;

		/*&.--disabled {
			background: $table-bg-color !important;
		}*/

		textarea {
			width: 100%;
			height: 100%;
			//font-size: $font-size-control;
			font-size: inherit;
			line-height: 100%; //$font-size-control;
			padding: 8px;
			outline: none;
			resize: none;
			color: inherit;

			//@include transition();
			@include scroll();
		}
	}

	&__panel.panel {
		background: unset;
	}
}
</style>
