<template>
	<v-dialog v-model="params.isVisible"
			  content-class="proc-calendar-dialog" width="320px">
		<v-card class="pb-5 text-center">
			<v-card-actions>
				<v-btn
					color="primary"
					text
					@click="onClose">
					Отмена
				</v-btn>
			</v-card-actions>
<!--			<v-icon class="proc-calendar-dialog__close" @click="onClose">mdi-close</v-icon>-->

			<v-date-picker
				v-model="internalDate"
				locale="ru-RU"
				:first-day-of-week="1"
				no-title
				scrollable
				@input="onSetDate">
			</v-date-picker>

			<div v-if="withTime" class="d-flex justify-center mb-8">
				<time-picker v-model="internalTime"/>
			</div>

			<div v-if="withTime">
				<v-btn color="primary" class="proc-calendar-dialog__btn-ok mb-4"
					   @click="onOk">Ок
				</v-btn>
			</div>
			<v-btn text @click="onClear">Сбросить</v-btn>
		</v-card>
	</v-dialog>
</template>

<script>
import Vue from "vue";
import {iso2ymd, iso2hm, yymd2ymd} from "@/utils/date";
import TimePicker from "@/components/elements/time-picker";

export default {
	name: "proc-calendar-dialog",
	components: {
		TimePicker
	},
	props: ["params"],
	data: () => ({
		internalDate: null,
		internalTime: "00:00",
	}),
	computed: {
		withTime() {
			return this.params?.withTime;
		},
		isFullySet() {
			return !!this.internalDate && (!this.withTime || !!this.internalTime);
		}
	},
	watch: {
		"params.date": {
			immediate: true,
			handler() {
				//console.log("this.params.date", this.params.date);
				this.internalDate = iso2ymd(this.params.date);
				//console.log("internalDate", this.internalDate);
				if (this.params?.date?.match(/^\d{4}-\d{2}-\d{2} \d{2}:\d{2}(:\d{2})?$/)) {
					this.internalTime = iso2hm(this.params.date);
				} else this.internalTime = "00:00";
			}
		}
	},
	methods: {
		onClose() {
			this.params.isVisible = false;
		},
		onSetDate() {
			if (!this.withTime) this.onOk();
		},
		onClear() {
			this.internalDate = null;
			this.internalTime = "00:00";
			this.params.date = null;
			this.onSetDate();
		},
		onOk() {
			let date = null;
			if ( this.internalDate ) {
				date = iso2ymd(this.internalDate) + (this.withTime ? " " + this.internalTime : "");
			}
			this.params.date = date;
			this.$emit("set", date);
			if (this.params.callback) {
				this.params.callback(this.params);
				this.params.callback = null;
			}
			this.params.isVisible = false;
		}
	}
}
</script>

<style lang="scss">
.proc-calendar-dialog {
	position: relative;

	&__close {
		position: absolute !important;
		top: 8px;
		right: 8px;
	}

	&__btn-ok {
		width: 50%;
	}
}
</style>