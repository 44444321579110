<template>
	<div class="panel-tabs">
		<v-tabs v-model="tab">
			<v-tab v-for="(child, index) of children" :key="'tab-'+index">{{ child.caption }}</v-tab>
		</v-tabs>
		<v-tabs-items v-model="tab" touchless>
			<v-tab-item v-for="(child, index) of children" :key="'tab-item-'+index" transition="false">
				<panel v-if="tab===index" :ui="child" :params="params" :formId="formId"/>
			</v-tab-item>
		</v-tabs-items>
	</div>
</template>

<script>
export default {
	name: "panel-tabs",
	props: {
		ui: {},
		params: {	// здесь структура параметров НЕ совпадает с тем, что непосредстенно у proc
			type: Array,
			default: () => []
		},
		formId: {},
	},
	data: () => ({
		tab: null
	}),
	computed: {
		children() {
			return this.ui.children;
		},
	},
	watch: {},
}
</script>

<style lang="scss">

.panel-tabs {
	//background: #aaa;
	width: 100%;
	height: 100%;
	display: flex;
	flex-direction: column;

	.v-tabs {
		flex-grow: 0;
		.v-tabs-bar {
			background: $thead-bg-color !important;
			height: 24px;
			.v-tab {
				font-size: 10px;
				letter-spacing: 0;
				text-transform: none;
				max-width: none;
			}
		}
	}

	.v-tabs-items {
		flex: 1;
		display: flex;

		.v-window__container {
			flex: 1;
			display: flex;

			.v-window-item {
				flex: 1;
			}
		}
	}
}
</style>