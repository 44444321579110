<template>
</template>
<script>
import Vue from "vue";
import ConfirmDialog from "@/components/elements/confirm-dialog";
import MsgDialog from "@/components/elements/msg-dialog";
import vp from "@/mixins/vp";
import {nf, md5} from "@/utils/string";
import {date2Iso} from "@/utils/date";
import {crossDownload} from "@/utils/utils";
import {debounce} from "lodash";
import Config from "@/config";

// todo перенести сюда всю общую логику proc и ds
// todo или объединить всю логику, отказавшись от разницы в proc & ds

export default {
	name: "proc-base",
	mixins: [vp],
	props: {
		ui: {}, // описание UI-контейнера
		readonly: {
			type: Boolean,
			default: false
		},
	},
	components: {
		ConfirmDialog,
		MsgDialog,
	},
	data: () => ({
		proc: null,
		currentItem: null,
		currentItemIndex: null,	// todo @deprecated
		multiselectedItems: [],
		buttons: null,
		items: [],	// важно [] for v-data-table
		headers: null,
		pfbParamsHash: null,
		isLoading: false,
		fetchProcDebounced: null,
		btnUpload: null,
		isValidatonDisabled: false,

		confirmDialog: {
			isVisible: false,
			isLoading: false,
			isMultiselect: false,
			callback: null
		},
		childProcDialog: {
			isVisible: false,
			id: null,
			parentId: null,
			formId: null,
			btn: null,
			params: null,
			hideButtons: true,
			withHeader: false,
			isCard: false,
			readonly: true,
			withOpen: false,
			item: null,
			col: null,
			callback: null,
		},
		msgDialog: {
			isVisible: false,
			isLoading: false,
			callback: null
		},
		paramsDialog: {
			isVisible: false,
			params: null,
			paramSet: null,
			dicts: null,
			item: null,
			callback: null,	// success
			onCancel: null,	// cancellation
		},

		tableFooterRefreshCounter: 0,
		btnsRefreshCounter: 0,
	}),
	computed: {
		error: {
			get() {
				return this.$store.state.snackbar.error;
			},
			set(v) {
				return this.$store.state.snackbar.error = v;
			}
		},
		success: {
			get() {
				return this.$store.state.snackbar.success;
			},
			set(v) {
				return this.$store.state.snackbar.success = v;
			}
		},
		isDebug() {
			return !!this.$route.query?.debugProcs;
		},
		isOnForm() {
			return !!this.ui;
		},
		procNameId() {
			return this.id || this.proc?.procNameId;
		},
		parentKeys() {
			return this.ui?.parentKeys;
		},
		allFormParentProcs() {
			return this.formProcs
				.filter(el => el.formId === this.formId)
				.filter(el => el.ui?.isParent);
		},
		parentProcs() {
			return this.ui?.parentProcIds?.map(id => {
				const proc = this.allFormParentProcs.find(el => el.procNameId === id);
				//console.log("Is parent loaded: " + id + ": " + (!!proc) + ", " + proc?.isLoading);
				return {
					...proc,
					isLoading: proc ? proc.isLoading : true,
					id
				}
			});
		},
		/**
		 * true - загружены все выборки от которых зависит данная выборка.
		 * @returns {*}
		 */
		areAllParentProcsLoaded() {
			return this.parentProcs?.every(proc => !proc?.isLoading);
		},
		areAllPFBParamsSet() {
			if (!this.ui?.isChild) return true;
			//if (!this.areAllParentProcsLoaded) return false;
			/*if (this.parentKeys?.length) {
				console.group("Proc [" + this.procNameId + "] areAllPFBParamsSet:");
				console.log("parentKeys: ", this.parentKeys);
				console.log("pfbParams: ", this.pfbParams);
				console.log("areAllPFBParamsSet: " + (!this.parentKeys?.length || this.parentKeys?.every(key => this.pfbParams?.find(el => el.INPARAMNAME === key && typeof el.VALUE !== "undefined"))));
				console.groupEnd();
			}*/
			return !this.parentKeys?.length || this.parentKeys?.every(key => this.pfbParams?.find(el => el.INPARAMNAME === key && typeof el.VALUE !== "undefined"));
		},
		areSomePFBParamsSet() {
			if (!this.ui?.isChild) return true;
			//if (!this.areAllParentProcsLoaded) return false;
			if (!this.pfbParams?.length) return false;
			/*console.group("Proc [" + this.procNameId + "] areSomePFBParamsSet:");
			console.log("parentKeys: ", this.parentKeys);
			console.log("pfbParams: ", this.pfbParams);
			//this.pfbParams?.forEach(param=>console.log(param.INPARAMNAME+": "+param.VALUE));
			console.log("areAllPFBParamsSet: " + !this.pfbParams.find(param => typeof param.VALUE === "undefined"));
			console.groupEnd();*/
			return !this.pfbParams.find(param => typeof param.VALUE === "undefined");
		},
		meta() {
			return this.proc?.meta;
		},
		cols() {
			return this.headers?.map(el => el.PROCPARAM);
		},
		formProcs() {
			return this.$store.state.formProcs;
		},
		isReadonly() {
			//return this.readonly || !this.meta?.UPD_SQL || !this.meta?.IS_UPDATE;
			return this.readonly || !this.meta?.IS_UPDATE;
		},
		procsShouldRunOperation() {
			return this.$store.state.procsShouldRunOperation;
		}
	},
	watch: {
		/*error() {
			this.info = null;
		},*/
		formProcs() {
			this.initPFBParams();
		},
		pfbParams: {
			immediate: true,
			handler() {
				this.pfbParamsHash = md5(this.pfbParams);
				//console.log("[" + this.formId + "." + this.procNameId + "] WATCH pfbParams!!!", this.pfbParams);
			}
		},
		params: {
			immediate: true,
			async handler(val, old) {
				if ( this.params?.length ) this.localParams = [...this.params];
				else this.localParams = [];
			}
		},
		/**
		 * Вариант открытия/обновления 2 - выборка обновляется по выбору элемента в родительской выборке - нужно перефетчить данные
		 * обработчик вызывается только в случае обновления элементов в родительских выборках, на которые он подписан
		 * см. parentProcParams
		 */
		pfbParamsHash(val, old) {
			/*console.group(new Date().valueOf() + " Proc [" + this.formId + "." + this.procNameId + "] detects changes");
			this.pfbParams?.forEach(el => {
				console.log("\t" + el.formId + "." + el.procNameId + ":" + el.INPARAMNAME + " = " + el.VALUE);
			});
			console.groupEnd();*/
			//console.log("PROC" + this.procNameId + " params:", this.pfbParams);

			this.currentItem = null;	// важно сразу сбросить текущий айтем, чтобы это увидели дочерние выборки
			this.multiselectedItems = [];

			// загружены ли ВСЕ родительские выборки?
			//if (!this.areAllParentProcsLoaded) return;

			// все параметры из родительской выборки заполнены?
			//if (this.pfbParams?.length && this.pfbParams?.every(param => typeof param.VALUE !== "undefined")) {
			this.clearData();
			if (this.areAllPFBParamsSet) {
				//console.log(new Date().valueOf() + " Proc [" + this.formId + "." + this.procNameId + "] is updating as all PFB params are set");
				//this.refreshProcDebounced();
				this.applyProcParams();	// важно применить родительские параметры
				//if (this.procNameId === -1098414) console.log("!!Proc [" + this.formId + "." + this.procNameId + "] calling fetchProc() 2");
				this.fetchProc();
			} else {
				// clear current proc
				//console.log("THIS PROC IS OBSOLETE [" + this.formId+"."+this.procNameId+"]");
				this.unregisterProc();
			}
		},
		procsShouldRunOperation() {
			//console.log("PROC " + this.procNameId + " watches changes ");
			const notifyIndex = this.procsShouldRunOperation.findIndex(el => el.formId === this.formId && el.procId === this.procNameId);
			if (notifyIndex >= 0) {
				// необходимо вызвать операцию этой выборки
				const notify = this.procsShouldRunOperation[notifyIndex];
				console.log("Notified with operation", notify, this.buttons);
				const btn = this.buttons?.find(el => el.ID_OPERATION === notify.operationId);
				if (btn) this.onButton(btn);

				// убрать из очереди обработанную операцию
				this.procsShouldRunOperation.splice(notifyIndex, 1);
			}
		},
		isLoading() {
			//console.log("registerProc in ds.isLoading", this.isLoading);
			// перерегистрируем proc - так как отслеживаем ее состояние isLoading
			this.registerProc();
		},
		"$store.state.procIdsWithRefreshItem"(val, old) {
			//console.log("[" + this.procNameId + "] WATCH procIdsWithRefreshItem:", val);
			// поступила команда обновить текущий элемент выборки?
			if (val?.includes(this.procNameId) && (this.currentItem || this.multiselectedItems?.length)) {
				//console.log("\trefetching items!");
				this.refetchCurrentItem();

				// remove proc id from refreshed no immediately as there could be several same procs on screen
				setTimeout(() => {
					val.splice(val.findIndex(id => id === this.procNameId), 1);
				}, 150);
			}
		},
		"$store.state.procIdsWithRefresh"(val, old) {
			//console.log("[" + this.procNameId + "] WATCH procIdsWithRefresh:", val);
			// поступила команда обновить текущую выборку?
			if (val?.includes(this.procNameId)) {
				//console.log("\trefetching proc!");
				this.fetchProc();

				// remove proc id from refreshed no immediately as there could be several same procs on screen
				setTimeout(() => {
					val.splice(val.findIndex(id => id === this.procNameId), 1);
				}, 150);

			}
		},
	},
	methods: {
		/**
		 * Вызывается на нажатие любой кнопки выборки.
		 */
		onButton(btn) {
			console.log("OPERATION ", btn);
			this.$store.dispatch("log", {
				ID_OBJECT: btn.ID_OPERATION,
				ID_WEBLOGTYPE: Config.ID_WEBLOGTYPE_BTN,
			});

			if (btn.ID_OPERATION === Config.ID_OPERATION_FILE_DOWNLOAD) {
				this.downloadFile(btn);
			} else if (btn.ID_OPERATION === Config.ID_OPERATION_FILE_OPEN) {
				this.openFile(btn);
			} else if (btn.ID_OPERATION === Config.ID_OPERATION_FILE_UPLOAD) {
				this.uploadFile(btn);
			} else if (btn.ID_OPERATION === Config.ID_OPERATION_FILE_DELETE) {
				this.deleteFile(btn);
			} else {
				const onBtn = async () => {
					// передать в операцию параметры из выбранного элемента
					// в кнопке параметры представлены в виде объектов со свойствами FIELDPARAM и FIELDVALUE
					// FIELDPARAM это название параметра для новой выборки, а FIELDVALUE это название свойства в старой выборке,
					// откуда надо взять значение
					const btnParams = btn.params || [];
					let operationParams = [];
					btnParams.forEach(btnParam => {
						btnParam.FIELDPARAM = btnParam.FIELDPARAM?.trim();
						btnParam.FIELDVALUE = btnParam.FIELDVALUE?.trim();
						let param = {
							INPARAMNAME: btnParam.FIELDPARAM,
						};

						// формируем параметры для операции кнопки
						const p = this.localParams?.find(el => el.INPARAMNAME === btnParam.FIELDVALUE);
						if (typeof p?.VALUE !== "undefined") {
							// 1. значение есть в параметрах
							param.VALUE = p?.VALUE;
						} else if (this.currentItem) {
							// 2. значение ищем в выбранном элементе выборки
							param.VALUE = this.currentItem[btnParam.FIELDVALUE];
						}

						operationParams.push(param);
					});

					const needsConfirmation = () => {
						return new Promise(resolve => {
							if (Number(btn.IS_ASK_BEFORE_RUN)) {
								this.confirmDialog.callback = resolve;
								this.confirmDialog.title = !!btn.SQL_TEXT && this.multiselectedItems?.length > 1 ? "Выполнить операцию для всех выделенных строк?" : null;
								this.confirmDialog.isVisible = true;
							} else resolve();
						});
					};

					const openChildDialog = () => {
						return new Promise(resolve => {
							if (btn.WM_VALUE === Config.WM_VALUE_GALLERY) {
								// 1. open gallery
								this.showGallery(btn, operationParams, resolve);
							} else if (btn.ID_CALLED_SEL) {
								// 2. child proc / card
								//console.log("Opening child btn proc", operationParams);
								this.openBtnProc(btn, operationParams, resolve);
							} else {
								// no child proc card
								resolve();
							}
						});
					};

					const refreshProcs = () => {
						console.log("REFRESHING AFTER BTN", btn?.REFRESHTYPE, btn.refreshes);

						// 1й тип рефреша - локальная выборка/элемент
						// refresh item or whole proc
						if (btn?.REFRESHTYPE === Config.BTN_REFRESH_TYPE_PROC) {
							console.log("BTN refresh: ", btn.REFRESHTYPE);
							// refresh whole proc
							this.fetchProc();
						} else if (btn?.REFRESHTYPE === Config.BTN_REFRESH_TYPE_ITEM) {
							console.log("BTN refresh: ", btn.REFRESHTYPE);
							// refresh item
							this.refetchCurrentItem();
						}

						// 2й тип рефреша - перечитка внешних выборок
						//console.log("BTN refreshes: ", btn?.refreshes);
						btn?.refreshes?.forEach(refresh => {
							if (refresh?.EVENT_DET === Config.BTN_REFRESH_TYPE_ITEM) {
								console.log("BTN_REFRESH_TYPE_ITEM!", refresh.ID_PROCNAME_DET);
								//if (refresh.ID_PROCNAME_DET === this.procNameId && this.currentItem) withKeepDisabled = true;	// will refetch an item of the same proc so keep us disabled so far
								this.$store.state.procIdsWithRefreshItem.push(refresh.ID_PROCNAME_DET);
								//console.log("\tprocIdsWithRefreshItem", this.$store.state.procIdsWithRefreshItem);
							} else if (refresh?.EVENT_DET === Config.BTN_REFRESH_TYPE_PROC) {
								console.log("BTN_REFRESH_TYPE_PROC!", refresh.ID_PROCNAME_DET);
								this.$store.state.procIdsWithRefresh.push(refresh.ID_PROCNAME_DET);
								//console.log("\tprocIdsWithRefresh", this.$store.state.procIdsWithRefresh);
							}
						});
					};

					// 0. нужно подтверждение?
					await needsConfirmation();
					// 1. если нужно - выполнить SQL запрос
					await this.runBtnSQL(btn, operationParams);
					// 2. если нужно - открыть окно/карточку/галерею с procNameId из кнопки
					await openChildDialog();
					// 3. перечитать выборки
					refreshProcs();
					// 4. возможно требуется подтверждающее сообщение
					if (Number(btn?.IS_NOTIFY_AFTER_RUN)) {
						this.msgDialog.isVisible = true;
					}
					// 5. если нужно - скачиваем файл
					if (btn.IS_SAVEFILE) {
						console.log("Saving file from SQL");
						this.receiveFileForBtn(btn);
					}

				};
				if (btn.IS_LOADFILE) this.sendFileForBtn(btn).then(onBtn);
				else onBtn();
			}
		},
		/**
		 * Вызывается на клик по элементу в дочерней выборке.
		 */
		onChildClick(child) {
			// todo перенесли логику в даблклик
		},
		/**
		 * Вызывается на даблклик по элементу в дочерней выборке (для установки в селекторе в текущей выборке).
		 */
		onChildOpen(child) {
			// child dialog clicked an item
			this.childProcDialog.isVisible = false;
			const col = this.childProcDialog.col;
			const item = this.childProcDialog.item;
			//console.log("CHILD", child);
			//console.log("COL", col);
			//console.log("ITEM BEFORE", oldItem);
			item[col.KEYFIELD] = child[col.LOOKUPKEY];
			item[col.PROCPARAM] = child[col.LOOKUPRESULT];
			//console.log("ITEM AFTER", item);
			//this.update(item);
		},
		onChildProcClose() {
			//console.log("CLOSED", this.childProcDialog);
		},
		/**
		 * Вызывается на любое подтверждение из диалога сообщения, для конкретики используйте msgDialog.callback.
		 */
		onAfterMsg() {
			console.log("onAfterMsg()");
		},
		/**
		 * Вызывается на любое подтверждение, для конкретики используйте confirmDialog.callback.
		 */
		onConfirm() {
			//console.log("onConfirm()");
		},
		onUploadSelected() {
			const input = this.$refs['upload-input'];
			const file = input.files[0];
			if (this.btnUpload.onFileSelected) return this.btnUpload.onFileSelected(input, file);
			if (!file) return;

			Vue.set(this.btnUpload, "isLoading", true);

			// значения входящих параметров родительской выборки (на которой лежат кнопки по работе с файлами)
			const procParams = {};
			// todo нужжно ли добавлять параметры выбранного элемента?
			this.localParams.forEach(el => {
				procParams[el.INPARAMNAME] = el.VALUE;
			});

			const params = new FormData();
			params.append("file", file);
			Object.keys(procParams).forEach(key => {
				params.append(key, procParams[key]);
			});

			//console.log("UPLOAD PARAMS", procParams);

			return this.$store.dispatch('post', {
				action: 'FileUploadController',
				params,
				headers: {'Content-Type': 'multipart/form-data'}
			}).then((res) => {
				this.success = "Загрузка успешно завершена!";
				this.fetchProc();
			}).catch((err) => {
				console.error("ERROR", err);
				this.error = err.error;
			}).finally(() => {
				setTimeout(_ => {
					Vue.set(this.btnUpload, "isLoading", false);
				}, 300);
				input.value = "";
			});
		},
		onMultiselect(items) {
			//console.log("onMultiselect", items);
			//this.multiselectedItems = items;
		},
		/**
		 * Открывает дочерний попап с новой выборкой.
		 * См. также onChildProc().
		 */
		openBtnProc(btn, params, callback) {
			this.childProcDialog.item = this.currentItem;
			this.childProcDialog.btn = btn;
			this.childProcDialog.id = btn.ID_CALLED_SEL;
			this.childProcDialog.formId = btn.ID_FORMNAME;
			this.childProcDialog.isCard = btn.IS_CARD;
			this.childProcDialog.mainProcId = btn.ID_PROCNAME_MAIN_VIS;
			//this.childProcDialog.id = btn.ID_FORMNAME || btn.ID_CALLED_SEL;
			this.childProcDialog.parentId = this.procNameId;
			this.childProcDialog.params = params;
			this.childProcDialog.callback = callback;
			this.childProcDialog.hideButtons = false;
			this.childProcDialog.withHeader = false;
			this.childProcDialog.readonly = false;
			this.childProcDialog.withOpen = false;
			this.childProcDialog.isVisible = true;
		},
		runBtnSQL(btn, params) {
			return new Promise((resolve, reject) => {
				if (btn.SQL_TEXT) {
					//console.log("Running btn SQL", params);
					this.isDisabled = true;
					Vue.set(btn, "isLoading", true);

					// 1. вначале получить параметры операции
					this.$store.dispatch("post", {
						action: "BtnParamsController",
						params: {
							id: this.procNameId,
							btnId: btn.ID_OPERATION,
							params
						}
					})
						.then(res => {

							const action = (paramSet = null) => {
								/*console.log("btn.params", params);
							console.log("BtnParamsController.paramSet", paramSet);
							console.log("proc.params", this.localParams);
							console.log("currentItem", this.currentItem);*/

								// join user set params with current proc params + PFB params
								paramSet?.forEach(param => {
									const p = this.localParams?.find(el => el.INPARAMNAME === param.INPARAMNAME);
									if (typeof p?.VALUE !== "undefined") Vue.set(param, "VALUE", p.VALUE);
								});
								this.pfbParams?.forEach(el => paramSet.push(el));

								let items;
								if (this.multiselectedItems?.length) items = this.multiselectedItems;
								else if (this.currentItem) items = [this.currentItem];

								//this.isLoading = true;
								//console.log("Calling BTN operation with params", paramSet);

								btn.isLoading = true; // важно снова установить!
								this.isDisabled = true; // важно снова установить!
								let withKeepDisabled = false;
								this.paramsDialog.isLoading = true;
								return this.$store.dispatch("post", {
									action: "BtnQueryController",
									params: {
										formId: this.formId,	// can be null
										id: this.procNameId,
										btnId: btn.ID_OPERATION,
										params: paramSet,
										items,
									}
								})
									.then(res => {
										console.log("BTN RESULT", res, btn);

										if (res?.BtnQueryController?.notification) {
											const text = res?.BtnQueryController?.notification;
											//this.msgDialog.title = text;
											//this.msgDialog.isVisible = true;
											this.success = text;
											this.$store.state.snackbar.timeout = -1;
										}

										// если открывали диалог кнопкой - возможно требуется сообщение
										/*if (Number(btn?.IS_NOTIFY_AFTER_RUN)) {
										this.msgDialog.callback = null;
										this.msgDialog.isVisible = true;
									}// else action();*/

										// в кнопку вносим рефреши, если есть
										btn.refreshes = res.BtnQueryController?.refreshes;

										// finally!
										resolve(res);
									})
									.catch(err => {
										console.error("ERROR", err);
										this.error = err.error;
										reject(err.error);
									})
									.finally(() => {
										this.paramsDialog.isLoading = false;
										this.paramsDialog.isVisible = false;
										btn.isLoading = false;
										//console.log("withKeepDisabled "+withKeepDisabled);
										if (!withKeepDisabled) {
											//console.log("ENABLING in finally 1");
											this.isDisabled = false;
										}
									});
							};

							// запросить у пользователя установить параметры, если есть, или сразу вызвать операцию
							const visibleParams = res.BtnParamsController.paramSet.filter(el => !!el.PARAMCAPTION);
							if (visibleParams?.length) {
								//console.log("BTN", btn);
								//console.log("BTN has visible params", res.BtnParamsController.paramSet);
								// здесь параметр локальные, поэтому paramSet и params являются единым массивом
								this.paramsDialog.paramSet = res.BtnParamsController.paramSet;
								this.paramsDialog.params = res.BtnParamsController.paramSet;
								this.paramsDialog.dicts = res.BtnParamsController.dicts;
								this.paramsDialog.isVisible = true;
								this.paramsDialog.callback = action;
								this.isDisabled = false;
								btn.isLoading = false;
							} else {
								//console.log("BTN has no visible params");
								action(res.BtnParamsController.paramSet);
							}
						})
						.catch(err => {
							console.error("ERROR", err);
							this.error = err.error;
							reject(err.error);
						})
						.finally(() => {
							//this.isLoading = false;
							//btn.isLoading = false;
						});
				} else resolve();
			});
		},
		/**
		 * Операция загрузки файла на сервер перед выполнением операции на кнопке.
		 * @param btn
		 * @returns {Promise<unknown>}
		 */
		sendFileForBtn(btn) {
			return new Promise((resolve, reject) => {
				console.log("Selecting file", btn);
				this.btnUpload = btn;
				const input = this.$refs['upload-input'];
				input.click();
				this.btnUpload.onFileSelected = (input, file) => {
					console.log("File selected!", file);
					if (file) {
						const params = new FormData();
						params.append("file", file);
						Vue.set(btn, "isLoading", true);
						this.$store.dispatch('post', {
							action: 'BtnFileUploadController',
							params,
							headers: {'Content-Type': 'multipart/form-data'}
						}).then((res) => {
							if ( !btn.SQL_TEXT ) {
								// show confirmation only if there is no consequent sql operation
								this.success = "Загрузка успешно завершена!";
							}
							resolve();
						}).catch((err) => {
							console.error("ERROR", err);
							this.error = err.error;
							reject();
						}).finally(() => {
							setTimeout(_ => {
								Vue.set(btn, "isLoading", false);
							}, 300);
							input.value = "";
						});
					} else reject();
				}
			});
		},
		/**
		 * Операция скачивания файла с сервера после выполнения операции на кнопке.
		 * @param btn
		 * @returns {Promise<unknown>}
		 */
		receiveFileForBtn(btn) {
			Vue.set(btn, "isLoading", true);
			const url = "/api/BtnFileDownloadController";
			return crossDownload(url, "POST")
				.catch(err => {
					console.error("ERROR", err);
					this.error = err.error;
				})
				.finally(() => {
					btn.isLoading = false;
				});
		},
		/**
		 * Эта операция возможна только для определенных выборок.
		 * В них всегда у currentItem будет свойство FILENAME_FULL.
		 * @param btn
		 */
		uploadFile(btn) {
			this.btnUpload = btn;
			const input = this.$refs['upload-input'];
			input.click();
		},
		/**
		 * Эта операция возможна только для определенных выборок.
		 * В них всегда у currentItem будет свойство FILENAME_FULL.
		 * @param btn
		 */
		downloadFile(btn) {
			if (!this.currentItem) return;

			btn.isLoading = true;
			const url = "/api/FileDownloadController?filepath=" + encodeURIComponent(this.currentItem?.FILENAME_FULL);
			crossDownload(url)
				.then(res => {
				})
				.catch(err => {
					console.error("ERROR", err);
					this.error = err.error;
				})
				.finally(() => {
					btn.isLoading = false;
				});
		},
		/**
		 * Эта операция возможна только для определенных выборок.
		 * В них всегда у currentItem будет свойство FILENAME_FULL.
		 * @param btn
		 */
		openFile(btn) {
			if (!this.currentItem) return;
			const link = document.createElement('a');
			document.body.appendChild(link);
			link.href = "/api/FileDownloadController?inline=1&filepath=" + encodeURIComponent(this.currentItem?.FILENAME_FULL);
			link.target = "_blank";
			link.click();
			document.body.removeChild(link); //remove the link when done
		},
		/**
		 * Эта операция возможна только для определенных выборок.
		 * В них всегда у currentItem будут свойства FILENAME_FULL & ID_FILESLIST.
		 * @param btn
		 */
		deleteFile(btn) {
			if (!this.currentItem) return;

			btn.isLoading = true;
			this.$store.dispatch("post", {
				action: "FileDeleteController",
				params: {
					filesListId: this.currentItem['ID_FILESLIST'],
					filepath: this.currentItem['FILENAME_FULL'],
				},
			})
				.then(res => {
					this.$store.state.snackbar.info = "Файл успешно удален!";
					this.fetchProc();
				})
				.catch(err => {
					console.error("ERROR", err);
					this.error = err.error;
				})
				.finally(() => {
					btn.isLoading = false;
				});
		},
		showGallery(btn, params, resolve) {
			//console.log("showGallery", btn);
			Vue.set(btn, "isLoading", true);

			this.$store.dispatch("get", {
				action: "GalleryController",
				params: {
					id: btn.ID_CALLED_SEL,
					formId: this.formId,
					params,
				},
			}).then(res => {
				//console.log("RES", res);
				// small pause for beauty
				setTimeout(() => {
					Vue.set(btn, "isLoading", false);
				}, 300);

				const files = res?.page?.images;
				if (files?.length) {
					this.$store.state.filesDialog.item = files[0];
					this.$store.state.filesDialog.files = files;
					this.$store.state.filesDialog.currentIndex = 0;
					this.$store.state.filesDialog.isVisible = true;
				}
			}).finally(() => {
				resolve();
			});
		},
		applyButtons() {
			const buttons = this.proc?.buttons?.map(btn => ({
				...btn,
				src: this.iconUrlForBtn(btn),
			}));
			const localHash = md5(this.buttons?.map(el => el.ID_OPERATION));
			const newHash = md5(buttons?.map(el => el.ID_OPERATION));
			//console.log("BUTTONS", localHash, newHash);

			//this.btnsRefreshCounter++;

			// refresh only if buttons changed - do not refresh if buttons are same as it poorly reloads icons
			if (localHash === newHash) return;
			this.buttons = buttons;
		},
		/**
		 * Инициализирует массив установленных PFB-параметров в текущей форме, от которых зависит ДАННАЯ выборка.
		 * Важно! Установит только те PFB-параметры, которые доступны сейчас!
		 * Таким образом это может быть не полный список PFB-параметров.
		 * То есть МОЖЕТ НЕ совпадать с parentKeys данной выборки!
		 * См. также areAllPFBParamsSet и areSomePFBParamsSet.
		 */
		initPFBParams() {

			// todo review code - this could be simplier

			const availablePFBCols = this.formProcs    // берем все текущие выборки
				.filter(el => el.formId === this.formId)    // все выборки в нашей форме
				.filter(el => el.procNameId !== this.procNameId)    // все выборки, кроме текущей
				?.reduce((all, el) => {	// собираем воедино все колонки из всех выборок
					//console.log(el.procNameId + " PFB COLS", el.cols.filter(el => el.match(/^PFB_.+/)));
					return all.concat(el.cols);
				}, [])
				.filter(el => !!el)    // не пустые колонки (могут быть для data sources)
				.filter(el => el.match(/^PFB_.+/));	// отбираем только PFB_ колонки
			//console.log("PROC" + this.procNameId+" AVAILABLE PFB COLS", availablePFBCols);

			// теперь для всех параметров, на которые мы подписаны - собираем массив параметров из элементов родительских выборок
			const pfbParams = this.parentKeys?.filter(key => !!availablePFBCols?.includes(key)).map(key => {
				//console.log("parent key "+key);
				//console.log("global proc items", this.formProcs);
				const el = this.formProcs
					.filter(el => el.formId === this.formId)    // все выборки в нашей форме
					.find(el => el.item && typeof el.item[key] !== "undefined");
				return {
					procNameId: el?.procNameId,
					formId: el?.formId,
					INPARAMNAME: key,
					VALUE: el?.item[key]
				}
			});

			if (md5(this.pfbParams) !== md5(pfbParams)) {
				this.pfbParams = pfbParams;
				//console.log("[" + this.formId + "." + this.procNameId + "] PFB params updated: ", this.pfbParams);
			}
		},
		applyHeaders() {
			this.headers = this.proc?.headers;
			this.headers?.forEach(el => {
				el.IS_READONLY = Number(el.IS_READONLY);
				el.CHECKBOX = Number(el.CHECKBOX);
				el.text = el.CAPTION || el.PROCPARAM;
				el.value = el.PROCPARAM;
				Vue.set(el, "search", null);
				Vue.set(el, "_sort", null);
			});
		},
		iconUrlForBtn(btn) {
			if (!btn['ICON']) return null;
			const auth = localStorage.getItem(Config.STORAGE_AUTH_TOKEN);
			return "/api/IconController?operationId=" + btn['ID_OPERATION']
				+ "&procNameId=" + this.procNameId
				+ "&auth=" + auth;
		},
		refetchCurrentItem() {
			//console.log("refetchCurrentItem", this.currentItem);
			//console.log("this.multiselectedItems", this.multiselectedItems);

			let items;
			if (this.multiselectedItems?.length) items = this.multiselectedItems;
			else if (this.currentItem) items = [this.currentItem];

			if (items) {
				items.forEach(el => Vue.set(el, "_isLoading", true));
			} else {
				this.isDisabled = false;
				return Promise.resolve();
			}

			this.isRefetchingCurrentItem = true;
			this.isDisabled = true;
			//console.log("DISABLING in refetchCurrentItem");
			return this.$store.dispatch("post", {
				action: "RefetchItemController",
				params: {
					procNameId: this.procNameId,
					items,
					params: this.localParams
				},
			})
				.then(res => {
					items.forEach((item, index) => {
						const newItem = res.page.items[index];
						this.replaceItem(item, newItem, true);
						//this.replaceCurrentItem(res.page.item);
						Vue.set(item, "_isLoading", false);
					});

					this.registerProc();
				})
				.catch(err => {
					console.error("ERROR", err);
					this.error = err.error;
				})
				.finally(() => {
					this.isRefetchingCurrentItem = false;
					this.isDisabled = false;
					//console.log("ENABLING in finally 2");
				});
		},
		replaceCurrentItem(item) {
			// the prev item is now the old item
			if (this.currentItem) this.oldItem = {...this.currentItem};

			// we should skip validation when item is replaced
			this.isValidatonDisabled = true;
			this.replaceItem(this.currentItem, item);
			setTimeout(() => {
				this.isValidatonDisabled = false;
			}, 150);
		},
		replaceItem(currentItem, item) {
			if (!currentItem || !item) return;

			//console.log("REPLACING", currentItem, item);

			// refresh current item
			Object.keys(item).forEach(key => {
				currentItem[key] = item[key];
			});

			if (this.currentItem === currentItem) this.oldItem = {...currentItem};

			// force btns to update visibility
			this.btnsRefreshCounter++;

			// force table to recalc footer totals
			this.tableFooterRefreshCounter++;
		},
		fetchProc() {
			// todo это решено пока не использовать - иногда требуется загрузить выборку без установки всех PFB-параметров
			//if (!this.areAllPFBParamsSet) return;
			//console.log(new Date().valueOf() + " Proc ["+this.procNameId + "] is debounce loading!");
			if (!this.fetchProcDebounced) {
				this.fetchProcDebounced = debounce(function () {
					this.fetchProcReal();
				}, 150);
				//console.log(new Date().valueOf() + " Proc ["+this.procNameId + "] debounce loading inited!");
			}
			this.fetchProcDebounced();
			//else this.fetchProcReal();
		},
		/**
		 * Устанавливает текущие данные выборки в глобальном (state) массиве всех выборок.
		 * Так соседние выборки могут видеть, что у нас происходит.
		 */
		registerProc() {
			/*console.group("Register proc " + this.procNameId);
			console.log("meta: ", this.meta);
			console.log("item: ", this.currentItem);
			console.groupEnd();*/

			//if ( this.procNameId === -22588 ) return;

			const procIndex = this.formProcs.findIndex(el => el.formId === this.formId && el.procNameId === this.procNameId);
			const el = {
				procNameId: this.procNameId,
				formId: this.formId,
				ui: this.ui,
				cols: this.cols,
				headers: this.headers,
				meta: this.meta,
				buttons: this.buttons,
				blobs: this.proc?.blobs,
				item: this.currentItem,
				//items: this.items,
				params: this.localParams,
				pfbParams: this.pfbParams,
				isReadonly: this.isReadonly,
				shouldEnterEditMode: false,
				isLoading: this.isLoading,
			};
			if (procIndex >= 0) this.formProcs.splice(procIndex, 1, el);
			else this.formProcs.push(el);
		},
		unregisterProc() {
			/*console.group("Unregister proc "+this.procNameId);
			console.log("meta: ", this.meta);
			console.log("item: ", this.currentItem);
			console.groupEnd();*/
			const procIndex = this.formProcs.findIndex(el => el.formId === this.formId && el.procNameId === this.procNameId);
			if (procIndex >= 0) this.formProcs.splice(procIndex, 1);
		},
		clearData() {
			this.currentItem = null;
			this.currentItemIndex = null;
			this.multiselectedItems = [];
			this.isPageRendered = false;
			this.items = [];
			this.buttons = null;
		}
	},
	destroyed() {
		this.unregisterProc();
	}
}
</script>
