import axios from "axios";
import Config from '@/config';
import {mergeObjects} from '@/utils/utils';
import 'axios-progress-bar/dist/nprogress.css';
import { loadProgressBar } from 'axios-progress-bar';
import {isMobile} from '@/utils/utils';

const axiosWithProgress = axios.create();
const axiosWithoutProgress = axios.create();
loadProgressBar({ showSpinner: false },axiosWithProgress);

export function api(cmd, options) {
	// по умолчанию - GET-запрос
	options.method = options.method || 'GET';

	//console.log('API '+options.method+' request \"' + cmd + '\": ', options);

	// с прогрессбаром или нет?
	let axios = null;
	if ( options.hidden ) axios = axiosWithoutProgress;
	else axios = axiosWithProgress;

	// добавляем токен авторизации
	let headers = {};
	//let authToken = Cookie.get(Config.STORAGE_AUTH_TOKEN);
	//if ( !authToken )
	let authToken = localStorage.getItem(Config.STORAGE_AUTH_TOKEN);
	//console.log("AUTH taken from cookie/storage: ",authToken);
	if ( authToken ) headers['Authorization'] = authToken;
	// else axios.defaults.headers.common['Authorization'] = '';
	let authDemoToken = localStorage.getItem(Config.STORAGE_AUTH_DEMO_TOKEN);
	if (authDemoToken) headers['AuthorizationDemo'] = authDemoToken;

	// добавляем isMobile (данные о разрешении экрана)
	const isMobileView = isMobile() || window.innerWidth < Config.WIDTH_MD;
	axios.defaults.headers.common['Is-Mobile'] = isMobileView;
	//console.log("Is-Mobile: " + isMobileView);

	let params = null, data = null;
	if ( options.method === "GET" ) {
		// конкатинируем GET params & query:
		params = options.params || {};
		let query = options.query || {};
		params = mergeObjects(params, query);
	}
	else data = options.params;

	// определяем URL:
	let url = '';
	if (cmd.match(/^\/.+/)||cmd.match(/^http:.+/) ) {
		// кастомный URL
		url = cmd;
		//console.log("Requesting URL: "+url);
	}
	else {
		// По умолчанию обращаемся к API
		url = (Config.API_SERVER_URI?Config.API_SERVER_URI:'') + '/api/' + cmd + '/';
	}

	// установка Accept
	let accepts = ["application/json"];
	if ( Config.WITH_WEBP ) accepts.push("image/webp");
	axios.defaults.headers.common['Accept'] = accepts.join(", ");

	// TODO: кастомные заголовки:
	//let headers = {'Cache-Control': 'max-age=300'};
	/*if (typeof options.headers !== 'undefined' && Object.keys(options.headers).length > 0) {
        _.forEach(options.headers, (val, key) => headers[key] = val);
    }*/

	// погнали
	return axios({
		method: options.method,
		url,
		params,
		headers,
		data
	}).catch((error) => {
		//console.warn("ERROR received:", error);

		if ( error?.response?.data?.error ) {
			console.warn('API exception: ', error.response.data.error || error.response.data.code);
			throw {error: error.response.data.error, code: error.response.data.code};
		}
		else if ( error ) {
			console.error('API error: ', {error: error.error, code: error.code});
			throw {error: error.error, code: error.code};
		}

		console.error('API fatal error');
		throw {error: 'API fatal error', code: Config.ERROR_FATAL};
	}).then((response) => {
		//console.log('API Response: ', response);

		if (!response.data && options.method === 'POST' ) return;
		else if (!response.data) throw 'No data in response object';

		if (response?.data?.error) {
			// API standard error
			console.warn('API error: ', response.data.error);
			if ( typeof response.data.code !== "undefined" ) throw {error: response.data.error, code: response.data.code};
			else throw response.data.error;
		}
		if (!response.data?.result) {
			console.warn('No result in response data object');
			throw 'No result in response data object';
		}

		return response?.data?.result;
	});
}

export default api;
