import Config from '@/config';
import {api} from './api';

const actions = {
    /**
     * Базовый метод, по идее, не должен вызываться вручную.
     * Используйте post() для отправки форм или кастомных некэшируемых запросов.
     * Все остальные данные должны грузиться через fetchPage() в цикле загрузки страницы.
     * @param store
     * @param params
     * @returns {Q.Promise<any> | * | Q.Promise<T | never> | PromiseLike<T | never> | Promise<T | never>}
     */
    request({commit, state}, params) {
        return api(params.action, params)
            .then(response => {
                commit('setResponse', response);
                return response;
            });
    },

    /**
     * Для свободного обращения к API методом POST.
     * @param store
     * @param params
     * @returns {Q.Promise<any> | * | Q.Promise<T | never> | PromiseLike<T | never> | Promise<T | never>}
     */
    post(store, params) {
        params = params || {};
        params.method = "POST";
        return actions.request(store, params);
    },

    /**
     * Для свободного обращения к API методом PUT.
     * @param store
     * @param params
     * @returns {Q.Promise<any> | * | Q.Promise<T | never> | PromiseLike<T | never> | Promise<T | never>}
     */
    put(store, params) {
        params = params || {};
        params.method = "PUT";
        return actions.request(store, params);
    },

    /**
     * Для удаления записей методом DELETE.
     * @param store
     * @param params
     * @returns {Q.Promise<any> | * | Q.Promise<T | never> | PromiseLike<T | never> | Promise<T | never>}
     */
    del(store, params) {
        params = params || {};
        params.method = "DELETE";
        return actions.request(store, params);
    },

    /**
     * Для свободного обращения к API методом GET.
     * @param store
     * @param params
     * @returns {Q.Promise<any> | * | Q.Promise<T | never> | PromiseLike<T | never> | Promise<T | never>}
     */
    get(store, params) {
        params = params || {};
        params.method = "GET";
        return actions.request(store, params);
    },

    /**
     * Загружает страницу в роутинге.
     * @param store
     * @param params
     * @returns {Q.Promise<any> | * | Q.Promise<T | never> | PromiseLike<T | never> | Promise<T | never>}
     */
    fetchPage(store, params) {
        params = params || {};
        params.method = params.method ? params.method : "GET";
        return actions.request(store, params).then((result) => {
            store.commit('setPage', result);
        }).catch(error => {
            store.commit('setError', error);
            throw error;
        });
    },

    /**
     * Отправляем лог-запись на бек.
     * @param store
     * @param params
     * @returns {Q.Promise<any> | * | Q.Promise<T | never> | PromiseLike<T | never> | Promise<T | never>}
     */
    log(store, params) {
        return actions.request(store, {
            method: "POST",
            action: "UserLogController",
            params
        });
    },

    /**
     * Выход из сессии - сообщаем это беку.
     * @param store
     * @param params
     * @returns {Q.Promise<any> | * | Q.Promise<T | never> | PromiseLike<T | never> | Promise<T | never>}
     */
    logout(store, params) {
        return actions.request(store, {
            method: "POST",
            action: "LogoutController",
            params
        }).then((response) => {
            store.commit('logout');
            console.log("CLEARED!");
            return response;
        });
    },
};

export default actions;
