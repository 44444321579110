export default [
    {
        name: 'entry-auth',
        path: '/entry-auth/:entry',
        props: true,
        component: () => import('../components/entry-auth'),
    },
    {
        name: 'auth',
        path: '/auth',
        component: () => import('../components/auth'),
        meta: {api: "AuthController"}
    },
    {
        path: '/logout',
        name: 'logout',
        component: () => import('../components/logout'),
        meta: {api: "LogoutController", method: "POST"}
    },
    {
        name: 'session-expired',
        path: '/session-expired',
        props: true,
        component: () => import('../components/session-expired'),
        meta: {api: "LogoutController", method: "POST"}
    },
    {
        path: '/error/:code',
        name: 'error',
        component: () => import('../components/error'),
        props: true,
    },
    {
        path: '/error/:code/:type',
        name: 'error-type',
        component: () => import('../components/error'),
        props: true,
    },
    {
        path: '/', component: () => import('../components/layouts/layout-main'), meta: {},
        children: [
            {
                path: '/',
                name: 'home',
                component: () => import('../components/home'),
                meta: {api: "HomeController"}
            },
            {
                path: '/page/:pageId',
                name: 'page',
                component: () => import('../components/page'),
                props: true,
                meta: {api: "PageController"}
            },
            {
                path: '/page/:pageId/:id',
                name: 'page-proc',
                component: () => import('../components/page'),
                props: true,
                meta: {api: "PageController"}
            },
            {
                path: '/form/:pageId',
                name: 'form',
                component: () => import('../components/page'),
                props: true,
                meta: {api: "FormController"}
            },
            {
                path: '/card/:pageId',
                name: 'card',
                component: () => import('../components/page'),
                props: true,
                meta: {api: "CommonController"}
            },
            {
                name: 'auth-inner',
                path: '/auth-inner',
                component: () => import('../components/auth-inner'),
                meta: {api: "CommonController"}
            },
            {
                path: '/profile',
                name: 'profile',
                component: () => import('../components/profile'),
                meta: {api: "DashboardController"}
            },
        ]
    },
    {
        path: '/under-development',
        name: 'under',
        component: () => import('../components/under'),
    },
];
