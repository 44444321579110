<template>
	<v-dialog v-model="params.isVisible"
			  content-class="confirm-dialog"
			  width="500">
		<v-card>
			<v-card-title>
				{{params.title || 'Выполнить операцию?'}}
			</v-card-title>

			<v-divider/>

			<v-card-actions>
				<v-spacer></v-spacer>
				<v-btn
						color="primary"
						text
						@click="params.isVisible=false"
				>
					Отмена
				</v-btn>
				<v-btn
						color="primary"
						:loading="params.isLoading"
						@click="onYes">
					Да
				</v-btn>
			</v-card-actions>
		</v-card>
	</v-dialog>

</template>

<script>
	import Vue from "vue";
	import {nf} from "@/utils/string";
	import {debounce} from "lodash";
	import Config from "@/config";

	export default {
		name: "confirm-dialog",
		props: ["params"],
		data: () => ({
			//isLoading: false
		}),
		computed: {},
		methods: {
			onYes() {
				this.$emit("yes");
				this.params.isVisible = false;
				if ( this.params.callback ) this.params.callback();
			},
		}
	}
</script>

<style lang="scss">
	.confirm-dialog {

		.v-card {
			&__title {
				padding: 2px 12px !important;
			}
			&__text {
				padding: 12px !important;
			}
			&__actions {
				padding: 12px !important;
			}
		}
	}
</style>