<template>
	<div v-if="items" class="proc-table"
		 :class="{readonly:readonly, filtering: isFiltering, resizing: !!activeResizer, 'th-dragging': !!draggedTH.dummy}"
		 :id="componentId">

		<v-data-table v-if="isLG||!$store.state.withAdaptiveTables" class="--desktop" :headers="headers"
					  :items="filteredItems"
					  :items-per-page="itemsPerPage"
					  :page.sync="pageNumber"
					  :height="tableHeight"
					  hide-default-header
					  hide-default-footer
					  fixed-header
					  dense
					  @page-count="pageCount = $event">
			<template v-slot:header>
				<thead>
				<tr v-if="thsRow1 && thsRow1.length" class="row1">
					<template v-for="(th, index) of thsRow1">
						<th v-if="th.colspan" :colspan="th.colspan"
							@mousedown="e=>onTHMouseDown(e, th, index)"
							@mousemove="onMouseMove"
							@mouseup="e=>onTHMouseUp(e, th, index)">
							<v-img v-if="isIcon(th)"
								   :src="iconUrlForCol(th)" :title="th.textRow1"/>
							<span v-else>{{ th.textRow1 }}</span>
						</th>
						<th v-else
							:rowspan="thsRow2 && thsRow2.length ? 2 : 1"
							@click="onSort(th)"
							@mousedown="e=>onTHMouseDown(e, th, index)"
							@mousemove="onMouseMove"
							@mouseup="e=>onTHMouseUp(e, th, index)"
							:class="thRow1Class(th, index)">
							<v-img v-if="isIcon(th)"
								   :src="iconUrlForCol(th)" :title="th.textRow2"/>
							<span v-else>{{ th.textRow1 }}</span>
							<transition name="route">
								<v-icon v-if="sort.by === th && !sort.desc" small key="sort1">mdi-chevron-up</v-icon>
								<v-icon v-else-if="sort.by === th && sort.desc"
										small
										key="sort2">mdi-chevron-down
								</v-icon>
							</transition>
						</th>
					</template>
				</tr>
				<tr v-if="thsRow2 && thsRow2.length" class="row2">
					<th v-for="(th, index) of thsRow2" :key="'th2'+index"
						@click="onSort(th)"
						@mousedown="e=>onTHMouseDown(e, th, index)"
						@mousemove="onMouseMove"
						@mouseup="e=>onTHMouseUp(e, th, index)"
						:class="thRow2Class(th, index)">
						<v-img v-if="isIcon(th)"
							   :src="iconUrlForCol(th)" :title="th.textRow2"/>
						<span v-else>{{ th.textRow2 }}</span>
						<transition name="route">
							<v-icon v-if="sort.by === th && !sort.desc" small key="sort1">mdi-chevron-up</v-icon>
							<v-icon v-else-if="sort.by === th && sort.desc" small key="sort2">mdi-chevron-down</v-icon>
						</transition>
					</th>
				</tr>
				<tr class="filters" :class="{'two-rows':thsRow2&&thsRow2.length}">
					<th v-for="(th, index) of headers" :key="'filter'+index" :class="thFilterClass(th, index)">
						<input v-model="th.search"
							   :class="{'active':!!th.search}"
							   maxlength="64"
							   @input="(e)=>onFilterInput(th,e)"
							   @focus="(e)=>onFilterFocus(th,e)"
							   @blur="(e)=>onFilterBlur(th,e)"
							   @keyup="(e)=>onFilter(th, e)"/>
						<v-icon v-if="!!th.search" x-small @click="(e)=>onFilterClear(th, e)">mdi-close</v-icon>
					</th>
				</tr>
				</thead>
			</template>
			<template v-slot:item="{ item, index }">
				<tr :style="itemStyle(item, index)" :class="{'--edited':item === editedItem}"
					@dblclick="onItemDblClick(item, index)">

					<template v-if="item === editedItem">
						<td v-for="(col, colIndex) of headers"
							:key="'row'+index+'col'+colIndex"
							:class="tdClass(item, col, colIndex)">
							<v-icon v-if="isCheckbox(item,col)"
									class="checkbox"
									:class="{active:item[col.value] && Number(item[col.value])}"
									@click="onCheckbox(item, index, col, colIndex)">
								{{
									item[col.value] && Number(item[col.value]) ? 'mdi-checkbox-intermediate' : 'mdi-checkbox-blank-outline'
								}}
							</v-icon>
							<span v-else-if="isSelector(item, col)" class="selector__body"
								  @click="onSelectorClick(item, index, col, colIndex)">
								<span>{{ item[col.value] }}</span>
								<span class="selector__icons">
									<v-icon v-if="item[col.value]!==null" class="dots"
											@click.stop="onSelectorClear(item, index, col, colIndex)">mdi-close</v-icon>
									<v-icon class="dots">mdi-dots-horizontal</v-icon>
								</span>
							</span>
							<v-img v-else-if="isIcon(col)&&iconUrlForItem(item,col)"
								   :src="iconUrlForItem(item,col)"/>
							<span v-else-if="isDateTime(item, col)"
								  @click="onDateClick(item, index, col, colIndex)">
								{{ item[col.value] | datetime }}
								<v-icon>mdi-calendar</v-icon>
							</span>
							<span v-else-if="isReadonly(item, col)">
								{{ item[col.value] }}
							</span>

							<!--<v-checkbox v-else-if="isCheckbox(item,col)"
										v-model="item[col.value]" hide-details/>-->
							<input v-else v-model="item[col.value]"
								   :class="inputClass(item, index, col, colIndex)"
								   :type="isInteger(item, col)?'number':'text'"
								   @keypress="event=>onInputKeyPress(item, index, col, colIndex, event)"
								   @change="onEditedItemChange(item, index, col, colIndex)"
								   @focus="onInputFocus" :title="col.value"/>
						</td>
					</template>
					<template v-else>
						<td v-for="(col, colIndex) of headers" :key="'row'+index+'col'+colIndex"
							:class="tdClass(item, col, colIndex)"
							@click="(event)=>onItemClick(event, item, index, col, colIndex)">
							<template v-if="isDateTime(item, col)">
								{{ item[col.value] | datetime }}
							</template>
							<v-icon v-else-if="isCheckbox(item,col)"
									class="checkbox" :class="{active:item[col.value] && Number(item[col.value])}">
								{{
									Number(item[col.value]) === 1 ? 'mdi-checkbox-intermediate' : 'mdi-checkbox-blank-outline'
								}}
							</v-icon>
							<v-img v-else-if="isIcon(col)&&iconUrlForItem(item,col)" :src="iconUrlForItem(item,col)"/>
							<a v-else-if="isURL(col)" :href="item[col.value]" target="_blank">{{ item[col.value] }}</a>
							<template v-else><span v-html="preserveSpaces(item[col.value])"></span></template>
						</td>
					</template>
				</tr>
			</template>
			<template v-slot:footer>
				<div v-if="hasFooter" class="proc-table__footer" :class="{'--visible':isFooterVisible}">
					<div v-for="(col, colIndex) of headers" :key="'footer-col'+colIndex" class="proc-table__footer-col">
						<span v-if="col.autosumResult" :title="col.autosumTitle">{{ col.autosumResult }}</span>
					</div>
				</div>
			</template>
		</v-data-table>
		<v-data-table v-else :headers="headers" class="--mobile"
					  :loading="!isInited"
					  loading-text="Загружаем данные..."
					  :items="filteredItems"
					  :items-per-page="itemsPerPage"
					  :page.sync="pageNumber"
					  :height="tableHeight"
					  :mobile-breakpoint="mobileBreakpoint"
					  disable-sort
					  hide-default-footer
					  fixed-header
					  dense
					  @page-count="pageCount = $event">
			<template v-slot:item="{ item, index }">
				<tr :style="itemStyle(item, index)"
					@click="(event)=>onItemClick(event, item, index)"
					@dblclick="onItemDblClick(item, index)" class="proc-table__mobile-tr"
					:class="{'--edited':item === editedItem}" :data-index="index">
					<td class="proc-table__mobile-td-number">{{ (pageNumber - 1) * itemsPerPage + index + 1 }}</td>
					<td :data-number="(pageNumber-1)*itemsPerPage + index+1" class="proc-table__mobile-td">
						<!--					<div class="proc-table__item-index">{{index}}</div>-->
						<template v-if="item === editedItem">
							<div v-for="(col, colIndex) of headers" :key="'row'+index+'col'+colIndex"
								 v-if="!(isReadonly(item, col) && !item[col.value])"
								 :class="tdClass(item, col, colIndex)" class="proc-table__mobile-col">
								<div class="proc-table__mobile-col-value">
									<v-checkbox v-if="isCheckbox(item,col)" v-model="item[col.value]" dense hide-details
												:label="col.CAPTION" :readonly="isReadonly(item, col)"/>
									<!--									<v-icon v-if="isCheckbox(item,col)"
																				class="checkbox"
																				:class="{active:item[col.value] && Number(item[col.value])}"
																				@click="onCheckbox(item, index, col, colIndex)">
																			{{
																				item[col.value] && Number(item[col.value]) ? 'mdi-checkbox-intermediate' : 'mdi-checkbox-blank-outline'
																			}}
																		</v-icon>-->
									<div v-else-if="isSelector(item, col)" class="selector__body"
										 @click="onSelectorClick(item, index, col, colIndex)">
										<div class="selector__body-value">
											<span v-if="item[col.value]" class="mr-1">{{
													item[col.value]
												}}</span>
											<v-icon class="dots">mdi-dots-horizontal</v-icon>
										</div>
										<v-icon v-if="item[col.value]!==null" class="dots"
												@click.stop="onSelectorClear(item, index, col, colIndex)">mdi-close
										</v-icon>
									</div>
									<v-img v-else-if="isIcon(col)&&iconUrlForItem(item,col)"
										   :src="iconUrlForItem(item,col)"/>
									<span v-else-if="isDateTime(item, col)"
										  @click="onDateClick(item, index, col, colIndex)">
										{{item[col.value] | datetime}}
										<v-icon>mdi-calendar</v-icon>
									</span>
									<template v-else-if="isReadonly(item, col)">
										<span v-if="item[col.value]">{{ item[col.value] }}</span>
									</template>

									<!--<v-checkbox v-else-if="isCheckbox(item,col)"
												v-model="item[col.value]" hide-details/>-->
									<input v-else v-model="item[col.value]"
										   :class="inputClass(item, index, col, colIndex)"
										   :type="isInteger(item, col)?'number':'text'"
										   @keypress="event=>onInputKeyPress(item, index, col, colIndex, event)"
										   @change="onEditedItemChange(item, index, col, colIndex)"
										   @focus="onInputFocus" :title="col.value"/>
								</div>
								<div class="proc-table__mobile-col-key">
									<template>
										{{ col.CAPTION }}
									</template>
								</div>
							</div>
						</template>
						<template v-else>
							<v-btn v-if="!isEditing&&!hideButtons" :loading="item._isLoading" icon
								   class="proc-table__mobile-menu-btn"
								   @click.stop.prevent="$event => onItemMenuClick($event, item, index)">
								<v-icon>mdi-dots-vertical</v-icon>
							</v-btn>
							<div v-for="(col, colIndex) of headers" :key="'row'+index+'col'+colIndex"
								 v-if="item[col.value]"
								 :class="tdClass(item, col, colIndex)" class="proc-table__mobile-col">
								<div class="proc-table__mobile-col-value">
									<template v-if="isDateTime(item, col)">
										{{ item[col.value] | datetime }}
									</template>
									<!--									<v-checkbox v-else-if="isCheckbox(item,col)" :value="!!item[col.value]" disabled
																					readonly dense hide-details
																					:label="col.CAPTION"/>-->
									<div v-else-if="isCheckbox(item,col)" class="d-flex align-center">
										<v-icon class="mr-1">
																			{{
												Number(item[col.value]) === 1 ? 'mdi-checkbox-marked-outline' : 'mdi-checkbox-blank-outline'
											}}
										</v-icon> {{ col.CAPTION }}
									</div>
									<v-img v-else-if="isIcon(col)&&iconUrlForItem(item,col)" :src="iconUrlForItem(item,col)"/>
									<a v-else-if="isURL(col)" :href="item[col.value]" target="_blank">{{
											item[col.value]
										}}</a>
									<span v-else>{{ item[col.value] }}</span>
								</div>
								<div class="proc-table__mobile-col-key">
									{{ col.CAPTION }}
								</div>
							</div>
						</template>
					</td>
				</tr>
			</template>
			<!--			<template v-slot:footer>
							<div v-if="hasFooter && isFooterVisible" class="proc-table__mobile-footer"
								 :class="{'&#45;&#45;visible':isFooterVisible}">
								<div v-for="(col, colIndex) of headers" :key="'footer-col'+colIndex"
									 v-if="col.autosumResult"
									 class="proc-table__mobile-footer-col d-flex justify-space-between">
									<span>{{ col.autosumTitle }}:</span>
									<span>{{ col.autosumResult }}</span>
								</div>
							</div>
						</template>-->
		</v-data-table>

		<template v-if="isLG">
			<div v-if="withPaging" class="text-center proc-table__paging" ref="proc-table__paging">
				<v-pagination v-if="withPaging"
							  v-model="pageNumber"
							  :length="pageCount"/>
			</div>
		</template>
		<template v-else>
			<v-bottom-sheet v-if="hasFooter" v-model="isFooterVisible">
				<v-sheet class="proc-table__mobile-footer">
					<v-btn class="mb-4"
						   color="primary"
						   text
						   @click="isFooterVisible = !isFooterVisible">
						закрыть
					</v-btn>
					<div v-for="(col, colIndex) of headers" :key="'footer-col'+colIndex"
						 v-if="col.autosumResult"
						 class="proc-table__mobile-footer-col d-flex justify-space-between">
						<span>{{ col.autosumTitle }}:</span>
						<span>{{ col.autosumResult }}</span>
					</div>
				</v-sheet>
			</v-bottom-sheet>
			<div v-if="withPaging || hasFooter" class="text-center proc-table__paging" ref="proc-table__paging">
				<v-row class="ma-0 align-center">
					<v-col cols="2" class="pa-0">
						<v-btn v-if="hasFooter" icon @click="onClickFooterBtn">
							<v-icon>mdi-counter</v-icon>
						</v-btn>
					</v-col>
					<v-col cols="8" class="pa-0">
						<v-pagination v-if="withPaging"
									  v-model="pageNumber"
									  :length="pageCount"/>
					</v-col>
					<v-col cols="2" class="pa-0"></v-col>
				</v-row>
			</div>
		</template>
	</div>
</template>

<script>
import Vue from "vue";
import {nf, preserveSpaces} from "@/utils/string";
import {iso2DDMMYYYY} from "@/utils/date";
import {debounce} from "lodash";
import {md5} from "@/utils/string";
import vp from "@/mixins/vp";
import Config from "@/config";

const MIN_TH_SIZE = 40;
const MAX_TH_SIZE = 1200;
const STORAGE_WIDTHS_PREFIX = "td-widths-";
let id = 1;

export default {
	name: "proc-table",
	mixins: [vp],
	props: {
		procId: {},
		formId: {},
		allHeaders: {},
		items: {},
		height: {},
		value: {},
		multiselectedItems: {},
		page: {},
		search: {},
		readonly: {
			type: Boolean,
			default: false
		},
		isEditing: {
			type: Boolean,
			default: false
		},
		footerRefresh: {},
		filterCounter: {},
		hideButtons: {
			type: Boolean,
			default: false
		},
	},
	data: () => ({
		id: null,
		pageNumber: 1,
		pageCount: 0,
		itemsPerPage: 100,
		selectedItem: null,
		selectedItemIndex: null,
		previousItem: null,
		editedItem: null,
		editedItemIndex: null,
		editedItemLast: null,	// prev copy of edited item, updated AFTER watched deep changes
		headers: null,
		thsRow1: null,
		thsRow2: null,
		filteredItems: [],	// [] is important for v-data-table
		isInited: false,
		isFiltering: false,
		sort: {
			by: null,
			desc: false
		},
		el: null,
		resizers: null,
		activeResizer: null,
		draggedTH: {
			el: null,
			col: null,
			index: null,
			startX: null,
			startY: null,
			dummy: null,
		},
		tableScrollLeft: null,
		tableScrollTop: null,
		style: null,
		lastContentViewScrollLeft: null,
		isFooterVisible: false,
	}),
	computed: {
		/*thsRow1() {
			return this.headers.filter(el => el.textRow1);
		},
		thsRow2() {
			return this.headers.filter(el => el.textRow2);
		},*/
		componentId() {
			return "proc-table-" + this.id;
		},
		tableHeight() {
			if (this.isLG) return this.height
				- (this.withPaging ? 24 : 0)	// with pagination
				- (this.hasFooter ? 24 : 0)	// with footer
				- 2;
			else return this.height
				- (this.withPaging || this.hasFooter ? 40 : 0)	// with pagination or with footer
				- 2;
		},
		withPaging() {
			return this.pageCount > 1;
		},
		hasGlobalEditedItemOfSameForm() {
			return false;/*!!this.$store.state.editedItem.item
				&& this.$store.state.editedItem.formId !== this.formId;*/
		},
		headersWithAutosum() {
			return this.headers?.filter(el => !!el.AUTOSUM);
		},
		hasFooter() {
			return !!this.headersWithAutosum?.length;
		},
	},
	watch: {
		value: {
			immediate: true,
			handler(val) {
				//console.log("proc-table value()");

				this.selectedItem = val;
				if (this.isEditing) this.editedItem = this.selectedItem;

				// обновляем суммы в футере, если обновился текущий элемент (может быть рефреш снаружи, из proc)
				//this.countAutosums();
			}
		},
		editedItem: {
			deep: true,
			immediate: true,
			handler(val, old) {
				if (val) {
					//console.log("onEditedItemValuesChanges", val, old);
					if (old) this.onEditedItemValuesChanges();
					this.setItemsStyle();
				}
				this.editedItemLast = this.editedItem ? {...this.editedItem} : null;
			}
		},
		items: {
			immediate: true,
			handler(val) {
				//console.log("ITEMS CHANGED!", this.isEditing);
				setTimeout(() => {
					this.reinitFilteredItems();
				}, 10);
			}
		},
		allHeaders: {
			immediate: true,
			handler() {
				//console.log("proc-table allHeaders()");

				// init headers
				this.headers = this.allHeaders?.filter(el => Number(el.VISIBLE));
				//console.log("VISIBLE HEADERS updated", this.headers);

				// поставить colspan & rowspan
				if (this.headers?.some(el => el.text?.match(/.+\|.+/))) {
					this.initThsRows();
				} else {
					// no two rowed columns in table headers
					this.thsRow1 = this.headers;
					this.thsRow2 = null;
					this.headers?.forEach(el => {
						el.textRow1 = el.text;
					});
				}

				//console.log("Proc [" + this.procId + "] cols with self validates: " + this.allHeaders.filter(el => el.ONVALIDATE).map(el => el.PROCPARAM));
				//console.log("Proc [" + this.procId + "] cols with ext. validates: " + this.allHeaders.filter(el => el.IS_USESVALIDATES).map(el => el.PROCPARAM));
			}
		},
		search(val, old) {
			//console.log("proc-table search()");

			if (val !== old) {
				this.onFilterDebounced();
			}
		},
		filterCounter(val, old) {
			this.filterItems();
			setTimeout(() => {
				const table = document.getElementById(this.componentId);
				if (table) {
					const wrapper = table.querySelector(".v-data-table__wrapper");
					if (wrapper) wrapper.scrollTop = 0;//wrapper.scrollHeight;
				}
			}, 300);
		},
		isEditing(val, old) {
			//console.log("proc-table isEditing()", val, old);

			//console.log("EDITING", val, old, this.selectedItem);
			if (val && !old) {
				if (!this.selectedItem) return;
				this.editedItem = this.selectedItem;
				this.editedItemIndex = this.selectedItemIndex;
				//this.setFocusOnEditedItem();
			} else if (!val && old) {
				if (!this.isLG) this.scrollToItem(this.editedItem);

				this.editedItem = null;
				this.editedItemIndex = null;
			}

			this.setItemsStyle();

			// обновляем суммы в футере, если вошли/вышли из режима редактирования
			this.countAutosums();
		},
		selectedItem(val, old) {
			//console.log("proc-table selectedItem()");
			//console.log("selected item changed", val, old, this.selectedItemIndex);

			if (val && this.editedItem && val !== this.editedItem) {
				//this.update(this.editedItem, this.editedItemIndex);
				this.editedItem = null;
				this.editedItemIndex = null;
			}

			if (val !== old) this.$emit("input", this.selectedItem);
		},
		page() {
			//console.log("proc-table page()");
			this.pageNumber = this.page;
		},
		pageNumber() {
			//console.log("proc-table pageNumber()");

			this.selectedItem = null;
			this.selectedItemIndex = null;
			this.$emit("input", this.selectedItem);
			this.$emit("page", this.pageNumber);

			this.setItemsStyle();
			this.scrollToTop();
		},
		footerRefresh() {
			this.countAutosums();
		},
		multiselectedItems() {
			this.setItemsStyle();
		},
	},
	methods: {
		/*filterSearch(value, search, item) {
			//console.log("search", search);
			return this.headers.every(col=>{
				if ( col.search ) {
					return item[col.value].toString().find(col.search) !== -1;
				}
				return true;
			});
		},*/
		onFilterInput(col, e) {
			if (this.isTouch) {
				/*
				 On touch devices especially Android Chrome - v-model does NOT change variable immediately as on desktop
				 See https://stackoverflow.com/questions/50617865/vue-v-model-input-change-mobile-chrome-not-work
				 So we use @input event to update variable manually...
				 */
				//if (typeof e.data !== "undefined") col.search = e.data;
			}
		},
		onFilter(col, event) {
			const skipKeys = ['Escape', 'ArrowLeft', 'ArrowRight', 'ArrowUp', 'ArrowDown'];
			if (skipKeys.find(key => key === event.key)) return;

			if (this.isTouch) {
				/*
				 On touch devices especially Android Chrome - v-model does NOT change variable immediately as on desktop
				 See https://stackoverflow.com/questions/50617865/vue-v-model-input-change-mobile-chrome-not-work
				 So we use @input event to update variable manually...
				 */
				//console.log("event", event.target.value);
				col.search = event.target.value;
				//if (typeof e.data !== "undefined") col.search = e.data;
			}

			this.onFilterDebounced();
		},
		onFilterFocus(th, e) {
			///this.$emit("filter", true);
		},
		onFilterBlur(th, e) {
			///this.$emit("filter", false);
		},
		onFilterClear(th, e) {
			th.search = null;
			this.isFiltering = true;
			this.filterItems();
		},
		onFilterDebounced: debounce(function () {
			this.isFiltering = true;
			setTimeout(() => {
				this.filterItems();
			}, 50);
		}, Config.INPUT_DEBOUNCE_TIMEOUT_LONG),
		onItemClick(event, item) {
			//console.log("onItemClick()");
			if (this.isEditing) return;
			if (this.hasGlobalEditedItemOfSameForm) return;

			const processClick = (item) => {
				this.selectedItemIndex = this.items.findIndex(el => el === this.selectedItem);
				this.selectedItem = item;

				this.$emit("click", item, this.selectedItemIndex);

				/*if (this.isTouch) {
					// для мобильного устройства - эмулируем дабл клик
					this.onItemDblClick(item, this.selectedItemIndex);
				}*/
			}

			if (event?.ctrlKey && this.multiselectedItems.length) {
				this.appendMultiselectedItems(item);
				if (this.selectedItem === item) {
					// we clicked current selected item - and unselected it - reselect another one
					processClick(this.multiselectedItems[0]);
				}
			} else if (event?.shiftKey && this.selectedItem) {
				const index = this.items.findIndex(el => el === item);
				const startIndex = this.items.findIndex(el => el === this.selectedItem);

				this.resetMultiselectedItems(this.selectedItem);
				if (index > startIndex) {
					for (let i = startIndex; i <= index; i++) {
						let item = this.items[i];
						this.appendMultiselectedItems(item, true);
					}
				} else if (index < startIndex) {
					for (let i = startIndex; i >= index; i--) {
						let item = this.items[i];
						this.appendMultiselectedItems(item, true);
					}
				}

			} else {
				this.resetMultiselectedItems(item);
				processClick(item);
				//this.previousItem = item;
			}

			//console.log("onItemClick", event, item);
		},
		onItemDblClick(item) {
			//console.log("onItemDblClick()");
			if (this.hasGlobalEditedItemOfSameForm) return;
			this.$emit("open", item);
		},
		onItemMenuClick(event, item, index) {
			this.onItemClick(event, item);
			this.$emit('item-menu', event, item, index);
		},
		/**
		 * Called on input changes done by user.
		 * @param item
		 * @param index
		 * @param col
		 * @param colIndex
		 */
		onEditedItemChange(item, index, col, colIndex) {
			/*console.log("Column changed "+col.value+", needs validate:" + col.IS_USESVALIDATES);
			if ( col.IS_USESVALIDATES || col.ONVALIDATE ) {
				//console.log("Column uses validates: "+col.value);
				this.validateFromColDebounced(item, col);
			}*/
		},
		onInputKeyPress(item, index, col, colIndex, event) {
			//this.update(item, index, col, colIndex);
			if (event.key === "Enter") {
				this.$emit("save", item);
			}
			this.onEditedItemChange(item, index, col, colIndex);
		},
		onInputFocus(event) {
			//event.target.select();
		},
		onSelectorClick(item, index, col, colIndex) {
			if (this.isReadonly(item, col)) return;
			this.$emit("selector", item, col, () => this.onEditedItemChange(item, index, col));
		},
		onSelectorClear(item, index, col, colIndex) {
			//console.log("CLEAR", item, col);
			item[col.PROCPARAM] = null;
			item[col.KEYFIELD] = null;
			this.onEditedItemChange(item, index, col);
		},
		onDateClick(item, index, col, colIndex) {
			if (this.isReadonly(item, col)) return;
			this.$emit("date", item, col, () => this.onEditedItemChange(item, index, col));
		},
		onCheckbox(item, index, col, colIndex) {
			if (this.isReadonly(item, col)) return;
			item[col.value] = !Number(item[col.value]);
			//this.update(item, index, col, colIndex);
			this.onEditedItemChange(item, index, col, colIndex);
		},
		onSort(th) {
			if (this.draggedTH.dummy) return;

			if (th._sort === "asc") Vue.set(th, "_sort", "desc");
			else if (th._sort === "desc") Vue.set(th, "_sort", null);
			else Vue.set(th, "_sort", "asc");

			this.reinitFilteredItems();
		},
		onTableScroll(e) {
			const tableScrollLeft = e.target.scrollLeft;
			if (tableScrollLeft !== this.tableScrollLeft) {
				this.recreateResizers();
				this.tableScrollLeft = tableScrollLeft;
			}
		},
		onResize() {
			this.recreateResizers();
		},
		onResizerMouseDown(resizer) {
			this.activeResizer = resizer;
			this.activeResizer.el.classList.add("active");
		},
		onMouseMove(e) {
			if (this.activeResizer) {
				//console.log("onMouseMove", e);
				const parent = this.el;
				const parentRect = parent.getBoundingClientRect();

				const el = this.activeResizer.el;
				let x = e.x - parentRect.left;

				const index = this.activeResizer.index;
				if (index === this.resizers.length - 1) {
					// for last resizer we allow only resizing to left
					const leftResizer = this.resizers[index - 1];
					if (x > parentRect.width) x = parentRect.width;
					else if (leftResizer && x < leftResizer.x + MIN_TH_SIZE) x = leftResizer.x + MIN_TH_SIZE;
				} else if (index > 0) {
					const leftResizer = this.resizers[index - 1];
					if (x < leftResizer.x + MIN_TH_SIZE) x = leftResizer.x + MIN_TH_SIZE;
					else if (x > leftResizer.x + MAX_TH_SIZE) x = leftResizer.x + MAX_TH_SIZE;
				} else {
					// for first resizer - allow only resizing to right
					if (x < MIN_TH_SIZE) x = MIN_TH_SIZE;
				}

				//if (x > MAX_TH_SIZE) x = MAX_TH_SIZE;

				x = Math.round(x);
				el.style.left = x + "px";
				this.activeResizer.x = x;
			} else if (this.draggedTH.el && this.draggedTH.col) {
				if (Math.abs(this.draggedTH.startX - e.x) > 5 ||
					Math.abs(this.draggedTH.startY - e.y) > 5 ||
					this.draggedTH.dummy) {
					const parent = this.el;
					const parentRect = parent.getBoundingClientRect();
					const rect = this.draggedTH.el.getBoundingClientRect();
					let dummy = this.draggedTH.dummy;
					if (!dummy) {
						this.draggedTH.el.classList.add("dragged");
						dummy = document.createElement("div");
						dummy.classList.add("proc-table__th-drag-dummy");
						dummy.innerHTML = this.draggedTH.el.innerHTML;
						//console.log("this.draggedTH.el.innerHTML", this.draggedTH.el.innerHTML);
						dummy.style.width = rect.width + "px";
						dummy.style.height = rect.height + "px";
						//const x = rect.left - parentRect.left;
						//const y = rect.top - parentRect.top;
						parent.appendChild(dummy);
						dummy.addEventListener("mousemove", this.onMouseMove);
						dummy.addEventListener("mouseup", this.onMouseUp);
						this.draggedTH.dummy = dummy;
					}

					const x = e.x - parentRect.left - (this.draggedTH.startX - (rect.left - parentRect.left));
					const y = rect.top - parentRect.top + 4;// + 20;
					//console.log("onMouseMove", this.draggedTH.startX, x);

					dummy.style.left = x + "px";
					dummy.style.top = y + "px";
				}
			}
		},
		onMouseUp(e, th, index) {
			//console.log("onMouseUp");
			if (this.activeResizer) {
				e.preventDefault();
				e.stopPropagation();
				this.applyResizer(this.activeResizer, e);
				this.activeResizer.el.classList.remove("active");
				this.activeResizer = null;
			}
			if (this.draggedTH.el || this.draggedTH.dummy) {
				//console.log("onMouseUp");

				this.draggedTH.dummy?.classList.add("drag-end");

				// clear dragged state
				// need timeout to allow mouseUp on real TH and to prevent click event & onSort
				setTimeout(() => {
					if (this.draggedTH.el) {
						this.draggedTH.el.classList.remove("dragged");
						this.draggedTH.el = null;
						this.draggedTH.col = null;
						this.recreateResizers();
					}

					// remove dummy
					if (this.draggedTH.dummy) {
						this.draggedTH.dummy.removeEventListener("mousemove", this.onMouseMove);
						this.draggedTH.dummy.removeEventListener("mouseup", this.onMouseUp);
						const parent = this.el;
						parent.removeChild(this.draggedTH.dummy);
						this.draggedTH.dummy = null;
					}
				}, 150);
			}
		},
		onTHMouseDown(e, col, index, isColspan) {
			//console.log("onTHMouseDown", e);
			let target = e.target;
			//console.log("onTHMouseDown", target.tagName);
			if (target.tagName.toLowerCase() !== "th") target = target.closest("th");
			this.draggedTH.el = target;
			this.draggedTH.col = col;
			this.draggedTH.index = this.headers.findIndex(el => el === col);	// important to take real index in headers
			const parent = this.el;
			const parentRect = parent.getBoundingClientRect();
			this.draggedTH.startX = e.x - parentRect.left;
			this.draggedTH.startY = e.y - parentRect.left;
		},
		onTHMouseUp(e, col, index) {
			if (this.draggedTH.dummy) {

				console.group("Drag TH");
				let isChanged = false;
				if (this.draggedTH.col === col) {
					console.log("same...");
					// do nothing
				} else if (this.draggedTH.col.textRow1 && this.draggedTH.col.textRow2 && col.textRow1) {
					console.log("ROW1");
					// this is a top th in column-span dragged - reorder all spanned headers
					let spans = this.headers.splice(this.draggedTH.index, this.draggedTH.col.colspan);
					let newIndex = this.headers.findIndex(el => el === col);
					if (newIndex === this.draggedTH.index) newIndex++;
					spans.forEach((el, i) => {
						this.headers.splice(newIndex + i, 0, el);
					});
					console.log("from", this.draggedTH.index);
					console.log("to", newIndex);
					isChanged = true;

				} else if (this.draggedTH.col.textRow2 && col.textRow2) {
					console.log("ROW2");
					// this is a bottom th in column-span dragged - allow only rearranging in span
					//const spanParentIndex = this.headers.findIndex((el,i)=>!!el.textRow1 && i + el.colspan > this.draggedTH.index);
					if (col.colspanGroup === this.draggedTH.col.colspanGroup) {
						this.headers.splice(this.draggedTH.index, 1);
						let newIndex = this.headers.findIndex(el => el === col);
						if (newIndex === this.draggedTH.index) newIndex++;
						this.headers.splice(newIndex, 0, this.draggedTH.col);
						console.log("from", this.draggedTH.index);
						console.log("to", newIndex);
						isChanged = true;
					} else console.log("Different colspans");
				} else if (this.draggedTH.col.textRow2) {
					console.log("ROW2 source over ROW1");
					// do nothing
				} else if (col.textRow2) {
					console.log("ROW1/2 target from ROW1");
					// this is a drop over row2 cell
					this.headers.splice(this.draggedTH.index, 1);
					let newIndex = this.headers.findIndex(el => el === col.parent || el === col);
					if (newIndex === this.draggedTH.index) {
						newIndex += col.parent ? col.parent.colspan : col.colspan;
					}
					this.headers.splice(newIndex, 0, this.draggedTH.col);
					console.log("from", this.draggedTH.index);
					console.log("to", newIndex);
					isChanged = true;
				} else {
					console.log("single drag");
					// this is single row header
					this.headers.splice(this.draggedTH.index, 1);
					let newIndex = this.headers.findIndex(el => el === col);
					if (newIndex === this.draggedTH.index) newIndex++;
					this.headers.splice(newIndex, 0, this.draggedTH.col);
					console.log("from", this.draggedTH.index);
					console.log("to", newIndex);
					isChanged = true;
				}


				this.draggedTH.col = null;
				if (isChanged) {
					this.initThsRows();
					this.updateStyleElement();
					this.redrawFooterDebounced();
					this.submitColumnData();
				}

				console.groupEnd();
			}
		},
		onContentViewScroll(e) {
			if (!this.isLG) return;
			//console.log("onContentViewScroll", e);
			const x = e.srcElement.scrollLeft;
			const y = e.srcElement.scrollTop;
			//console.log("\tleft", x);
			//console.log("\ttop", y);
			if (x !== this.lastContentViewScrollLeft) {
				this.isFooterVisible = false;
				this.lastContentViewScrollLeft = x;
				this.redrawFooterDebounced();
			}
		},
		/**
		 * Called when edited item values are changed by user or by programmatic changes.
		 * See also onEditedItemChange.
		 * @param item
		 * @param old
		 */
		onEditedItemValuesChanges() {
			const item = this.editedItem;
			const old = this.editedItemLast;
			//console.log("Edited item values change", item, old);
			if (item && old) {
				// we should compare every header col even hidden one
				this.allHeaders.forEach(col => {
					//console.log("\tChecking "+col.PROCPARAM, item[col.PROCPARAM], old[col.PROCPARAM]);
					if (item[col.PROCPARAM] !== old[col.PROCPARAM]) {
						//console.log("Col "+col.PROCPARAM+" changed", item[col.PROCPARAM], old[col.PROCPARAM]);
						if (col.IS_USESVALIDATES || col.ONVALIDATE) {
							//this.$emit("validate", item, col);
							console.log("Col " + col.PROCPARAM + " should be validated: " + item[col.PROCPARAM]);
							this.$emit("validate", item, col);
						}
					}
				});
			}
		},
		onClickFooterBtn() {
			this.isFooterVisible = !this.isFooterVisible;
		},
		filterItems() {
			//console.log("filterItems()");
			this.reinitFilteredItems();
			setTimeout(() => {
				this.isFiltering = false;
			}, 150);
		},
		appendMultiselectedItems(item, keepPrevious = false) {
			const index = this.multiselectedItems.findIndex(el => el === item);
			if (index >= 0) {
				if (!keepPrevious && this.multiselectedItems.length > 1) this.multiselectedItems.splice(index, 1);
			} else this.multiselectedItems.push(item);

			this.$emit("multiselect", this.multiselectedItems);
		},
		resetMultiselectedItems(item) {
			this.multiselectedItems.splice(0, this.multiselectedItems.length);
			if (item) this.multiselectedItems.push(item);

			this.$emit("multiselect", this.multiselectedItems);
		},
		initThsRows() {
			const colspans = {};
			let colspanGroup = 1;
			this.headers.forEach(el => {
				if (el.text?.match(/.+\|.+/)) {
					const span = el.text.replace(/^(.+?)\|.+/, "$1").trim();
					const caption = el.text.replace(/^.+?\|(.+)$/, "$1").trim();
					if (colspans[span]) {
						colspans[span].colspan++;
						el.colspan = null;
						el.textRow1 = null;
						el.textRow2 = caption;
						el.parent = colspans[span];
						el.colspanGroup = el.parent.colspanGroup;
					} else {
						el.colspan = 1;
						colspans[span] = el;
						el.textRow1 = span;
						el.textRow2 = caption;
						el.colspanGroup = colspanGroup++;
					}
				} else {
					el.rowspan = 2;
					el.textRow1 = el.text;
				}
			});

			// fix headers order to keep two-rowed columns together
			const headers = [];
			this.headers.filter(el => el.textRow1).forEach((el, index) => {
				headers.push(el);
				if (el.colspanGroup) {
					//console.log("PARENT of " + el.colspanGroup + ": " + el.textRow1);
					// move all children afterwards
					this.headers.filter(child => child.colspanGroup === el.colspanGroup && !child.textRow1).forEach((child, childIndex) => {
						//console.log("CHILD of " + child.colspanGroup + ": " + el.textRow2 + ": " + (!el.textRow1));
						headers.push(child);
					});
				}
			});
			this.headers = headers;
			//console.log("Headers", this.headers.map(el => el.FLDORDER + ". " + el.textRow1 + "." + el.textRow2 + ": " + el.colspanGroup));

			this.thsRow1 = this.headers.filter(el => !!el.textRow1);
			this.thsRow2 = this.headers.filter(el => !!el.textRow2);
			if (!this.thsRow2.length) this.thsRow2 = null;
		},
		reinitFilteredItems() {
			const matches = (item, col) => {
				let filter = col.search;
				if (!filter) return true;

				let value;
				//let value = item[col.PROCPARAM] + "";
				if (this.isCheckbox(item, col)) {
					return !!item[col.PROCPARAM];
				} else if (this.isDateTime(item, col)) {
					value = iso2DDMMYYYY(item[col.PROCPARAM]);
				}
				/*else if (this.isCheckbox(item, col)) {
					value = (item[col.PROCPARAM] + "").toLowerCase();
					//console.log("VALUE", value);
				}*/
				else {
					value = (item[col.PROCPARAM] + "").toLowerCase();
				}

				//console.log("VALUE", str + ":" + s + ":" + str.indexOf(s));
				filter = (filter + "").toLowerCase().trim();
				return value.indexOf(filter) !== -1;
			};

			const hasFilters = this.headers.some(col => !!col.search);
			//console.log("Proc [" + this.procId + "] has filters: " + hasFilters);

			if (!hasFilters && !this.search) this.filteredItems = [...this.items];
			else this.filteredItems = this.items.filter((item, index) => {

				if ( index === 0 ) console.log(index+": ", this.isEditing, item);

				// в режиме редактирования всегда оставлять первую строку
				if (this.isEditing && index === 0) return true;

				let foundByFilters = false, found = false;
				if (hasFilters) {
					foundByFilters = this.headers?.every(col => {
						return matches(item, col);
					});
				} else foundByFilters = true;

				//console.log("foundByFilters", item['FULLNAME'], foundByFilters);

				// add global search filter
				if (this.search) {
					found = this.headers?.some(col => matches(item, col, this.search));
				} else found = true;

				//console.log("found", item['FULLNAME'], found);

				return foundByFilters && found;
			});
			//.filter(el=>el.FS_ITALIC);
			//.filter(el=>el.BG_COLOR || el.COLOR);

			const sortBy = this.headers.find(el => !!el._sort);
			if (sortBy) {
				console.log("Proc [" + this.procId + "] has sorting: " + sortBy.PROCPARAM);
				this.filteredItems.sort((aItem, bItem) => {
					let a = aItem[sortBy.PROCPARAM];
					let b = bItem[sortBy.PROCPARAM];
					let isNumber = false;
					if (typeof a === "string") {
						a = a.trim().toLowerCase();
						if (a.match(/^\d+(\.\d+)?$/)) {
							a = Number(a);
							b = Number(b);
							isNumber = true;
						}
					}
					if (typeof b === "string") b = b.trim().toLowerCase();
					if (a === null) a = "";
					if (b === null) b = "";
					if (false && this.isCheckbox(a, sortBy)) {
						if (sortBy._sort === "desc") {
							if (a && !b) return -1;
							if (!a && b) return 1;
						} else {
							if (a && !b) return -1;
							if (!a && b) return 1;
						}
					} else {
						/*console.log("A: "+a);
						console.log("B: "+b);
						console.log("\t a < b: "+(a < b));*/
						if (sortBy._sort === "desc") {
							if (a < b) return 1;
							if (a > b) return -1;
						} else {
							if (a < b) return -1;
							if (a > b) return 1;
						}
					}

					return 0;
				});
			}

			// если больше нет текущего элемента - снять выделение
			if (!this.filteredItems.find(el => el === this.selectedItem)) {
				this.selectedItem = null;
				this.selectedItemIndex = null;
				//console.log("Selected item reset");
			}

			this.countAutosums();
			this.setItemsStyle();

			this.isInited = true;

			/*console.log("hasFilters", hasFilters);
			console.log("this.selectedItem", this.selectedItem);
			console.log("find", this.filteredItems.find(el=>el===this.selectedItem));

			if ( hasFilters && this.selectedItem && this.filteredItems.find(el=>el===this.selectedItem) ) {
				this.selectedItem = null;
				this.selectedItemIndex = null;
			}*/
		},
		countAutosums() {
			//console.log("countAutosums");
			// рассчитать автосуммы
			this.headersWithAutosum?.forEach(col => {
				if (col.AUTOSUM === "COUNT") {
					col.autosumResult = nf(this.filteredItems.length);
					col.autosumTitle = "Количество";
				} else if (col.AUTOSUM === "SUM") {
					col.autosumResult = nf(this.filteredItems.reduce((sum, item) => {
						const v = parseFloat(item[col.value]);
						return sum + (isNaN(v) ? 0 : v);
					}, 0), 2);
					col.autosumTitle = "Сумма";
				} else if (col.AUTOSUM === "MIN") {
					col.autosumResult = this.filteredItems.reduce((min, item) => {
						const value = parseFloat(item[col.value]);
						return value < min || min === null ? value : min;
					}, null);
					if (col.autosumResult === null) col.autosumResult = 0;
					else col.autosumResult = nf(col.autosumResult, 2);
					col.autosumTitle = "Минимальное значение";
				} else if (col.AUTOSUM === "MAX") {
					col.autosumResult = this.filteredItems.reduce((max, item) => {
						const value = parseFloat(item[col.value]);
						return value > max || max === null ? value : max;
					}, null);
					if (col.autosumResult === null) col.autosumResult = 0;
					else col.autosumResult = nf(col.autosumResult, 2);
					col.autosumTitle = "Максимальное значение";
				} else if (col.AUTOSUM === "AVG") {
					const total = col.autosumResult = this.filteredItems.reduce((sum, item) => {
						return sum + parseFloat(item[col.value]);
					}, 0);
					if (this.filteredItems.length) col.autosumResult = nf(total / this.filteredItems.length, 2);
					col.autosumTitle = "Среднее";
				}
			});
		},
		/**
		 * Так как этот метод вызывается каждый раз, как только меняется this.selectedItem/this.editedItem.
		 * Поэтому при изменении текущего элемента ПЕРЕРИСОВЫВАЕТСЯ ВСЯ ТАБЛИЦА.
		 * @param item
		 * @param index
		 * @returns {*[]|null}
		 */
		itemClass(item, index) {
			//if (item.isDeleted) return "deleted";
			const c = [];
			//if (this.isFiltering) c.push("filtering");
			if (this.selectedItem === item) {	// this redraws all table!
				c.push("current");
				if (this.editedItem === item) c.push("edited");
			}
			//if (!this.readonly && this.selectedItemIndex === null && index === 0) c.push("edit");
			return c.length ? c : null;
		},
		itemStyle(item, index) {
			/*
			COLOR        - определяет цвет текста строки (значение RGB)
			BG_COLOR  - определяет цвет фона строки (значение RGB)
			FS_BOLD     - определяет жирность текста (значение 1 или 0)
			FS_STRIKE   - определяет перечеркивание текста (значение 1 или 0)
			FS_ITALIC    - определяет курсив текста (значение 1 или 0)
			 */
			const s = {};

			if (item.COLOR) {
				s.color = "#" + item.COLOR.substring(7, 10)
					+ item.COLOR.substring(5, 7)
					+ item.COLOR.substring(3, 5);
			}
			if (item.BG_COLOR) {
				s.backgroundColor = "#" + item.BG_COLOR.substring(7, 10)
					+ item.BG_COLOR.substring(5, 7)
					+ item.BG_COLOR.substring(3, 5);
			}
			if (item.FS_BOLD) s.fontWeight = "bold";
			if (item.FS_STRIKE) s.textDecoration = "line-through";
			if (item.FS_ITALIC) s.fontStyle = "italic";
			//console.log(item);
			return s;
		},
		thRow1Class(th, index) {
			const c = [];
			if (!this.thsRow2?.length) c.push("_x" + this.id + "-" + index);
			if (this.isIcon(th)) c.push("icon");
			c.push("sortable");
			if (!!th._sort) c.push("sorted");
			return c;
		},
		thRow2Class(th, index) {
			const c = [];
			const colIndex = this.headers.findIndex(el => el.ID_PROCPARAM === th.ID_PROCPARAM);
			if (colIndex >= 0) c.push("_x" + this.id + "-" + colIndex);
			if (this.isIcon(th)) c.push("icon");
			c.push("sortable");
			if (!!th._sort) c.push("sorted");
			return c;
		},
		thFilterClass(th, index) {
			const c = [];
			c.push("_x" + this.id + "-" + index);
			return c;
		},
		tdClass(item, col, colIndex) {
			const c = [];
			if (this.isLG) c.push("_x" + this.id + "-" + colIndex);

			///if (this.selectedItem === item ) c.push("current");	this redraws all table!
			if (!!col.search || !!col._sort) c.push("filtered");
			if (this.isReadonly(item, col)) c.push("readonly");

			if (this.isSelector(item, col)) c.push("selector");
			else if (this.isDateTime(item, col)) c.push("date");
			else if (this.isCheckbox(item, col)) c.push("checkbox");
			else if (this.isIcon(col)) c.push("icon");
			else if (this.isInteger(item, col)) c.push("text-right justify-end");
			return c.length ? c : null;
		},
		/*		tdClassAdaptive(item, col, colIndex) {
					const c = [];
					//c.push("_x" + this.id + "-" + colIndex);

					///if (this.selectedItem === item ) c.push("current");	this redraws all table!
					if (this.isReadonly(item, col)) c.push("readonly");

					if (this.isSelector(item, col)) c.push("selector");
					else if (this.isDateTime(item, col)) c.push("date");
					else if (this.isCheckbox(item, col)) c.push("checkbox");
					else if (this.isIcon(col)) c.push("icon");
					else if (this.isInteger(item, col)) c.push("text-right justify-end");
					return c.length ? c : null;
				},*/
		footerTDClass(col, colIndex) {
			const c = [];
			c.push("_x" + this.id + "-" + colIndex);
			return c.length ? c : null;
		},
		inputClass(item, index, col, colIndex) {
			const c = [];
			c.push('input_' + index + '_' + colIndex);
			if (this.isInteger(item, col)) c.push("text-right");
			return c;
		},
		/*update(item, index, col, colIndex) {
			this.$emit("update", item, index);
		},*/
		isSelector(item, col) {
			return !!col.ID_SELECTION_LKP;
		},
		isReadonly(item, col) {
			return !!col.IS_READONLY;
		},
		isIcon(col) {
			return !!Number(col.IS_PICTURE?.trim());
		},
		isURL(col) {
			return !!Number(col.IS_URL?.trim());
		},
		isDateTime(item, col) {
			if (!item) return false;
			return col.FIELD_TYPE_EX?.match(/^date/)
				|| col.FIELD_TYPE_EX?.match(/^timestamp/)
				|| (typeof item[col.value] === "string" && item[col.value]?.match(/^\d{4}-\d{2}-\d{2}/));
		},
		isInteger(item, col) {
			return col.FIELD_TYPE_EX?.match(/^integer/)
				|| col.FIELD_TYPE_EX?.match(/^decimal/);
		},
		isCheckbox(item, col) {
			return !!col.CHECKBOX;
		},
		iconUrlForCol(col) {
			if (!this.isIcon(col)) return null;
			const auth = localStorage.getItem(Config.STORAGE_AUTH_TOKEN);
			return "/api/IconController?id=" + col['ID_ICON']
				+ "&auth=" + auth;
		},
		iconUrlForItem(item, col) {
			if (!this.isIcon(col)) return null;
			if (!col['PROCPARAM'] || !item[col['PROCPARAM']]) return null;
			const auth = localStorage.getItem(Config.STORAGE_AUTH_TOKEN);
			return "/api/IconController?id=" + item[col['PROCPARAM']]
				+ "&auth=" + auth;
		},
		preserveSpaces(str) {
			return preserveSpaces(str);
		},
		applyResizer(activeResizer, e) {
			//console.log("applyResizer", activeResizer, e);

			const withAlt = e.shiftKey;	// resizing the right col instead of left col
			const parent = this.el;
			const parentRect = parent.getBoundingClientRect();

			let col, width;
			const index = activeResizer.index;
			if (withAlt) {
				// resize column on the right
				let right;
				if (index >= this.resizers.length - 1) right = parentRect.width;
				else {
					const rightResizer = this.resizers[index + 1];
					right = rightResizer.x;
				}
				col = this.headers[index + 1];
				width = Math.round(right - activeResizer.x);
			} else {
				// resize column on the left
				let left = 0;
				if (index > 0) {
					const leftResizer = this.resizers[index - 1];
					left = leftResizer.x;
				}
				col = activeResizer.col;
				width = Math.round(activeResizer.x - left);
			}

			col.WIDTH = width;

			// fix all cols width - it should be not less than parent.width
			const totalWidth = this.headers.reduce((all, el) => all + el.WIDTH + 1, 0);
			if (totalWidth < parentRect.width) {
				if (this.headers.length === 1) {
					col.WIDTH = parentRect.width;
				} else {
					const colIndex = this.headers.findIndex(el => el === col);
					const plus = parentRect.width - totalWidth;
					const countOnRight = this.headers.reduce((total, el, index) => index > colIndex ? total += 1 : total, 0);
					const plusForEach = plus / countOnRight;
					this.headers.forEach((el, index) => {
						if (index > colIndex) el.WIDTH += plusForEach;
					});
				}
			}

			this.updateStyleElement();
			setTimeout(() => {
				this.recreateResizers();
			}, 150);

			this.submitColumnData();
			//console.groupEnd();
		},
		submitColumnData() {
			//console.log("submitColumnData");

			// todo temporally - save this to storage
			/*const data = this.headers.filter(el => !!el.WIDTH).map(el => ({
				id: el.ID_PROCPARAM,
				w: el.WIDTH,
			}));
			localStorage.setItem(STORAGE_WIDTHS_PREFIX + this.procId, JSON.stringify(data));*/

			return this.$store.dispatch("post", {
				action: "ProcSettingsController",
				params: {
					procNameId: this.procId,
					headers: this.headers.map((el, index) => ({
						id: el.ID_PROCPARAM,
						index,
						width: el.WIDTH,
						sort: !!el._sort ? (el._sort === "desc" ? 2 : 1) : 0
					}))
				},
			})
				.then(res => {
					//console.log("RES", res);
				})
				.catch(err => {
					console.error("ERROR", err);
					this.$emit("error", err?.error);
				})
				.finally(() => {
				});
		},
		async initEl() {
			return new Promise(resolve => {
				setTimeout(() => {
					this.el = document.getElementById("proc-table-" + this.id);
					resolve();
				}, 150);
			});
		},
		recreateResizers() {
			setTimeout(() => {
				if (!this.el) return;

				this.destroyResizers();
				this.resizers = [];

				const parent = this.el;
				const tr = parent.querySelector("tr.filters");
				if (tr) {
					const ths = [...tr.querySelectorAll("th")];
					const parentRect = parent.getBoundingClientRect();
					//const elRectHeight = rect.bottom - parentRect.top;//parentRect.height - (this.withPaging ? 30 : 0);	// fixme const
					this.headers.forEach((col, index) => {
						const th = ths[index];
						const rect = th.getBoundingClientRect();
						if (!col.WIDTH) this.$set(col, "width", Math.round(rect.width));
						if (index + 1 >= ths.length) return;

						//console.log("th rect", rect);
						const el = document.createElement("div");
						//el.setAttribute("draggable", true);
						const x = rect.right - parentRect.left;
						el.style.left = x + "px";
						el.style.top = "0";
						//resizer.style.width = "9px";
						el.style.height = (rect.bottom - parentRect.top) + "px";
						el.classList.add("proc-table__resizer");
						parent.appendChild(el);

						const resizer = {
							col,
							th,
							el,
							index,
							x
						};

						this.addResizerEvents(resizer);
						this.resizers.push(resizer);
					});

					const tableWrapper = parent.querySelector(".v-data-table__wrapper");
					tableWrapper.addEventListener("scroll", this.onTableScroll);
					tableWrapper.addEventListener("mousemove", this.onMouseMove);
					tableWrapper.addEventListener("mouseup", this.onMouseUp);
				}

				this.redrawFooter();
			}, 150);
		},
		addResizerEvents(resizer) {
			resizer.onMouseDown = (event) => this.onResizerMouseDown(resizer, event);
			resizer.el.addEventListener("mousedown", resizer.onMouseDown);
			resizer.el.addEventListener("mousemove", this.onMouseMove);
			resizer.el.addEventListener("mouseup", this.onMouseUp);
		},
		/*updateResizers() {
			const parent = this.el;
			const parentRect = parent.getBoundingClientRect();
			const elRectHeight = 60;//parentRect.height - (this.withPaging ? 30 : 0);	// fixme const
			this.resizers?.forEach(resizer => {
				const rect = resizer.th.getBoundingClientRect();
				//console.log("th rect", rect);
				const x = rect.right - parentRect.left;
				resizer.el.style.left = x + "px";
				resizer.el.style.top = "0";
				//resizer.style.width = "9px";
				resizer.el.style.height = (rect.bottom - parentRect.top) + "px";
				resizer.x = x;
				resizer.index = x;
			});
		},*/
		destroyResizers() {
			const parent = this.el;
			if (!parent) return;

			const tableWrapper = parent.querySelector(".v-data-table__wrapper");
			tableWrapper.removeEventListener("scroll", this.onTableScroll);
			tableWrapper.removeEventListener("mousemove", this.onMouseMove);
			tableWrapper.removeEventListener("mouseup", this.onMouseUp);

			this.resizers?.forEach(resizer => {
				resizer.el.removeEventListener("mousedown", resizer.onMouseDown);
				resizer.el.removeEventListener("mousemove", this.onMouseMove);
				resizer.el.removeEventListener("mouseup", this.onMouseUp);
				parent.removeChild(resizer.el);
			});

			this.resizers = null;
		},
		/**
		 * todo this was temporary while BE was not ready
		 * @deprecated
		 */
		restoreColumnData() {
			//console.group("restoreColumnData");
			let widths = localStorage.getItem(STORAGE_WIDTHS_PREFIX + this.procId);
			if (widths) {
				//console.log("raw widths", widths);
				try {
					widths = JSON.parse(widths);
					//console.log("parsed widths", widths);
					if (widths) {
						widths.forEach(d => {
							const col = this.headers.find(el => el.ID_PROCPARAM === d.id);
							if (col) {
								this.$set(col, "width", d.w);
								//console.log("COL" + d.id + ": " + d.w);
							}
						});
					}
				} catch (e) {
					//console.log("exception!", e);
				}
			}
			//console.groupEnd();
		},
		createStyleElement() {
			const style = document.createElement('style');
			style.id = 'style-proc-table-cols-' + this.id;
			style.type = 'text/css';
			document.getElementsByTagName('head')[0].appendChild(style);
			this.style = style;

			this.updateStyleElement();
		},
		updateStyleElement() {
			const styles = [];
			this.headers.forEach((col, index) => {
				if (!col.WIDTH) return;
				const width = col.WIDTH + "px";
				const props = [];
				props.push("min-width:" + width + ";");
				props.push("max-width:" + width + ";");
				styles.push("._x" + this.id + "-" + index + " {" + props.join("") + "}");
			});
			this.style.innerHTML = styles.join("\n");
		},
		destroyStyleElement() {
			if (this.style) {
				document.getElementsByTagName('head')[0].removeChild(this.style);
				this.style = null;
			}
		},
		/**
		 * Мы используем прямое измененив в DOM, так как если устанавливать реактивные CSS-классы - таблица полностью перерислвывается,
		 * что негативно сказывается на производительности и в т.ч. на иконках в элементах.
		 */
		setItemsStyle() {
			// пауза для обновления DOM
			setTimeout(() => {
				const id = 'proc-table-' + this.id;
				const table = document.getElementById(id);
				if (!table) return;
				//console.log("TABLE", table);
				let trs = document.querySelectorAll("#" + id + " table tbody tr");

				if (this.selectedItem) {
					let index = this.filteredItems.findIndex(el => el === this.selectedItem);
					if (index >= 0) {
						const pageNumber = Math.floor(index / this.itemsPerPage) + 1;
						//console.log("Page index "+pageNumber);
						//console.log("Table page index "+this.pageNumber);
						if (pageNumber === this.pageNumber) {
							//const index = this.selectedItemIndex % this.itemsPerPage + 1;
							index = index % this.itemsPerPage + 1;
							trs = document.querySelectorAll("#" + id + " table tbody tr:not(:nth-child(" + index + "))");
							const query = "#" + id + " table tbody tr:nth-child(" + index + ")";
							const tr = document.querySelector(query);
							if (tr) {
								tr.classList.add("current");
								if (this.editedItem === this.selectedItem) tr.classList.add("edited");
								else tr.classList.remove("edited");
								//console.log("TR", query, tr, this.editedItem);
							}
						}
					}
				}

				trs.forEach(tr => tr.classList.remove("current"));
				trs.forEach(tr => tr.classList.remove("edited"));
				trs.forEach(tr => tr.classList.remove("multiselected"));

				if (this.multiselectedItems.length > 1) {
					this.multiselectedItems.forEach(multiselectedItem => {
						let index = this.filteredItems.findIndex(el => el === multiselectedItem);
						if (index >= 0) {
							const pageNumber = Math.floor(index / this.itemsPerPage) + 1;
							//console.log("Page index "+pageNumber);
							//console.log("Table page index "+this.pageNumber);
							if (pageNumber === this.pageNumber) {
								index = index % this.itemsPerPage + 1;
								const query = "#" + id + " table tbody tr:nth-child(" + index + ")";
								const tr = document.querySelector(query);
								tr.classList.add("multiselected");
							}
						}
					});
				}

			}, 150);

			/*const id = 'style-proc-table-'+this.id;
			let style = document.getElementById(id);
			if ( !style ) {
				style = document.createElement('style');
				style.id = id;
				style.type = 'text/css';
				document.getElementsByTagName('head')[0].appendChild(style);
				console.log("Style created "+id);
			}

			const styles = [];
			if ( this.selectedItem ) {
				const index = this.selectedItemIndex % this.itemsPerPage + 1;
				styles.push("#proc-table-" + this.id + " table tr:nth-child(" + index + ") td {background:#eef !important;}");
				//if ( this.editedItem === this.selectedItem ) styles.push("#proc-table-" + this.id + " table tr:nth-child(" + index + ") td {background:#eef !important;}");
			}

			style.innerHTML = styles.join("\n");*/
		},
		setFocusOnEditedItem() {
			setTimeout(() => {
				const table = document.getElementById(this.componentId);
				if (table) {
					const tr = table.querySelector("tr.--edited");
					if (tr) {
						const firstInput = tr.querySelector("input");
						if (firstInput) firstInput.focus();
						if (this.isTouch) {
							// small pause to activate mobile softkeyboard
							//setTimeout(() => {
							const wrapper = table.querySelector(".v-data-table__wrapper");
							wrapper.scrollTop = tr.offsetTop;
							//}, 150);
						}
					}
				}

			}, 150);
		},
		scrollToTop() {
			// need pause to be sure that DOM is ready
			setTimeout(() => {
				const table = document.getElementById(this.componentId);
				if (table) {
					const wrapper = table.querySelector(".v-data-table__wrapper");
					wrapper.scrollTop = 0;
				}
			}, 150);
		},
		scrollToItem(item) {
			// need pause to be sure that DOM is ready
			setTimeout(() => {
				let index = this.filteredItems.findIndex(el => el === item);
				if (index >= 0) {
					index -= (this.pageNumber - 1) * this.itemsPerPage;
					const table = document.getElementById(this.componentId);
					if (table) {
						const tr = table.querySelector("tr[data-index='" + index + "'");
						if (tr) {
							const wrapper = table.querySelector(".v-data-table__wrapper");
							wrapper.scrollTop = tr.offsetTop;
						}
					}
				}

			}, 150);
		},
		initContentViewListeners() {
			setTimeout(() => {
				const wrapper = document.querySelector("#proc-table-" + this.id + " .v-data-table__wrapper");
				if (!wrapper) return;

				wrapper.addEventListener("scroll", this.onContentViewScroll);
			}, 150);
		},
		deinitContentViewListener() {
			const wrapper = document.querySelector("#proc-table-" + this.id + " .v-data-table__wrapper");
			if (!wrapper) return;
			wrapper.removeEventListener("scroll", this.onContentViewScroll);
		},
		redrawFooterDebounced: debounce(function () {
			this.redrawFooter();
		}, Config.INPUT_DEBOUNCE_TIMEOUT_SHORT),
		redrawFooter() {
			if (!this.hasFooter) return;
			const parent = this.el;
			if (!parent) return;
			const parentRect = parent.getBoundingClientRect();
			const tr = parent.querySelector("tr.filters");
			if (tr) {
				const ths = [...tr.querySelectorAll("th")];
				const footer = parent.querySelector(".proc-table__footer");
				const tds = [...footer.querySelectorAll(".proc-table__footer-col")];
				this.headers.forEach((col, index) => {
					const th = ths[index];
					const rect = th.getBoundingClientRect();
					const td = tds[index];
					td.style.left = (rect.left - parentRect.left) + "px";
					td.style.width = rect.width + "px";
					//td.style.visibility = height = rect.width + "px";
				});
				this.isFooterVisible = true;
			}
		},
	},
	async mounted() {
		this.id = id++;
		//this.restoreColumnData();
		await this.initEl();
		this.createStyleElement();
		this.recreateResizers();
		this.initContentViewListeners();
		this.redrawFooterDebounced();
		window.addEventListener("resize", this.onResize);

		//console.log("procId", this.procId);
		/*if (this.procId === -990568 || this.procId === -7844 || this.procId === -1052960 || this.procId === -1081928) {
			//console.log("ITEMS.length "+this.items.length);
			this.onItemClick(null, this.items[2]);
			this.onItemDblClick(this.items[2]);
		}*/
	},
	beforeDestroy() {
		this.destroyResizers();
		this.destroyStyleElement();
		this.deinitContentViewListener();
		window.removeEventListener("resize", this.onResize);
	}
}
</script>

<style lang="scss">

$proc-table__footer-height: 40px;
$proc-table__footer-height--lg: 18px;
$proc-table__footer-font-size: 12px;

$cell-bg-color--edited-mobile: #fff8dd;

.proc-table {
	position: relative;
	z-index: $z-index-proc-table;
	//height: 100%;

	.v-data-table {
		&.--desktop {
			//border-bottom: $border;
			//background: $table-bg-color;

			& > .v-data-table__wrapper {
				//background: $table-bg-color;
				border: $border;
				//border-right: $border;
				border-top: 0;

				& > table {
					position: relative;

					//border-top: $border;
					border-bottom: $border;

					& > thead {
						top: 0;
						position: sticky;
						z-index: 1;

						& > tr {

							&.row1 > th {
								border-top: $border;

								&:first-child {
									border-left: $border;
								}
							}

							&.row2 > th {
								position: static;
								white-space: nowrap;
								//top: 20px !important;
								//border-top: $border;
							}

							&.filters > th {
								//top: 20px !important;
								padding: 2px !important;
								height: 24px !important;
								vertical-align: middle;
								position: relative;

								input {
									background: $white;
									width: 100%;
									height: 20px;
									line-height: 20px;
									font-size: 11px;
									outline: none;
									padding: 0 3px;
									text-align: center;
									@include transition();

									&.active {
										background: $cell-bg-color--edited !important;
									}
								}

								.v-icon {
									position: absolute;
									right: 6px;
									top: 6px;
									z-index: 1;
								}
							}

							&.filters.two-rows > th {
								//top: 40px !important;
							}

							& > th {
								top: unset !important;
								background: $thead-bg-color !important;
								border-bottom: $border;
								border-right: $border;
								text-align: center !important;
								font-size: 10px !important;
								padding: 2px 4px !important;
								height: auto !important;

								&[colspan] {
									text-align: center !important;
								}

								&:first-child {
									border-left: $border;
								}

								&.sortable {
									position: relative;
									cursor: pointer;
									transition: background-color .2s;

									&:hover {
										background: $tr-hover-color !important;
									}

									.v-icon {
										position: absolute;
										right: 0;
										top: 0;
									}
								}

								&.sorted {
									background: $cell-bg-color--selected !important;

									&:hover {
										background: $tr-hover-color !important;
									}
								}

								&.dragged {
									background: $cell-bg-color--selected !important;
								}

								&.icon {
									text-align: center;

									.v-image {
										width: 16px;
										height: 16px;
										max-width: 16px;
										margin: 0 auto;
									}
								}
							}

							&:not(.row2):not(.filters) > th {
								border-top: $border;
							}
						}
					}

					& > tbody {
						transition: opacity .3s;

						& > tr {
							touch-action: manipulation;

							& > td {
								touch-action: manipulation;
								//background: $cell-bg-color;
								border-right: $border;
								font-size: 13px !important;
								line-height: normal;
								//height: auto !important;
								height: 24px !important;
								padding: 0 4px !important;
								//@include transition();
								transition: background-color .2s;
								cursor: pointer;
								white-space: nowrap;
								user-select: none;
								//min-width: 300px !important;
								//max-width: 300px !important;
								overflow: hidden; // -x does not work in webkit

								&:first-child {
									border-left: $border;
								}

								&.readonly {
									cursor: pointer;
								}

								&.checkbox {
									text-align: center;
								}

								&.icon {
									text-align: center;

									.v-image {
										width: 16px;
										height: 16px;
										max-width: 16px;
										margin: 0 auto;
									}
								}

								/*&.current {
									background: $cell-bg-color--selected;
								}*/

								&.selector {
									cursor: pointer;
									position: relative;
									//min-width: 200px;

									.selector {
										&__body {
											display: flex;
											justify-content: space-between;
										}

										&__icons {
											display: inline;
											flex: 0;
											position: absolute;
											z-index: 1;
											right: 4px;
											top: 50%;
											font-size: 12px;
											transform: translateY(-50%);
											background: $cell-bg-color--edited;

											.v-icon {
												/*position: absolute;
												right: 4px;
												top: 8px;*/
												font-size: 12px;
												margin-left: 4px;
												opacity: .7;
												transition: opacity 0.2s;

												&:hover {
													opacity: 1;
												}
											}
										}
									}
								}

								&.date {
									cursor: pointer;
									//min-width: 100px;
									text-align: center;

									span {
										position: relative;
										justify-content: space-between;

										.v-icon {
											position: absolute;
											z-index: 1;
											right: 4px;
											top: 50%;
											display: inline;
											font-size: 12px;
											transform: translateY(-50%);
											background: $cell-bg-color--edited;
										}
									}
								}

								input {
									width: 100%;
									height: 21px;
									font-size: $font-size-input;
									outline: none;
									padding: 1px 0;
									//background: $cell-bg-color;
									//background: $cell-bg-color--selected;

									/*&.right {
										text-align: right;
									}
									&.center {
										text-align: center;
									}*/

									@include hideInputIntegerScroller;
								}

								/*.v-input {
									font-size: 13px;

									input {
										max-height: 24px;
									}
								}*/
							}

							&.current {
								& > td {
									background: $cell-bg-color--selected;
								}
							}

							&.multiselected:not(.current) {
								& > td {
									background: $cell-bg-color--multiselected;
								}
							}

							&.edited {
								& > td {
									background: $cell-bg-color--edited !important;
									//padding: 1px !important;

									&.readonly {
										cursor: default;
										opacity: .6;
									}

									&.checkbox {
										&.readonly {
											.v-icon.checkbox {
												cursor: default;
											}
										}
									}

									& > span {
										display: flex;
										align-items: center;
										justify-content: inherit;
										width: 100%;
										height: 21px;
										font-size: $font-size-input;
										outline: none;
										//padding: 1px 3px;
									}
								}
							}

							&.deleted {
								& > td {
									background: $cell-bg-color--deleted;
									text-decoration: line-through;
									color: $white;
								}
							}

							&:hover:not(.current):not(.multiselected):not(.v-data-table__empty-wrapper) {
								> td {
									background: $tr-hover-color;
								}
							}

							/*&:not(.v-data-table__empty-wrapper) {
								> td {
									cursor: pointer;
								}
							}*/

							&.v-data-table__empty-wrapper {
								td {
									cursor: default;
									//height: 128px !important;
								}
							}
						}

					}
				}

				@include scroll();
			}
		}

		&.--mobile {
			position: relative;
			//height: 100%;

			&::before {
				position: absolute;
				z-index: 2;
				content: "";
				top: 0;
				left: 0;
				width: 100%;
				height: 2rem;
				background: linear-gradient(180deg, rgba(0, 0, 0, 0.05), rgba(0, 0, 0, 0.0) 100%);
				pointer-events: none;
			}

			&::after {
				position: absolute;
				z-index: 2;
				content: "";
				bottom: 0;
				left: 0;
				width: 100%;
				height: 2rem;
				background: linear-gradient(0deg, rgba(0, 0, 0, 0.05), rgba(0, 0, 0, 0.0) 100%);
				pointer-events: none;
			}

			& > .v-data-table__wrapper {
				& > table {
					& > tbody {
						& > tr {
							& > td {
								transition: background .2s;

								.selector {
									&__body {
										display: flex;
										justify-content: space-between;
										&-value {
											width: 100%;
											overflow: hidden;
										}
									}
								}

								.checkbox {
									width: 100%;
									flex-direction: row;
									align-items: center;
									padding-bottom: 1rem;
									//gap: .5rem;

									.v-label {
										font-size: $font-size-larger;
										color: $border-text-color; // same as default default light theme v-table color
									}

									/*.proc-table__mobile-col-value {
										width: unset;
									}*/
									.proc-table__mobile-col-key {
										display: none;
										/*position: absolute;
										left: 44px;
										top: 0.35rem;
										color: unset;
										font-size: $font-size-larger;
										@include text-overflow();
										width: calc(100% - 70px);
										flex-grow: 0;*/
									}
								}
							}

							&.current {
								& > td {
									background: #eef4ff; //$cell-bg-color--selected;
								}
							}

							&.edited {
								& > td {
									background: $cell-bg-color--edited-mobile !important;
								}
							}

							&.v-data-table__empty-wrapper {
								& > td {
									display: block;
									width: 100%;
									padding: 5vh 0;
									text-align: center;
									font-size: $font-size-larger;
								}
							}

							&:hover:not(.v-data-table__expanded__content):not(.v-data-table__empty-wrapper) {
								background: unset;
							}
						}
					}
				}
			}
		}
	}

	&__mobile {

		/*&-tr {
			&:first-child {
				td {
					padding-top: 1.5rem !important;
				}
			}

			&:last-child {
				padding-bottom: .5rem;
			}
		}*/

		&-td {
			//display: block;
			width: 100%;
			position: relative;
			padding: 1.5rem 0 !important;
			height: auto !important;

			&-number {
				//min-width: 2rem;
				padding: 2.1rem 0 1rem 1.75rem !important;
				height: auto !important;
				vertical-align: top;
				color: map-get($grey, "lighten-1");
				font-size: $font-size-smaller !important;
			}

			input {
				padding: 4px 6px;
				width: 100%;
				border: 1px solid $border-color;
				font-size: $font-size-larger;
			}
		}

		&-col {
			position: relative;
			display: flex;
			flex-direction: column;
			width: 100%;
			padding: .25rem 2.5rem .5rem 1.25rem;

			&.filtered {
				.proc-table__mobile-col-value {
					position: relative;

					&::before {
						position: absolute;
						content: "";
						left: -.8rem;
						top: 0.65rem;
						background-color: red;
						border-radius: 50%;
						width: 3px;
						height: 3px;
					}
				}

				.proc-table__mobile-col-key {
					color: red;
					//font-style: italic;
				}
			}

			/*&:first-child {
				padding-top: 1rem !important;
			}

			&:last-child {
				padding-bottom: 1rem !important;
			}*/

			&-key {
				//order: 1 !important;
				font-size: $font-size-smaller;
				font-weight: normal;
				width: 100%;
				text-align: left;
				color: map-get($grey, "lighten-1");
				padding: 0;
			}

			&-value {
				//order: 0 !important;
				width: 100%;
				overflow-wrap: anywhere;
				font-weight: normal;
				text-align: left;
				font-size: $font-size-larger;
				padding: 0;

				.v-image {
					max-width: 20px;
					height: 20px;
				}
			}
		}

		&-menu-btn {
			position: absolute !important;
			right: 0.75rem;
			top: 1.25rem;
			z-index: 1;
		}

		&-footer {
			font-size: $font-size-larger;
			padding: 1rem 1.5rem 2.5rem 1.5rem;
			display: flex;
			flex-direction: column;
			justify-content: center;

			/*position: absolute;
			bottom: 0;
			left: 0;
			width: 100%;
			display: flex;
			background: $white;
			*/
		}
	}

	&.filtering {
		tbody {
			opacity: .25;
		}
	}

	&.resizing * {
		cursor: col-resize !important;
	}

	&.th-dragging {
		* {
			cursor: all-scroll !important;
		}

		.proc-table__resizer {
			display: none;
		}
	}

	&__paging {
		//padding-top: 2px;

		.row {
			height: 40px;
			@include up($lg) {
				height: 24px;
			}
		}

		.v-pagination {
			margin: 0 !important;
			padding: 3px 0 0 0 !important;
			height: 40px;

			@include up($lg) {
				padding: 4px 0 0 0 !important;
				height: 24px;
			}

			&__navigation {
				min-width: 32px;
				height: 32px;
				padding: 0 3px;
				margin: 0;
				box-shadow: none;
				font-size: $font-size-normal;
				background-color: transparent !important;
				@include transition();

				@include up($lg) {
					min-width: 24px;
					height: 20px;
					font-size: $font-size-smaller;

					&:hover {
						background-color: $thead-bg-color !important;
					}
				}

			}

			&__item {
				//width: 24px;
				min-width: 32px;
				height: 32px;
				padding: 0 3px;
				margin: 0;
				box-shadow: none;
				font-size: $font-size-normal;
				background-color: transparent !important;
				@include transition();

				@include up($lg) {
					min-width: 24px;
					height: 20px;
					font-size: $font-size-smaller;

					&:hover {
						background-color: $thead-bg-color !important;
					}
				}
			}
		}
	}

	&__resizer {
		position: absolute;
		z-index: $z-index-proc-table__resizer;
		//background: rgba(0,0,0,.5);//$color-white;
		opacity: 0;
		width: 11px;
		cursor: col-resize;
		transform: translateX(-50%);
		//@include transition();
		user-select: none;

		&:hover {
			opacity: .3;
		}

		&.active {
			opacity: .6;
		}

		&:after {
			content: "";
			position: absolute;
			top: 0;
			left: 50%;
			width: 1px;
			height: 100%;
			transform: translateX(-50%);
			background: $color-black;
			/*border-left: 1px solid $white;
			border-right: 1px solid $color-black;*/

			//box-shadow: 0 0 7px rgba(0, 0, 0, .35);
		}
	}

	&__th-drag-dummy {
		position: absolute;
		z-index: $z-index-proc-table__resizer;
		border: 1px dotted $color-black;
		background: $thead-bg-color;
		box-shadow: 0 0 15px rgba(0, 0, 0, .25);
		background: $thead-bg-color !important;
		font-size: 10px;
		font-weight: bold;
		padding: 2px 4px;
		display: flex;
		align-items: center;
		justify-content: center;
		text-align: center;
		pointer-events: none;
		opacity: 0.5;
		transition: opacity .15s;

		.v-image {
			width: 16px;
			height: 16px;
			max-width: 16px;
			margin: 0 auto;
		}

		&.drag-end {
			opacity: 0;
		}
	}

	&__footer {
		width: 100%;
		height: $proc-table__footer-height;
		position: relative;
		overflow: hidden;
		background: $white; //$thead-bg-color;
		opacity: 0;
		font-size: $proc-table__footer-font-size;
		@include transition();

		@include up($lg) {
			height: $proc-table__footer-height--lg;
		}

		&.--visible {
			opacity: 1;
		}

		&-col {
			position: absolute;
			top: 0;
			///text-align: right;
			padding: 0 4px;
			//background: $thead-bg-color;
			font-weight: bold;
			display: flex;
			align-items: center;
			overflow: hidden;
		}
	}
}

</style>