<template>
	<v-app>
		<transition name="route">
			<template v-if="isReloading">
				<v-main style="background:red">
					<div class="d-flex align-center justify-center fill-height grey lighten-4">
						<p class="grey--text text-center">Перезагружаем приложение.<br/> Пожалуйста, подождите...</p>
					</div>
				</v-main>
			</template>
			<template v-else>
				<transition name="route" mode="out-in" appear>
					<router-view/>
				</transition>
			</template>
		</transition>
	</v-app>
</template>

<script>
import store from "@/store";

export default {
	name: 'App',

	data: () => ({
		lastWindowWidth: null,
		isReloading: false,
	}),
	methods: {
		async onResize(withReload = true) {
			this.$store.commit('updateWindowWidth');
			let vh = window.innerHeight * 0.01;
			document.documentElement.style.setProperty('--vh', `${vh}px`);

			/*if (withReload) {
				if (this.lastWindowWidth !== window.innerWidth) {
					this.isReloading = true;
					setTimeout(() => window.location.reload(), 250);
				}
			}*/
			this.lastWindowWidth = window.innerWidth;
		},
	},
	mounted() {
		window.addEventListener("resize", this.onResize);
		this.onResize(false);
	},
	beforeDestroy() {
		window.removeEventListener("resize", this.onResize);
	}
};
</script>
