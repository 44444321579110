<template>
	<v-dialog v-model="params.isVisible"
			  content-class="delete-dialog"
			  width="500">
		<v-card>
			<v-card-title>
				Удалить запись?
			</v-card-title>

			<v-divider/>

			<v-card-actions>
				<v-spacer></v-spacer>
				<v-btn
					color="primary"
					text
					@click="params.isVisible=false"
				>
					Отмена
				</v-btn>
				<v-btn
					color="primary"
					:loading="params.isLoading"
					@click="onYes">
					Удалить
				</v-btn>
			</v-card-actions>
		</v-card>
	</v-dialog>

</template>

<script>
import Vue from "vue";
import {nf} from "@/utils/string";
import {debounce} from "lodash";
import Config from "@/config";

export default {
	name: "delete-dialog",
	props: ["params"],
	data: () => ({
		//isLoading: false
	}),
	computed: {},
	watch: {
		"params.params": {
			immediate: true,
			handler() {
				this.initParams();
			}
		}
	},
	methods: {
		initParams(withDefaults = false) {
		},
		onYes() {
			this.$emit("yes", true);
		},
	}
}
</script>

<style lang="scss">
.delete-dialog {
	.v-card {
		&__title {
			padding: 2px 12px !important;
		}

		&__text {
			padding: 12px !important;
		}

		&__actions {
			padding: 12px !important;
		}
	}
}
</style>