/**
 * Returns merged objects as a sibgle object.
 * TS so far does not support this out of the box.
 */
import Config from '@/config';

export function mergeObjects(...args) {
    let target = args[0];
    for (let i = 1; i < arguments.length; i++) {
        let source = arguments[i];
        for (let key in source) {
            if (source.hasOwnProperty(key)) {
                target[key] = source[key];
            }
        }
    }
    return target;
}

export function debounce(func, wait, immediate) {
    let timeout;
    return function () {
        let context = this, args = arguments;
        let later = function () {
            timeout = null;
            if (!immediate) func.apply(context, args);
        };
        let callNow = immediate && !timeout;
        clearTimeout(timeout);
        timeout = setTimeout(later, wait);
        if (callNow) func.apply(context, args);
    };
}

export function crossDownload(url, method = "GET") {
    return new Promise((resolve, reject) => {
        const req = new XMLHttpRequest();
        method = method || "GET";
        req.open(method, url, true);
        req.responseType = "arraybuffer";

        let authToken = localStorage.getItem(Config.STORAGE_AUTH_TOKEN);
        req.setRequestHeader('Authorization', authToken);

        // Create a binary string from the returned data, then encode it as a data URL.
        const arrayBufferToString = (response) => {
            const uInt8Array = new Uint8Array(response);
            let i = uInt8Array.length;
            const binaryString = new Array(i);
            while (i--) {
                binaryString[i] = String.fromCharCode(uInt8Array[i]);
            }
            return binaryString.join('');
        };

        req.onload = function (event) {
            const blob = req.response;
            let fileName = req.getResponseHeader('Content-Disposition')?.split("filename=")[1];
            if (!fileName) {
                const data = arrayBufferToString(req.response);
                //console.log("DATA", data);
                reject(JSON.parse(data));
                return;
            }

            fileName = decodeURI(fileName.replace(/['"]/g, ''))
            const __fileName = fileName;
            const contentType = req.getResponseHeader("content-type");
            if (window.navigator.msSaveOrOpenBlob) {
                // Internet Explorer
                window.navigator.msSaveOrOpenBlob(new Blob([blob], {type: contentType}), fileName);
            } else {
                const link = document.createElement('a');
                document.body.appendChild(link);
                link.download = __fileName;
                //link.href = window.URL.createObjectURL(blob);

                const data = arrayBufferToString(req.response);
                const base64 = window.btoa(data);
                const mime = contentType ? contentType.split(";")[0] : "text/plain";
                link.href = "data:" + mime + ";base64," + base64;
                link.click();
                document.body.removeChild(link); //remove the link when done
            }

            resolve();
        };
        req.send();
    });
};

export function areSameSets(object1, object2) {
    //console.log("1: ", object1);
    //console.log("2: ", object2);
    if (!object1 && !object2) return true;
    if (object1 && !object2) return false;
    if (!object1 && object2) return false;
    const keys1 = Object.keys(object1);
    const keys2 = Object.keys(object2);
    if (keys1.length !== keys2.length) return false;
    for (let i = 0; i < keys1.length; i++) {
        const key = keys1[i];
        //console.log("\t" + key + "1: " + object1[key]);
        //console.log("\t" + key + "2: " + object2[key]);
        if (object1[key] + '' !== object2[key] + '') return false;
    }
    return true;
};

export function routeOfMenu(menu) {
    if (menu?.ID_FORMNAME && menu?.IS_CARD) return {
        name: "card",
        params: {
            "pageId": menu.ID_MENU,
        },
    };
    else if (menu?.ID_PROCNAME) return {
        name: "page",
        params: {
            "pageId": menu.ID_MENU,
        },
    };
    else if (menu?.ID_FORMNAME) return {
        name: "form",
        params: {
            "pageId": menu.ID_MENU,
        },
    };

    return null;
}

export function colorFromDelphi(value) {
    if (!value) return null;

    const constants = {
        // cbStandardColors
        "clBlack": "#000000",
        "clMaroon": "#800000",
        "clGreen": "#008000",
        "clOlive": "#808000",
        "clNavy": "#000080",
        "clPurple": "#800080",
        "clTeal": "#008080",
        "clGray": "#808080",
        "clSilver": "#C0C0C0",
        "clRed": "#FF0000",
        "clLime": "#00FF00",
        "clYellow": "#FFFF00",
        "clBlue": "#0000FF",
        "clFuchsia": "#FF00FF",
        "clAqua": "#00FFFF",
        "clWhite": "#FFFFFF",

        // cbExtendedColors
        "clMoneyGreen": "#C0DCC0",
        "clSkyBlue": "#A6CAF0",
        "clCream": "#FFFBF0",
        "clMedGray": "#A0A0A4",

        // cbSystemColors (win 7)
        "clActiveBorder": "#B4B4B4",
        "clActiveCaption": "#99B4D1",
        "clAppWorkSpace": "#ABABAB",
        "clBackground": "#000000",
        "clBtnFace": "#F0F0F0",
        "clBtnHighlight": "#FFFFFF",
        "clBtnShadow": "#A0A0A0",
        "clBtnText": "#000000",
        "clCaptionText": "#000000",
        "clGradientActiveCaption": "#B9D1EA",
        "clGradientInactiveCaption": "#D7E4F2",
        "clGrayText": "#6D6D6D",
        "clHighlight": "#3399FF",
        "clHighlightText": "#FFFFFF",
        "clHotLight": "#0066CC",
        "clInactiveBorder": "#F4F7FC",
        "clInactiveCaption": "#BFCDDB",
        "clInactiveCaptionText": "#434E54",
        "clInfoBk": "#FFFFE1",
        "clInfoText": "#000000",
        "clMenu": "#F0F0F0",
        "clMenuBar": "#F0F0F0",
        "clMenuHighlight": "#3399FF",
        "clMenuText": "#000000",
        "clScrollBar": "#C8C8C8",
        "cl3DDkShadow": "#696969",
        "cl3DLight": "#E3E3E3",
        "clWindow": "#FFFFFF",
        "clWindowFrame": "#646464",
        "clWindowText": "#000000",
    };

    if (constants[value]) return constants[value];

    let hex = Number(value).toString(16);
    if (hex.length === 6) hex = hex.substring(4, 6)
        + hex.substring(2, 4)
        + hex.substring(0, 2);
    return "#" + hex;
}

export function isIOS() {
    const iOSMobile = [
            'iPad Simulator',
            'iPhone Simulator',
            'iPod Simulator',
            'iPad',
            'iPhone',
            'iPod'
        ].includes(navigator.platform)
        // iPad on iOS 13 detection
        || (navigator.userAgent.includes("Mac") && "ontouchend" in document);
    //console.log("IsIos iOSMobile ", iOSMobile);
    return iOSMobile;
}

export function isMacOrIOs() {
    return /(Mac|iPhone|iPod|iPad)/i.test(navigator.platform) || isIOS();
}

export function isMobile() {
    let check = false;
    (function (a) {
        if (/(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i.test(a) || /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(a.substr(0, 4))) check = true;
    })(navigator.userAgent || navigator.vendor || window.opera);
    return check;
}
